import React, { useState } from 'react';

const GovernmentItSolutions = () => {

    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
        {
            title: "CITIZEN SELF-SERVICE PORTALS",
            imageSrc: "/assets/images/Picture-1-16.png",
            content:
              "We create resilient solutions that offer a convenient and effective means of reaching government services, data, and assets. Our goal is to assist entities in reducing expenses related to manual services while improving customer contentment through exceptional service provision. Additionally, our team of specialists develops secure frameworks that enable individuals to access confidential information from the government while safeguarding their privacy.",
          },
          {
            title: "SMART CITIES SOLUTIONS",
            imageSrc: "/assets/images/Picture-1-17.png",
            content:
              "Governments worldwide are heavily investing in advanced software designed to enhance the efficiency, sustainability, and overall quality of life within their cities. Smart city solutions play a pivotal role in assisting governments to lower energy usage, elevate public safety measures, efficiently handle traffic management, and enhance accessibility to essential services. Through the utilization of such software for governmental management, countries can cultivate a setting that encourages both economic expansion and progress.",
          },
          {
            title: "ACCOUNTING SOFTWARE FOR GOVERNMENT",
            imageSrc: "/assets/images/Picture-1-18.png",
            content:
              "We specialize in developing advanced accounting software solutions tailored for governmental organizations. These tools play a crucial role in managing financial activities and guaranteeing adherence to relevant laws. Our cutting-edge software not only aids in budget management, payment monitoring, and report generation but also empowers municipalities to enhance their decision-making processes, streamline operations, and minimize expenditures.",
          },
          {
            title: "MUNICIPAL GOVERNMENT ERP SOFTWARE",
            imageSrc: "/assets/images/Picture-1-19.png",
            content:
              "We specialize in creating comprehensive ERP software designed specifically for municipal governments. Our advanced solutions are tailored to automate various tasks like budget management, accounting, procurement, and payroll processing. Through our cutting-edge offerings, we enable government bodies to not only simplify their workflows but also lower expenses and enhance overall effectiveness. By harnessing the capabilities of modern technology, local governments can confidently deliver prompt and precise services to their residents while maintaining financial discipline.",
          },
          {
            title: "E-TAX PORTAL DEVELOPMENT",
            imageSrc: "/assets/images/Picture-1-20.png",
            content:
              "The growing worldwide inclination towards e-governance has resulted in a surge of governments embracing electronic tax portals to enhance the efficiency of tax collection and submission processes. These online platforms provide a secure and dependable avenue for taxpayers to settle their obligations, simultaneously cutting down on traditional paper-based filing methods. Moreover, e-tax portals grant governments immediate access to live data on their tax collection procedures, enabling them to make well-informed fiscal choices.",
          },
          {
            title: "G2C/G2B PORTAL DEVELOPMENT",
            imageSrc: "/assets/images/Picture-1-1.png",
            content:
              "Brain Star Technologies specializes in crafting G2C/G2B portals tailored for governmental bodies. These portals offer a plethora of advantages, including enhanced correspondence with citizens and enterprises, expedited handling of inquiries, elevated client support, amplified operational openness, and economical gains. Our portals empower government entities to execute their tasks more effectively, all the while furnishing superior assistance to their populace.",
          },
          {
            title: "CYBER & INFORMATION SECURITY",
            imageSrc: "/assets/images/Picture-1-22.png",
            content:
              "Our expert team creates software solutions for local governments, focusing on effectively addressing their requirements for securing confidential data. Our offerings deliver a holistic strategy to ensure the safety of sensitive information and defend networks against data breaches, ransomware assaults, and other malicious endeavors. Employing cutting-edge technologies and industry best practices, we guarantee that the software we develop adheres to the utmost benchmarks of security and dependability.",
          },
          {
            title: "FEDERAL GOVERNMENT VEHICLES",
            imageSrc: "/assets/images/Picture-1-1-1.png",
            content:
              "We are dedicated to providing the highest quality service and solutions that save time and money for government agencies. We build elegant government software solutions to help federal agencies manage their fleet of vehicles quickly and efficiently, making it easy for them to track vehicle data, monitor maintenance, create reports on fleet activities, and even track fuel usage.",
          },
          {
            title: "PUBLIC HEALTHCARE & PENSIONS",
            imageSrc: "/assets/images/Picture-1-24.png",
            content:
              "We specialize in creating essential software solutions for government agencies, focusing on healthcare and pension management. Our software empowers governments to effectively monitor the well-being and financial welfare of their populations. By automating diverse tasks, information becomes readily available, and services are promptly provided. This leads to enhanced service distribution and cost savings through the removal of manual procedures.",
          },
          {
            title: "DOCUMENT MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Picture-1-25.png",
            content:
              "Brain Star Technologies is gaining considerable acknowledgment from governmental bodies for its resilient document management solutions. These solutions are being praised for their ability to simplify and optimize the entire lifecycle of documents within these organizations. This includes creation, archival, and retrieval processes, leading to enhanced efficiency in records management and heightened security. Furthermore, these innovative solutions contribute to the reduction of expenses linked to physical paper storage, printing, and distribution. They play a pivotal role in maintaining well-organized and efficient workflows across all processes.",
          },
          {
            title: "MUNICIPAL ID ENROLMENT SYSTEM",
            imageSrc: "/assets/images/Picture-1-26.png",
            content:
              "We build municipal ID enrolment systems that offer a secure, efficient and cost-effective way for governments to enroll citizens into government services, helping them better serve their citizens. By leveraging the latest technologies, such enrolment systems enable governments to quickly and accurately track important information, ultimately leading to improved service delivery and a higher quality of life for their citizens.",
          },
        ];

  return (
   <>
   <section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Government IT solutions</span>
            </h1>
            <p
              className="wow fadeInUp aos-init"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Brain Star Technologies is a comprehensive government software
              company that provides extensive support to government entities,
              aiding them in their journey towards digital modernization. Our
              primary focus is on simplifying inter-agency operations and
              enhancing citizen experiences. With our profound technical
              proficiency, we are capable of developing robust software
              solutions that enable you to optimize workflow effectiveness,
              minimize the expenses associated with outdated systems, and
              enhance the quality of public services.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>



<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>

</>
  )
}

export default GovernmentItSolutions