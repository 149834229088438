import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';

const DigitalMarketing = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);


  return (
<>
  <section className="section home_banner crossPlatform">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-7 banner_text carrer_banner_text">
              <h1
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                <span>Expand Your Digital Presence</span>
                <br /> Unleash Your Brand Potential!
              </h1>
              <h3
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Empower Your Business with Our Dynamic Digital Marketing
                Strategies
              </h3>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/images/digital-marketing-banner-img.png"
                alt="Digital Marketing Banner Image"
                className="img-responsive wow zoomIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "zoomIn"
                }}
              />
            </div>
          </div>
        </div>
        <div className="ocean contact_ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section app_development_section services-padding">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            Beyond Websites, <br />
            <span>Empower Your Business with Digital Marketing.</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
            An impactful digital marketing strategy is the cornerstone of your
            business's success, acting as a catalyst for growth and expansion.
            It goes beyond traditional websites, embracing innovative techniques
            to reach and engage your target audience effectively. At Brain Star
            Technologies, we specialize in comprehensive digital marketing
            solutions, driving higher business visibility and conversions. Our
            team crafts captivating campaigns, leverages the power of online
            platforms, and conducts in-depth analytics to propel your business
            forward. Partner with us to empower your brand and achieve
            unparalleled business growth.
          </p>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section mt-0 w-100">
          <div className="col-md-6 common_tittle">
            <h2
              className="heading-mt wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.4s"
            >
              {" "}
              Digital Marketing Services At <span>Brain Star Technologies</span>
            </h2>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/seo-icon.svg" alt="seo" />
                </span>
                <h3>Search Engine Optimization</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.7s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/smm.png" alt="social media marketing" />
                </span>
                <h3>Social Media Marketing</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/pay-per-click.png" alt="ppc" />
                </span>
                <h3>Pay Per Click</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.9s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/content-writing.svg" alt="content writing" />
                </span>
                <h3>Content Writing</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/app-store.svg"
                    alt="app store optimization"
                  />
                </span>
                <h3>App Store Optimization</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/orm.svg" alt="orm" />
                </span>
                <h3>Online Reputation Management</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section app_platform_section w-100 pt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Unlocking the Potential of
              <br />
              <span>Digital Marketing</span> for Your Business
            </h2>
          </div>
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                SEO Services
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Our comprehensive SEO services are designed to enhance your
                online presence and drive organic traffic to your website. Our
                expert team of SEO specialists ensures that your website ranks
                higher in search engine results, leading to increased visibility
                and business growth. Discover the exceptional benefits of our
                SEO services:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Strategic Keyword Research and Implementation
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Optimized On-Page and Off-Page SEO Techniques
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Tailored SEO Solutions for Your Business Needs
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Integration of SEO with User-Friendly Website Design
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Enhanced Local SEO for Targeted Audience Reach
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Regular Performance Analysis and Reporting
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/seo.jpg"
                alt="Node.js Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5">
              <img
                src="/assets/images/iot.jpg"
                alt="iot app Development"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                IoT App Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                IoT App Development harnesses the potential of emerging
                technologies to create innovative and interconnected
                applications that transform businesses. Our expert developers
                build cutting-edge IoT solutions that seamlessly integrate
                devices, data, and processes, providing efficient automation and
                actionable insights. Explore the key advantages of IoT App
                Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Empowering Connected Devices and Smart Solutions
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Seamless Data Management and Interaction
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Flexibility and Scalability for Rapid Growth
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Enabling Real-time Monitoring and Control
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Secure and Robust Connectivity
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Leveraging Advanced Analytics for Informed Decision-making
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Social Media Marketing
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Social Media Marketing harnesses the power of social platforms
                to build a strong online presence, drive brand awareness, and
                engage with the target audience effectively. Our expert team
                crafts compelling social media strategies tailored to your
                business, delivering remarkable results in digital marketing.
                Explore the key advantages of Social Media Marketing:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Strategic Brand Exposure on Leading Social Channels
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Engaging Content Creation for Higher User Interaction
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Targeted Advertising to Reach Relevant Audiences
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Data-Driven Approach for Optimal Campaign Performance
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Building Brand Loyalty and Customer Relationships
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Social Listening and Responding to Customer Feedback
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/social-media-marketing.jpg"
                alt="Django Web Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5">
              <img
                src="/assets/images/ppc.jpg"
                alt="Angular Development"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Pay Per Click (PPC) Marketing
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                PPC marketing is a dynamic and result-oriented approach that
                empowers businesses to reach their target audience effectively.
                Our skilled marketers craft and optimize PPC campaigns to drive
                relevant traffic and boost conversions, maximizing your return
                on investment. Discover the remarkable advantages of Pay Per
                Click Marketing:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Instant Visibility and Immediate Results
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Flexible Budget Allocation and Cost Control
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Targeted Ads to Reach Your Specific Audience
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Measurable and Data-Driven Campaigns
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Enhanced Brand Exposure and Awareness
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Optimized ROI through Conversion Tracking
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Digital Marketing Solutions
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Our comprehensive Digital Marketing solutions empower businesses
                to thrive in the competitive online landscape. Harness the
                potential of our strategic digital marketing techniques and
                drive impressive results. Discover the remarkable advantages of
                our Digital Marketing Services:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Targeted and Result-Oriented Campaigns
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Seamless Integration of Multi-Channel Strategies
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Customized Solutions for Every Business
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Strategic Content Marketing Initiatives
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Social Media Engagement and Brand Building
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Data-Driven Analytics and Reporting
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/dms.jpg"
                alt="digital market solution"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5">
              <img
                src="/assets/images/aso.jpg"
                alt="appstore optimization"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                App Store Optimization
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                App Store Optimization (ASO) is a crucial process that enhances
                the visibility and discoverability of mobile apps on various app
                stores. Our experts specialize in maximizing app rankings,
                driving more downloads, and increasing user engagement through
                effective ASO strategies. Explore the key advantages of App
                Store Optimization:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Optimizing Keywords for Higher Rankings
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Crafting Appealing App Descriptions
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Engaging App Icons and Screenshots
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Gaining Positive Reviews and Ratings
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Utilizing A/B Testing for Continuous Improvement
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Analyzing Competitor Strategies for Market Insights
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Online Reputation Management
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Online Reputation Management (ORM) plays a crucial role in
                shaping a positive image of your brand in the digital landscape.
                Our experts specialize in managing and enhancing your online
                reputation, ensuring that your business maintains a stellar
                online presence. Explore the key aspects of our Online
                Reputation Management services:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Building and Maintaining a Positive Online Image
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Proactively Handling Customer Feedback and Reviews
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Strategically Mitigating Negative Feedback
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Enhancing Brand Credibility and Trust
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Monitoring and Managing Online Mentions and References
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Creating a Strong Digital Footprint with Engaging Content
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/orm.jpg"
                alt="online reputation"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section mobile_process w-100 bg-light-blue">
          <div className="container">
            <div className="col-md-12 common_tittle text-center">
              <h2
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Our Digital Marketing <span>Process</span>
              </h2>
            </div>
            <div className="clearfix" />
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/analyze-data.jpg"
                    alt="Analyze Data"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Analyze Data</h3>
                <p>
                  Begin by collecting and analyzing comprehensive data about the
                  client's current marketing strategies, target audience, and
                  business objectives.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Strategy Planning</h3>
                <p>
                  Develop a well-structured digital marketing strategy based on
                  the analyzed data, outlining the campaign goals, target
                  platforms, content plan, and performance metrics.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/strategy-planning.jpg"
                    alt="Strategy Planning"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/create-compelling-content.png"
                    alt="Create Compelling Content"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Create Compelling Content</h3>
                <p>
                  In this phase, we create engaging and relevant content that
                  aligns with the marketing strategy, resonates with the target
                  audience, and drives customer engagement.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Implementation</h3>
                <p>
                  Execute the digital marketing plan by leveraging various
                  channels such as social media, email marketing, content
                  marketing, SEO, and PPC to reach the target audience.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/implementation.webp"
                    alt="Implementation"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.9s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/measure-performance.jpg"
                    alt="Measure Performance"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Measure Performance</h3>
                <p>
                  Conduct thorough performance analysis to measure the
                  effectiveness of the digital marketing campaigns and identify
                  areas for improvement.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Optimization</h3>
                <p>
                  Based on the performance analysis, optimize the digital
                  marketing strategies and content to achieve better results and
                  higher ROI.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/optimization.jpg"
                    alt="Optimization"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="clearfix" />
      </div>
    </div>
  </section>
  

  
  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 ">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Hire Digital Marketing Experts
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
            Amplify your brand's reach with data-driven strategies guided by our digital marketing experts.


          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>





</>

  )
}

export default DigitalMarketing