import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';


const MobileAppDevelopment = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);



  return (
    <>
    <section className="section home_banner mobile-app-development-servicesBanner">
      <div className="header_banner w-100">
        <div className="banner_overlay">
          <div className="container-fluid">
            <div className="row banner-content">
              <div className="col-md-7 banner_text carrer_banner_text">
                <h1
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  <span>Powerful Mobile Apps</span>
                  <br />
                  crafted by Expert Developers
                </h1>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Elevate your business with cutting-edge mobile app solutions.
                  Our user-centric approach sets new industry standards. Let us
                  build the perfect app for your success!
                </p>
              </div>
              <div className="col-md-5 text-center">
                <img
                  src="/assets/images/mobile-app-development-banner-img.png"
                  alt="Mobile App Development Banner Image"
                  className="img-responsive wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.4s"
                />
              </div>
            </div>
          </div>
          <div className="ocean">
            <div className="wave" />
            <div className="wave" />
          </div>
        </div>
      </div>
    </section>
    <section className="section services_column_section app_development_section services-padding">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Unleashing Memorable Experiences!
            </h2>
            <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.6s">
              Crafting Unique Apps with Customer-Centric Solutions. Stay Ahead
              with Us! Delivering Quality Solutions through Innovative Technology!
              Experience Polished Native Apps for Efficiency and Scalability.Let
              Your Ideas Soar High!
            </p>
          </div>
          <div className="clearfix" />
          <div className="all_services_section app_services_section mt-0 w-100">
            <div className="col-md-6 common_tittle">
              <h2
                className="heading-mt wow fadeInLeft"
                data-aos="fade-left"
                data-aos-delay="0.4s"
              >
                Mobile App Development Services:
                <br />
                <span>From Ideation To App Maintenance</span>
              </h2>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.6s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/iphone.svg" alt="iphone-app-development" />
                  </span>
                  <h3>IPhone App Development</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.7s"
              >
                <div className="all_services_inner">
                  <span>
                    <img
                      src="/assets/images/android-phone.svg"
                      alt="android-app-development"
                    />
                  </span>
                  <h3>Android App Development</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.8s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/ipad.svg" alt="ipad-app-development" />
                  </span>
                  <h3>IPad App Development</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos-delay="0.9s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/swift.png" alt="swift-app-development" />
                  </span>
                  <h3>Swift App Development</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="all_services_section app_services_section app_platform_section w-100 pt-0">
            <div className="col-md-12 common_tittle text-center">
              <h2
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                {" "}
                Wondering, How To Choose The Best <br />
                <span>Mobile App Platform </span>For Your Application?
              </h2>
            </div>
            <div className="definition_column_wrapper w-100">
              <div className="col-md-6 definition_column_right">
                <h3
                  className="text-uppercase wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  iPhone App Development
                </h3>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Supercharge your business with top-notch mobile apps! Elevate
                  user satisfaction through our tailor-made Native iPhone App
                  Development solutions. Collaborate with Brain Star Technologies,
                  the leading mobile app development company, and unlock limitless
                  possibilities. Our dedicated team delivers personalized native
                  iOS apps that surpass user expectations.
                </p>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Explore our comprehensive Native iPhone App Development
                  services:
                </p>
                <ul className="w-100">
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.7s"
                  >
                    Thorough Market Analysis
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.8s"
                  >
                    Innovative App Design &amp; Development
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.9s"
                  >
                    Seamless App Integration
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.0s"
                  >
                    Perfect synchronization
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.1s"
                  >
                    Reliable App Maintenance
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-center">
                <img
                  src="/assets/images/iphone-app-development.jpg"
                  alt="iPhone App Development"
                  className="img-responsive mx-auto wow fadeInRight"
                  data-aos="fade-right"
                  data-aos-delay="0.5s"
                />
              </div>
            </div>
            <div className="definition_column_wrapper w-100">
              <div className="col-md-6 text-center">
                <img
                  src="/assets/images/android-app-development.jpg"
                  alt="Android App Development"
                  className="img-responsive mx-auto wow fadeInLeft"
                  data-aos="fade-left"
                  data-aos-delay="0.5s"
                />
              </div>
              <div className="col-md-6 definition_column_right">
                <h3
                  className="text-uppercase wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  Android App Development
                </h3>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Embrace the power of native apps built in dedicated programming
                  languages for unmatched reliability and performance. Elevate
                  your business with our intuitive and cutting-edge app
                  development services.
                </p>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Our Native Android App Development Services:
                </p>
                <ul className="w-100">
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.7s"
                  >
                    Crafting a Winning App Strategy
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.8s"
                  >
                    Innovative Product Design
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.9s"
                  >
                    Expert App Development
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.0s"
                  >
                    Seamless Usability Features
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.1s"
                  >
                    Rigorous App Testing
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.2s"
                  >
                    Effective Product Marketing
                  </li>
                </ul>
              </div>
            </div>
            <div className="definition_column_wrapper w-100">
              <div className="col-md-6 definition_column_right">
                <h3
                  className="text-uppercase wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  IPad App Development
                </h3>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Want to elevate your business with a cutting-edge iPad app?
                  Trust our team of skilled native app developers to create a
                  polished solution. Experience remarkable business growth with
                  our high-performance apps.
                </p>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Our Expertise in Native iPad App Development Includes:
                </p>
                <ul className="w-100">
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.7s"
                  >
                    In-depth Market Research &amp; Analysis
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.8s"
                  >
                    Strategic App Architecture
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.9s"
                  >
                    Sleek and Impactful Design
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.0s"
                  >
                    Efficient App Development Processt
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.1s"
                  >
                    Flawless App Porting &amp; Rigorous Testing
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-center">
                <img
                  src="/assets/images/ipad-app-development.jpg"
                  alt="Ipad App Development"
                  className="img-responsive mx-auto wow fadeInRight"
                  data-aos="fade-right"
                  data-aos-delay="0.5s"
                />
              </div>
            </div>
            <div className="definition_column_wrapper w-100">
              <div className="col-md-6 text-center">
                <img
                  src="/assets/images/swift-app-development.jpg"
                  alt="swift App Development"
                  className="img-responsive mx-auto wow fadeInLeft"
                  data-aos="fade-left"
                  data-aos-delay="0.5s"
                />
              </div>
              <div className="col-md-6 definition_column_right">
                <h3
                  className="text-uppercase wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  Swift App Development
                </h3>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Take your business to new heights with a custom iPad app!
                  Partner with Brain Star Technologies, a trusted mobile app
                  development company. Leveraging our expertise, we'll create a
                  polished and feature-rich app that boosts sales and delights
                  users.
                </p>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Discover our extensive Native iPad App Development services:
                </p>
                <ul className="w-100">
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.7s"
                  >
                    Thorough Market Research
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.8s"
                  >
                    Optimized App Architecture
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.9s"
                  >
                    Compelling and Impactful Design
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.0s"
                  >
                    Proficient App Development
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.1s"
                  >
                    Smooth App Migration &amp; Rigorous Testing
                  </li>
                  <li
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.2s"
                  >
                    Continuous Support &amp; Maintenance
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="all_services_section app_services_section mobile_process w-100 service-heading-section mobile-services mt-0 pt-0">
            <div className="col-md-12 common_tittle text-center">
              <h2
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                {" "}
                Our Mobile App <span>Development Process</span>{" "}
              </h2>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                In a competitive landscape, differentiating your brand is no easy
                task. We offer innovative and user-centric solutions to narrate
                your business journey. <br />
                This is how we work!
              </p>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 text-center progress-arrow1">
              <img
                src="/assets/images/progress-arrow1.png"
                alt="Progress Arrow 1"
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.6s"
              />
            </div>
            <div className="clearfix" />
            <div className="col-md-6 culture_column">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="/assets/images/we-dig-deep.png"
                    alt="Understanding Your Requirement"
                    className="img-responsive wow zoomIn"
                    data-aos="zoom-in"
                  />
                </div>
                <div className="col-md-6">
                  <h4
                    className="culture_step1_heading wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.2s"
                  >
                    Understanding Your Requirement
                  </h4>
                  <p
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="0.3s"
                  >
                    We value your thoughts! We prioritize understanding your needs
                    and implement top-notch strategies to propel your business
                    towards success. Share your ideas and aspirations with us, and
                    let's work together to achieve your goals! We're all ears!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 culture_column">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="/assets/images/web-frame.png"
                    alt="Web frame & UI/UX Design"
                    className="img-responsive wow zoomIn"
                    data-aos="zoom-in"
                    data-aos-delay="1s"
                  />
                </div>
                <div className="col-md-6">
                  <h4
                    className="culture_step2_heading wow fadeInUp"
                    data-aos-delay="1.1s"
                  >
                    Web frame &amp; UI/UX Design
                  </h4>
                  <p
                    className="wow fadeInUp"
                    data-aos="fade-up"
                    data-aos-delay="1.2s"
                  >
                    Our top priority is to provide remarkable user experiences
                    through intuitive UI/UX design for our apps. We work closely
                    with you to validate your vision and tailor it to meet the
                    specific needs of your audience.
                  </p>
                </div>
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 text-center progress-arrow2">
              <img
                src="/assets/images/progress-arrow2.png"
                alt="Progress Arrow 2"
                className="wow fadeInDown"
                data-aos="fade-down"
                data-aos-delay="1.2s"
              />
            </div>
            <div className="clearfix" />
            <div className="col-md-6 culture_column">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="/assets/images/mobile-app-development-step3.png"
                    alt="Mobile App Development"
                    className="img-responsive wow zoomIn"
                    data-aos="zoom-in"
                    data-aos-delay="0.4s"
                  />
                </div>
                <div className="col-md-6">
                  <h4 className="culture_step3_heading wow fadeInUp">
                    Mobile App Development
                  </h4>
                  <p className="wow fadeInUp">
                    Embrace cutting-edge technologies and frameworks for
                    end-to-end custom mobile app development. Our solutions
                    deliver added user value and drive real business growth!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </section>
    <section className="section contact_section" id="contact" style={{}}>
      <div className="air air1" />
      <div className="air air2" />
      <div className="air air3" />
      <div className="air air4" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 contact_section_left">
            <div className="testimonials-section">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="testimonials-inner wow flipInX">
                    <div className="testi-img">
                      <img
                        src="/assets/images/mike.jpg"
                        alt="Mike"
                        className="img-responsive"
                      />
                    </div>
                    <h3 className="name-position">Mike</h3>
                    <p>
                      Thanks Brain Star Technologies for great support on our
                      project. They not only showed their commitment and hard work
                      in our project, but also helped us take care of some points
                      that got missed by us and did not belong to their scope.
                    </p>
                  </div>
                  <div className="testimonials-inner wow flipInX">
                    <div className="testi-img">
                      <img
                        src="/assets/images/casi.jpg"
                        alt="Casi"
                        className="img-responsive"
                      />
                    </div>
                    <h3 className="name-position">Casi</h3>
                    <p>
                      Brain Star Technologies has a solid technology team and on
                      time delivery. Very happy to be associated with them Great
                      work!!! The team was solid, efficient and knowledgeable.
                      They did an amazing job on my very challenging app. I will
                      be using them again. Thank you for doing such a great job!
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 mt-30">
                  <div className="testimonials-inner wow flipInX">
                    <div className="testi-img">
                      <img
                        src="/assets/images/suzanne.jpg"
                        alt="Suzzane"
                        className="img-responsive"
                      />
                    </div>
                    <h3 className="name-position">Suzzane</h3>
                    <p>
                      Brain Star Technologies is driven by a passion to create and
                      deliver on new ideas and that is reflected in their work. It
                      is a team that strives for excellence and is open to
                      experimenting and creating new trends in the business. Our
                      work relationship has been productive and we have
                      collectively been able to achieve great results.
                    </p>
                  </div>
                  <div className="testimonials-inner wow flipInX">
                    <div className="testi-img">
                      <img
                        src="/assets/images/daisy.jpg"
                        alt="Daisy"
                        className="img-responsive"
                      />
                    </div>
                    <h3 className="name-position">Daisy</h3>
                    <p>
                      Brain Star Technologies Solutions has done a great job
                      taking our company’s idea and developing a website as per
                      our niche specifications. The team is technically strong and
                      creative. I will not hesitate to recommend them.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-right">
                  <a href="" className="btn btn-view gradient-btn">
                    View More
                    <img
                      src="/assets/images/arrow-right.png"
                      alt="Arrow Right"
                      className="img-responsive"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-5 col-md-offset-1 contact_section_right"
            id="Request_Quote_form"
          >
            <div className="contact_right_wrapper">
              <div className="col-md-12 common_tittle text-center">
                <h2 className="text-uppercase wow fadeInDown">
                  Let's Talk <br />
                  <span className="wow fadeInDown">About Project</span>
                </h2>
              </div>
              <form
                className="w-100"
                id="contactform"
                action=""
                method="post"
                noValidate="novalidate"
              >
                <input type="hidden" name="_token" defaultValue="" />
                <input
                  type="hidden"
                  id="redirecturl"
                  name="redirecturl"
                  defaultValue=""
                />
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        required=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className=" col-md-6 col-sm-6 col-xs-12 ">
                    <div className="form-group">
                      <input
                        type="text"
                        id="contactno"
                        name="contactno"
                        className="form-control"
                        placeholder="Mobile"
                      />
                    </div>
                  </div>
                  <div className=" col-md-6 col-sm-6 col-xs-12 ">
                    <div className="form-group">
                      <input
                        type="number"
                        name="projectbudget"
                        className="form-control"
                        placeholder="Project Budget"
                      />
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Project Summary"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        name="file_upload"
                      />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-md-12 col-sm-12 col-xs-12" />
                  <div className="clearfix" />
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group intership-form">
                      <div className="loading" style={{ display: "none" }} />
                      <button
                        type="submit"
                        name="submit"
                        className="btn btn-talk gradient-btn"
                        id="contactformSubmit"
                      >
                        Let's Talk
                      </button>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>


    


    
  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 ">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Mobile App Developers
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
          Forge innovative apps by joining forces with our proficient Mobile App developers.

          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>






  </>
  
  )
}

export default MobileAppDevelopment