import React, { useState } from 'react';

const ManufacturingSoftwareDevelopment = () => {

  
    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
      
        {
            title: "MANUFACTURING EXECUTION SYSTEMS SOFTWARE (MES)",
            imageSrc: "/assets/images/Frame-49126.png",
            content:
              "We harness the power of MES/MOM software development to oversee, trace, record, and manage the manufacturing process. This technology enhances accuracy and foresight, boosts operational efficiency, minimizes downtime, and curbs operational expenses.",
          },
          {
            title: "MANUFACTURING RESOURCE PLANNING SOFTWARE (MRP)",
            imageSrc: "/assets/images/Frame-49126-1.png",
            content:
              "Through this system, we bring together and harmonize all elements of resource planning within a singular digital platform. The development of MRP software encompasses tasks like managing inventory, handling procurements, overseeing CRM activities, and coordinating other resource planning processes.",
          },
          {
            title: "MANUFACTURING WORKFLOW AUTOMATION",
            imageSrc: "/assets/images/Frame-49126-2.png",
            content:
              "As new intelligent technologies continue to surface, we make use of sophisticated instruments to achieve full automation that aligns with manufacturing technical norms. This setup enables operators to step in during the production process solely when required.",
          },
          {
            title: "ENTERPRISE RESOURCE PLANNING SYSTEMS (ERP)",
            imageSrc: "/assets/images/Frame-49126-3.png",
            content:
              "Brain Star Technologies specializes in crafting personalized ERP software solutions that empower enterprises to efficiently oversee their daily operations. Our offerings enable businesses to streamline various tasks such as resource allocation, inventory management, sales and marketing efforts, distribution processes, as well as financial and accounting activities.",
          },
          {
            title: "ENTERPRISE ASSET MANAGEMENT (EAM)",
            imageSrc: "/assets/images/Frame-49126-4.png",
            content:
              "Our company introduces tools that assist enterprises in overseeing their corporate resources at every stage of their existence. This encompasses everything from the initial acquisition and setup to continuous supervision and upkeeping.",
          },
          {
            title: "PRODUCTION PLANNING SOFTWARE",
            imageSrc: "/assets/images/Frame-49126-5.png",
            content:
              "We specialize in assisting businesses of varying scales to make the most out of accurate and punctual planning. Our adept technicians excel in utilizing production scheduling software, which analyzes incoming information to fulfill market requirements and align with demand predictions.",
          },
          {
            title: "PRODUCTION MONITORING SOFTWARE",
            imageSrc: "/assets/images/Frame-49126-6.png",
            content:
              "Brain Star Technologies develops software that tracks the condition of production equipment by gauging various factors like temperature, substance concentrations, weight, usage, and more.",
          },
          {
            title: "CONDITION MONITORING SOFTWARE",
            imageSrc: "/assets/images/Frame-49126-7.png",
            content:
              "By utilizing sensors, we set up intelligent monitoring software that assesses the operational efficiency of essential equipment such as electric motors and pumps. This system operates in real-time and issues alerts whenever it identifies any operational abnormalities.",
          },
          {
            title: "EQUIPMENT MAINTENANCE SOFTWARE",
            imageSrc: "/assets/images/Frame-49126-8.png",
            content:
              "Through the automation of operational workflows, our developers create digital solutions that enable customers to monitor and address equipment malfunctions efficiently. These tools not only identify breakdowns and analyze their root causes but also minimize the duration of equipment downtime by facilitating immediate repairs.",
          },
          {
            title: "QUALITY MANAGEMENT SOFTWARE (QMS)",
            imageSrc: "/assets/images/Frame-49126-9.png",
            content:
              "Brain Star Technologies creates solutions designed to facilitate the incorporation of quality specifications and standards in accordance with industry regulations and global best practices, meeting the elevated expectations of customers for unparalleled quality.",
          },
          {
            title: "INVENTORY MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Frame-49126-10.png",
            content:
              "Our team of expert professionals develops software for managing inventory and warehouses at the highest level. This ensures the reliable planning of supply chains, monitoring of stock quantities, and analysis of orders, sales, and shipments. As a result, manufacturing processes run seamlessly without any interruptions.",
          },
          {
            title: "PROJECT MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Frame-49126-11.png",
            content:
              "Brain Star Technologies is a proficient provider of IT services, specializing in the creation of project management software. Our software solutions are designed to oversee, manage, and ensure the timely completion of project tasks, while also facilitating budget adherence.",
          },
          {
            title: "WORKFORCE MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Frame-49126-12.png",
            content:
              "Our specialized groups create tailored software solutions to monitor staff tasks, enabling managers to improve efficiency by overseeing elements such as workforce needs, individual timetables, extra work, sick leave, and allocated time off.",
          },
          
        ];

  return (
<>
<section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span> Manufacturing software development company </span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >

Brain Star Technologies has dedicated itself to crafting bespoke software solutions for the manufacturing sector, aimed at enhancing operational efficiency for businesses. Drawing upon more than ten years of expertise in the field of custom software development, we serve as the link between the ambitions of enterprises and their practical attainment. Our personalized applications are designed to oversee critical functions including material supply management, technological process oversight, and quality assurance. Our distinct CRM & ERP systems play a pivotal role in aiding emerging startups as they progress towards becoming prosperous small and medium-sized enterprises, all the while ensuring that larger corporations retain their competitive edge by virtue of streamlined operations that contribute to heightened revenue generation.


            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>


<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>

        
</>
  )
}


export default ManufacturingSoftwareDevelopment