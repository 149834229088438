import React, { useState } from 'react';

    const ElearningSoftwareDevelopment = () => {
    
      
        const [activeTab, setActiveTab] = useState(0);
    
        const tabMouseOverHandler = (index) => {
          // Set the active tab when a tab is hovered over
          setActiveTab(index);
        };
    
        const tabData = [
          
            {
                title: "Learning management systems (LMS)",
                imageSrc: "/assets/images/slide1.png",
                content:
                  "Brain Star Technologies provides personalized Learning Management Systems (LMS) complete with administrative and automation assistance. These web-based solutions are user-friendly and can be conveniently accessed from any gadget. Leveraging an LMS offers advantages such as rapid availability, excellent operational efficiency, and enhanced maintenance capability.",
              },
              {
                title: "Learning experience platforms (LXP)",
                imageSrc: "/assets/images/slide2.png",
                content:
                  "We harness the capabilities of cutting-edge technologies to create a Learning Experience Platform (LXP), which offers users a tailored learning journey. This solution capitalizes on the latest advancements in educational technology, fostering an environment where learners can exchange valuable content with one another.",
              },
              {
                title: "Knowledge management systems (KMS)",
                imageSrc: "/assets/images/slide3.png",
                content:
                  "We create adaptable knowledge management solutions designed for team, departmental, and organizational levels. These solutions enhance collaboration, facilitate knowledge sharing, and optimize business processes.",
              },
              {
                title: "School management software (SMS)",
                imageSrc: "/assets/images/slide4.png",
                content:
                  "Our team of software engineers develops dependable school management software, also referred to as a student information system (SIS), designed to handle data efficiently, facilitate seamless communication, and streamline scheduling. We create a pre-configured solution for digital administration within educational institutions, reducing the need for paper-based processes.",
              },
              {
                title: "Mobile eLearning applications",
                imageSrc: "/assets/images/slide5.png",
                content:
                  "Brain Star Technologies leverages their in-depth knowledge of mobile app development alongside their proficiency in EdTech to craft exceptional solutions for smartphones, tablets, and wearable gadgets. The integration of accessibility features not only empowers learners to achieve their best results but also elevates the overall user experience.",
              },
              {
                title: "Educational web applications",
                imageSrc: "/assets/images/slide6.png",
                content:
                  "We specialize in developing cutting-edge eLearning web applications, reminiscent of platforms like Google Classroom and edX. Our solutions empower users to conveniently access their educational resources from any location, enabling them to embrace online learning fully and enjoy a myriad of educational opportunities.",
              },
              {
                title: "Platforms for online courses",
                imageSrc: "/assets/images/slide7.png",
                content:
                  "We specialize in creating personalized educational platforms featuring engaging resources. Our offerings empower users to register for courses, craft their own learning materials, and evaluate their progress. Our systems streamline timetable management, curriculum creation, skill evaluation, and multimedia integration.",
              },
              {
                title: "Streaming platforms for online events",
                imageSrc: "/assets/images/slide8.png",
                content:
                  "We develop tools that simplify the broadcasting of virtual events, enabling educators to connect with a wide range of participants in live settings. Our expertise lies in crafting robust applications that support the hosting of various events such as conferences, association gatherings, lectures, workshops, and presentations. Our aim is to attract a more extensive audience and guarantee significant returns on investment for our clients.",
              },
              {
                title: "Online training software",
                imageSrc: "/assets/images/slide9.png",
                content:
                  "Create tools that provide swift access to educational resources, facilitating thorough and effective employee growth. These solutions will simplify the process of employee development and the assimilation of knowledge within both small and large businesses.",
              },
              
            ];
    
      return (
    <>
    <section
      className="section home_banner contact_banner"
      style={{ marginBottom: "3rem" }}
    >
      <div className="header_banner w-100">
        <div className="banner_overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
                <h1
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <span> Education software development services </span>
                </h1>
                <p
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-down"
                  data-aos-delay={300}
                >
    
    Brain Star Technologies has been delivering comprehensive personalized eLearning solutions to businesses and educational establishments. We implement creative eLearning tools, support online business growth, and enhance the learning experience by making it dynamic, engaging, and economical.    
    
                </p>
              </div>
            </div>
          </div>
          <div className="ocean contact_ocean">
            <div className="wave" />
            <div className="wave" />
          </div>
        </div>
      </div>
    </section>
    
    
    <div className="services_container">
          <div className="services_lt">
            <ul id="scroll_list" className="scroll_list">
              {tabData.map((tab, index) => (
                <li
                  key={index}
                  className={`tab-link ${activeTab === index ? 'active' : ''}`}
                  onMouseOver={() => tabMouseOverHandler(index)}
                >
                  <span data-tab={index + 1}>
                    <span className="scroll_list_num">{index + 1}.</span>
                    {tab.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="services_rt">
            <div className="sevices_rt_inner">
              {tabData.map((tab, index) => (
                <div
                  key={index}
                  id={index + 1}
                  className={`tab-content ${activeTab === index ? 'current' : ''}`}
                >
                  <figure>
                    <img src={tab.imageSrc} alt="img" />
                  </figure>
                  <div className="serv_card_head">
                    <h1>{tab.title}</h1>
                  </div>
                  <p className="card_para">{tab.content}</p>
                </div>
              ))}
            </div>
            </div> </div>
    
            
    </>
      )
    }
    
    
export default ElearningSoftwareDevelopment