import React from 'react'

const Career = () => {
  return (
    <>

<section className="carrer_banner-section careerBanner">
  <div className="carrer_banner">
    <div className="carrer_overlay">
      <div className="container-fluid">
        <div className="row carrer-content">
          <div className="col-md-7 carrer_text carrer_banner_text">
            <h1
              className="wow fadeInUp aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Code. Enjoy. Repeat!</span>
              <br />
              Join Us!
            </h1>
            <p
              className="wow fadeInUp aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              We are always open to talented people. If you believe, you’d be a
              great fit for us, please feel free drop us a line at
              hr@brainstartechnologies.com
            </p>
          </div>
          <div className="col-md-5 text-center">
            <img
              src="/assets/images/join-our-team.png"
              alt="Career Banner Image"
              className="img-responsive wow fadeInRight aos-init"
              data-aos="fade-left"
              data-aos-delay={300}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="facts_section job_section">
  <div className="container-fluid">
    <div className="row">
      <div className="available-jobs row">
        <div className="col-md-7 job_section_column">
          <div className="row">
            <div className="col-md-12 common_tittle text-center">
              <h2
                className="wow fadeInUp aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Now Hiring
                <br />
                <span>Check Our Vacant Seats</span>
              </h2>
            </div>
            <div className="col-md-6 job_column">
              <div
                className="job_column_wrapper wow zoomIn aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="0.4s"
              >
                <h3>Business Development Manager</h3>
                <p>Experience Required : 3-5 Years</p>
              </div>
            </div>
            <div className="col-md-6 job_column">
              <div
                className="job_column_wrapper wow zoomIn aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="0.5s"
              >
                <h3>IONIC Developer</h3>
                <p>Experience Required : 2-5 Years</p>
              </div>
            </div>
            <div className="col-md-6 job_column">
              <div
                className="job_column_wrapper wow zoomIn aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="0.5s"
              >
                <h3>DevOps Engineer</h3>
                <p>Experience Required : 3-6 Years</p>
              </div>
            </div>
            <div className="col-md-6 job_column">
              <div
                className="job_column_wrapper wow zoomIn aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="0.4s"
              >
                <h3>Quality Analyst</h3>
                <p>Experience Required : 2-4 Years</p>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="col-md-5 common_tittle apply-job">
          <h2
            className="wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay="0.4s"
          >
            Apply <span>Now</span>
          </h2>
          <p
            className="wow fadeInUp aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
            In a world where challenges can seem endless, we see infinite
            possibilities.
          </p>
          <div
            className="contact_right_wrapper contact-box-shadow wow zoomIn aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-delay="0.5s"
          >
           <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/apply1.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
            <div className="form-group">
            <input type="text" class="form-control" placeholder="Job Profile" name="job_profile"/>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
           
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="internship-training-program">
  <div className="container">
    <div className="common_tittle">
      <h2
        className="color-white wow fadeInUp aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="0.4s"
      >
        WE ARE GROOMING
        <br />
        <span className="color-white">FRESH TALENT!</span>
      </h2>
      <p
        className="color-white wow fadeInUp aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="0.5s"
      >
        Practical knowledge is a must if you want to enhance your skills. At
        Brain Star Technologies, we offer on-job internship training that allows
        you to work on live projects and updated technologies. We nurture the
        professional capabilities and enhance the overall personality of the
        individuals. So, all you get is a high-quality learning experience.
      </p>
    </div>
    <div className="row">
      <div className="col-md-8 col-md-offset-2" id="internship-form">
        <div
          className="contact_right_wrapper contact-box-shadow aos-init"
          data-aos="zoom-in"
          data-aos-delay="0.5s"
        >
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow zoomIn aos-init"
              data-aos="zoom-in"
              data-aos-delay="0.5s"
            >
              Internship <br />
              <span>Training Form</span>
            </h2>
            <div
              className="msg-bg-color wow zoomIn aos-init"
              data-aos="zoom-in"
              data-aos-delay="0.5s"
            />
          </div>
          <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/apply2.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
            <div className="form-group">
            <input type="text" class="form-control" placeholder="Job Profile" name="job_profile"/>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" >
         
          
          <div className="form-group">
          <select name="program" className="form-control" id="hire_on">
            <option value="Choose Internship Program">Choose Internship Program</option>
          </select>
        </div>
    
        </div>

            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>


        </div>
      </div>
    </div>
  </div>
</section>



    </>
  )
}

export default Career