import React, { useState } from 'react';

const InsuranceSoftwareDevelopment = () => {

    
    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
        {
            title: "RISK MANAGEMENT SOLUTIONS",
            imageSrc: "/assets/images/Picture-1-2.png",
            content:
              "We develop user-friendly interfaces that simplify the procedure of evaluating risks and generating prioritized plans to minimize those risks. Our expertise lies in crafting AI-powered tools for managing risks, enabling companies to gain a holistic understanding of their existing activities and aiding them in enhancing their decision-making processes.",
          },
          {
            title: "INSURANCE DOCUMENT MANAGEMENT SYSTEM",
            imageSrc: "/assets/images/Picture-2-2-1.png",
            content:
              "We design digital solutions for document management, specifically tailored for the insurance sector. These solutions enhance operational effectiveness and time savings by establishing a unified document repository and streamlining workflows. Furthermore, our systems ensure full compliance with industry regulations and standards.",
          },
          {
            title: "INSURANCE CLAIMS MANAGEMENT SOLUTION",
            imageSrc: "/assets/images/Picture-3-2.png",
            content:
              "We create specialized software for managing a high volume of claims and policies while maintaining top-notch quality and efficiency. Our focus is on developing insurance software solutions that streamline complex processes within the insurance sector. Our software automates the creation of claims documents and facilitates seamless payment management.",
          },
          {
            title: "INSURANCE AGENCY SOFTWARE",
            imageSrc: "/assets/images/Picture-4-2.png",
            content:
              "Insurance agency software offers an ideal tool for effectively handling clients and their claims. This technology enables insurance professionals to streamline tasks and expedite deal closures through its robust capabilities. Additionally, utilizing this system provides more room for focusing on addressing client requirements as opposed to administrative processes.",
          },
          {
            title: "INSURANCE CLIENT PORTAL",
            imageSrc: "/assets/images/Picture-5-1.png",
            content:
              "We create sophisticated and all-encompassing systems that enable agencies to enhance their growth and development at an accelerated pace. Our solutions increase the efficiency of agents, facilitating the seamless oversight, tracking, and upkeep of policies. Furthermore, we guarantee that customers experience a safe and smooth process from purchasing insurance to making claims, alleviating any concerns.",
          },
          {
            title: "INSURANCE QUOTING SOFTWARE",
            imageSrc: "/assets/images/Picture-6-1.png",
            content:
              "We empower insurance companies to excel in a challenging industry by furnishing them with resilient software for generating insurance quotes. This software harnesses statistical information and advanced predictive analysis. Our offerings streamline the process of sending automated follow-up emails to individuals who haven't finalized their quote submissions. Additionally, our solutions enable a qualitative assessment of risks and the calculation of premiums.",
          },
          {
            title: "INSURANCE COMPLIANCE SOFTWARE",
            imageSrc: "/assets/images/Picture-7-1.png",
            content:
              "We have developed cloud-native software that empowers businesses to effectively address compliance requirements. Our solutions enable companies to streamline compliance procedures, manage compliance tasks, and enhance overall governance. As a result, the platform minimizes instances of compliance violations.",
          },
          {
            title: "INSURANCE ERP",
            imageSrc: "/assets/images/Picture-8-1.png",
            content:
              "We specialize in providing insurance ERP solutions that offer a unified and all-inclusive approach to overseeing company operations. Our tailored solutions enable businesses to effectively handle every facet of their activities, including but not limited to, the handling of claims, management of policies, administration of contracts, organization of documents, and execution of financial transactions.",
          },
          {
            title: "INSURANCE CRM SOFTWARE",
            imageSrc: "/assets/images/Picture-9-1.png",
            content:
              "We specialize in aiding insurance companies to enhance their operational effectiveness and stay ahead in the market using an extensive CRM system. This robust, integrated solution facilitates the effortless administration of numerous policies, ensuring a smooth customer journey. Additionally, we develop software to streamline claims processing and automatically allocate tasks, simplifying the entire workflow.",
          },
          {
            title: "INSURANCE DATA ANALYTICS",
            imageSrc: "/assets/images/Picture-10-1.png",
            content:
              "Through harnessing advanced data analytics technologies, businesses can maintain a competitive edge in the ever-changing market landscape and capitalize on emerging prospects in their respective industries. Our team creates innovative solutions that empower companies to comprehend their activities, offerings, and customer actions, facilitating enhanced performance and expansion.",
          },
          
        ];

  return (
<>

<section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Insurance software development</span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Brain Star Technologies stands out as a prominent worldwide firm in the realm of insurance software development. They excel in crafting advanced technologies aimed at aiding insurance companies in refining their processes, boosting precision, exerting better authority, and enriching client assistance. By emphasizing automated processes and ingenuity, Brain Star Technologies' offerings enable insurance companies to economize on time and assets, elevate productivity, and maintain a competitive edge in the dynamic and swiftly transforming insurance sector.

            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>



<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>

        
</>
  )
}

export default InsuranceSoftwareDevelopment
