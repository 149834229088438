import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Portfolio = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);
    
      useEffect(() => {
        // Initialize Bootstrap tabs when the component mounts
        const tabElement = document.getElementById('myTab');
        if (tabElement) {
          new window.bootstrap.Tab(tabElement);
        }
      }, []);

  return (
    
    <>
  <section className="section home_banner portfolio_main">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-7 banner_text carrer_banner_text">
              <h1
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.3s"
              >
                <span>Our Portfolio -</span>
                <br />
                Driven By Ideas, Delivered With
                <br /> Enthusiasm
              </h1>
            </div>
            <div
              className="col-md-5 text-center wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.3s"
            >
              <img
                src="/assets/images/portfolio.png"
                alt="Portfolio Banner Image"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
        <div className="ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section blog_page_section portfolio_tabs">
    <div className="container-fluid">
      <div className="row">
        <div className="blog_page_tabs w-100">
          <div className="col-md-12 col-sm-12 col-xs-12 blog_page_tab_left">
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="active">
                <a
                  href="#portfolio-all"
                  aria-controls="portfolio-all"
                  role="tab"
                  data-toggle="tab"
                >
                  All
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#portfolio-websites"
                  aria-controls="portfolio-websites"
                  role="tab"
                  data-toggle="tab"
                >
                  Web
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#portfolio-apps"
                  aria-controls="portfolio-apps"
                  role="tab"
                  data-toggle="tab"
                >
                  Mobile
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content w-100">
            <div role="tabpanel" className="tab-pane active" id="portfolio-all">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/angela-cagelia.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.angelacaglia.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Angela Cagelia</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/be-keepers.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.beekeepersnaturals.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Beekeepers's Naturals</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/charlemagne.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://charlemagnewines.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Charlemagne Wines</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/node-js.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/chess-made-fun.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://chessmadefun.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Chess Made Fun</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/circulon.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://circulon.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Circulon</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/five-dinners.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://fivedinners.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Five Dinners</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/flour-and-branch.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.flourandbranch.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Flour And Branch</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img src="/assets/images/js.png" className="w-100" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/good-american.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.goodamerican.com/en-in"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Good American</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/hollywell-enterprises.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.holywellenterprises.eu"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Holywell Enterprises</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/hu-kitchen.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://hukitchen.com/?view=2"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">HU Kitchens</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/jimmy-games.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://jimmyqgames.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Jimmy Q Games</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/khaite.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://khaite.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Khaite</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/lc-watches.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://lcwatches.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">LC Watches</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt="Perl Logo"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.5s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/ostasz-studio.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="http://www.ostaszstudio.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Ostasz Studio</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.7s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{
                      backgroundImage: "url(/assets/images/ripley-world-wide.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://ripleyworldwide.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Ripley Worldwide</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt="angular-js-icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/skims.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://skims.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Skims</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{
                      backgroundImage:
                        "url(/assets/images/smarter-retirement-solutions.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://smarterretirementsolutions.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">
                      Smarter Retirement Solutions
                    </h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.5s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/third-love.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.thihordlove.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Third Love</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt="Wordpress icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.7s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/three-agency.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://threethirteenagency.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">3.13 Agency</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt="Wordpress icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane" id="portfolio-websites">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/angela-cagelia.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.angelacaglia.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Angela Cagelia</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/be-keepers.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.beekeepersnaturals.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Beekeepers's Naturals</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/charlemagne.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://charlemagnewines.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Charlemagne Wines</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/node-js.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/chess-made-fun.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://chessmadefun.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Chess Made Fun</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/circulon.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://circulon.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Circulon</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/five-dinners.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://fivedinners.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Five Dinners</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/flour-and-branch.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.flourandbranch.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Flour And Branch</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img src="/assets/images/js.png" className="w-100" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/good-american.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.goodamerican.com/en-in"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Good American</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{
                      backgroundImage: "url(/assets/images/hollywell-enterprises.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.holywellenterprises.eu"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Holywell Enterprises</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/hu-kitchen.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://hukitchen.com/?view=2"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">HU Kitchens</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/jimmy-games.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://jimmyqgames.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Jimmy Q Games</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/khaite.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://khaite.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Khaite</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth"
                    style={{ backgroundImage: "url(/assets/images/lc-watches.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://lcwatches.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">LC Watches</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt="Perl Logo"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.5s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/ostasz-studio.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="http://www.ostaszstudio.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Ostasz Studio</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.7s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{
                      backgroundImage: "url(/assets/images/ripley-world-wide.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://ripleyworldwide.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Ripley Worldwide</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt="angular-js-icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/skims.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://skims.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Skims</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.3s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{
                      backgroundImage:
                        "url(/assets/images/smarter-retirement-solutions.jpg)"
                    }}
                  >
                    <div className="mask">
                      <a
                        href="https://smarterretirementsolutions.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">
                      Smarter Retirement Solutions
                    </h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.5s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/third-love.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://www.thihordlove.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">Third Love</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/shopify-logo.png"
                          className="w-100"
                          alt="Wordpress icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className="portfolio_page_column w-100 wow zoomIn"
                  data-aos="zoom-in"
                  data-aos-delay="0.7s"
                >
                  <div
                    className="blog_column_img portfolio_col_img view view-tenth "
                    style={{ backgroundImage: "url(/assets/images/three-agency.jpg)" }}
                  >
                    <div className="mask">
                      <a
                        href="https://threethirteenagency.com"
                        className="info text-white"
                        target="_blank"
                      >
                        Visit Site <i className="zmdi zmdi-link pl-2" />
                      </a>
                    </div>
                  </div>
                  <div className="blog_column_content w-100 text-center">
                    <h3 className="text-uppercase">3.13 Agency</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="image-01 mx-01">
                        <img
                          src="/assets/images/wordpress.png"
                          className="w-100"
                          alt="Wordpress icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div role="tabpanel" class="tab-pane" id="portfolio-apps">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.5s">
                  <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/ClanApp.png);">
                    <div class="mask">
                      <a href="#" title="Android App" target="_blank" class="info text-white"> <i class="zmdi zzmdi-link pl-2"></i></a>
                    
                    </div>
                  </div>
                  <div class="blog_column_content w-100 text-center">
                    <h3 class="text-uppercase">Clan App</h3>
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="image-01 mx-01"><img src="/assets/images/flutter1.png" class="w-100" alt=""></span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.3s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Zerocater2.png);">
                  <div class="mask">
                    <a href="#" title="Android App" class="info text-white" target="_blank"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">ZeroCater</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/reactjs.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/Python.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.5s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Vycab2.png);">
                  <div class="mask">
                    <a href="#" title="Android App" class="info text-white" target="_blank"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">VYCAB Driver App</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/php.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/swift.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/Java.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.7s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Schooltry2.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">School Try</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/wordpress.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="laravel-01.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="flutter1.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.3s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/How-you-think-matters.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">How You Think Matters</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/reactjs.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/laravel-01.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.5s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Define-by-sole.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">Define by sole</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/reactjs.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/laravel-01.png" class="w-100" alt=""></span>
                  </div>
                
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.7s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Henley-backford.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">Henley Backford</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/codeigniter1.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/Objective C.png" class="w-100" alt="objective-c-logo"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.3s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Asuaaq.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">Asuaaq</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/laravel-01.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/kotlin-01.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.5s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/School-App.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">School App</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/react-native.png" class="w-100" alt=""></span>
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.7s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/MNG-lottery.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">MNG Lottery</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/swift.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/Java.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.3s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/MODEL-MY-DIET.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">Model My Diet</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/django.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/andriod.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.5s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Maficonnect.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">Maficonnect</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/swift.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/kotlin-01.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.7s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Smart-trucker.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">SmartTrucker App</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/swift.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/kotlin-01.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.3s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Uptawk2.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">Uptawk</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/swift.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/Java.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="portfolio_page_column w-100 wow zoomIn animated" data-aos="zoom-in" data-aos-delay="0.5s">
                <div class="blog_column_img portfolio_col_img view view-tenth" style="background-image:url(/assets/images/Nerdish.png);">
                  <div class="mask">
                    <a target="_blank" href="#" title="Android App" class="info text-white"> <i class="zmdi zmdi-link pl-2"></i></a>
                  </div>
                </div>
                <div class="blog_column_content w-100 text-center">
                  <h3 class="text-uppercase">Nerdish</h3>
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="image-01 mx-01"><img src="/assets/images/swift.png" class="w-100" alt=""></span>
                    <span class="image-01 mx-01"><img src="/assets/images/kotlin-01.png" class="w-100" alt=""></span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  </section>
</>


  )
}

export default Portfolio