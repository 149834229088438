import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';



const EmergingTechnologies = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);
    
  return (
 
    <>
  <section className="section home_banner crossPlatform">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="col-md-7 banner_text carrer_banner_text">
            <h1
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              <span>Embrace Emerging Technologies</span>
              <br /> Unlock Infinite Possibilities!
            </h1>
            <h3
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              Connect with Global Audiences through Our Innovative Mobile Apps
            </h3>
          </div>
          <div className="col-md-5 text-center">
            <img
              src="/assets/images/emerging-technologies-banner.png"
              alt="emerging technology Banner Image"
              className="img-responsive wow zoomIn"
              data-wow-delay="0.4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "zoomIn"
              }}
            />
          </div>
        </div>
        <div className="ocean contact_ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section app_development_section services-padding">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            Embracing Cutting-Edge Technologies,
            <br />
            <span>Driving Your Business Forward.</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
            An effective technology strategy is essential for your business
            growth, serving as the catalyst for innovation and progress. It can
            either elevate or hinder your business success, so choosing the
            right technology partner is paramount. At Brain Star Technologies,
            we specialize in emerging technologies, driving your business to new
            heights. We deliver groundbreaking solutions, leveraging the latest
            advancements in tech, and creating user-focused applications and
            experiences. Partner with us to embrace the future of technology and
            propel your business growth with precision and commitment.
          </p>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section mt-0 w-100">
          <div className="col-md-6 common_tittle">
            <h2
              className="heading-mt wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.4s"
            >
              {" "}
              Emerging Technologies Services At{" "}
              <span>Brain Star Technologies</span>
            </h2>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/bot.png" alt="bot" />
                </span>
                <h3>Bots Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.7s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/wearable.png" alt="wearable" />
                </span>
                <h3>Wearable Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/hybrid.png" alt="hybrid" />
                </span>
                <h3>Hybrid Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.9s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/ibeacon.png" alt="ibeacon" />
                </span>
                <h3>Ibeacon Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/IOT1.png" alt="iot" />
                </span>
                <h3>IoT App Development</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section app_platform_section w-100 pt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Unleashing the Power of <br />
              <span>Emerging Technologies</span> for Your Business?
            </h2>
          </div>
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Bots Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Bots development is revolutionizing the digital landscape by
                enabling intelligent and interactive virtual assistants. These
                AI-powered bots streamline communication and automate various
                tasks, offering businesses improved efficiency and customer
                engagement. Key advantages of Bots Development include:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Enhanced Customer Support
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Seamless Automation of Repetitive Tasks
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  24/7 Availability
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Personalized User Experience
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Efficient Data Collection and Analysis
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Cost Savings and Increased Productivity
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/bot1.png"
                alt="Node.js Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5">
              <img
                src="/assets/images/iot.jpg"
                alt="iot"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                IoT App Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                IoT App Development harnesses the potential of emerging
                technologies to create innovative and interconnected
                applications that transform businesses. Our expert developers
                build cutting-edge IoT solutions that seamlessly integrate
                devices, data, and processes, providing efficient automation and
                actionable insights. Explore the key advantages of IoT App
                Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Empowering Connected Devices and Smart Solutions
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Seamless Data Management and Interaction
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Flexibility and Scalability for Rapid Growth
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Enabling Real-time Monitoring and Control
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Secure and Robust Connectivity
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Leveraging Advanced Analytics for Informed Decision-making
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Wearable Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Wearable technology has revolutionized the way we interact with
                devices, offering innovative solutions that seamlessly integrate
                with our daily lives. Our skilled developers harness the
                potential of wearable technology to create cutting-edge
                applications, providing unique user experiences and convenience.
                Explore the remarkable advantages of Wearable Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Unleashing the Potential of Wearable Devices
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Seamless Integration with Smart Gadgets
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  User-Friendly and Intuitive Applications
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Embracing Innovative Design and User Interface
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Friendly Licensing and Easy Extension with APIs and Frameworks
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Leveraging Data Analytics and Machine Learning
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/wearable-tech.webp"
                alt="wearable_tech"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5">
              <img
                src="/assets/images/hybrid.png"
                alt="hybrid Development"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Hybrid Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Hybrid development empowers web and mobile app development
                companies to create innovative and versatile applications that
                seamlessly work across multiple platforms. The developers
                efficiently utilize hybrid frameworks to build robust and
                cost-effective applications, offering the best of both worlds in
                app development. Let's explore the remarkable advantages of
                Hybrid Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Unifying Cross-Platform Capabilities
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Efficient Utilization of Device Features
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Rapid Development and Time-to-Market
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Seamless Integration with Native Features
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Cost-Effectiveness and Reduced Development Expenses
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Enhanced User Engagement and Accessibility
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                iBeacon Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                iBeacon technology, an emerging and innovative solution, enables
                web and mobile app development companies to create interactive
                and personalized experiences for users. Our skilled developers
                excel in building cutting-edge applications using iBeacon's
                capabilities, providing seamless integration with various
                devices. Experience the remarkable advantages of iBeacon
                Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Unlocking the Power of Proximity Marketing
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Seamless Integration with Location-Based Services
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  No Complications in Configuration and Setup
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Adopting the Efficacy of Beacon Broadcasting
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Customizable and Extendable with SDKs and APIs
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Harnessing the Potential of Context-Aware Applications
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/ibeacon.png"
                alt="iBeacon Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section mobile_process w-100 bg-light-blue">
          <div className="container">
            <div className="col-md-12 common_tittle text-center">
              <h2
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Our Emerging <span>Technologies Development Process</span>{" "}
              </h2>
            </div>
            <div className="clearfix" />
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/gather-information.png"
                    alt="Information Gathering"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Information Gathering</h3>
                <p>
                  Begin by collecting comprehensive details about the company,
                  its objectives, target audience, and business requirements
                  related to emerging technologies.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Planning</h3>
                <p>
                  Develop a well-structured blueprint based on the gathered
                  information, outlining the architecture, technology choices,
                  user interface, and navigation system for the implementation
                  of emerging technologies.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/business-consulting.png"
                    alt="Planning"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/build-and-lead.png"
                    alt="Design"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Design</h3>
                <p>
                  In the design phase, we craft visually stunning websites that
                  leverage the latest emerging technologies, reflecting your
                  brand identity and captivating your target audience.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Development</h3>
                <p>
                  Transform design prototypes into fully functional websites
                  using the cutting-edge capabilities of emerging technologies,
                  ensuring seamless accessibility and high-performance.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/blend-in-design-and-development.png"
                    alt="Development"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.9s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/testing.png"
                    alt="Testing"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Testing</h3>
                <p>
                  Conduct rigorous testing to verify the functionality of
                  websites developed using emerging technologies, ensuring
                  compatibility across various devices and browsers.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Maintenance</h3>
                <p>
                  The development process doesn't end with the website going
                  live. Regular updates, enhancements, and utilization of the
                  latest advancements in emerging technologies keep the site
                  engaging and relevant.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/support-and-maintenance.png"
                    alt="Maintenance"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="clearfix" />
      </div>
    </div>
  </section>

  
  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 contact_section_left">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Hire NFT Developer
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
        We specialize in building cutting-edge NFT solutions that redefine digital ownership and empower creators and collectors.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>




</>


  )
}

export default EmergingTechnologies


