import React from 'react'

const Industries = () => {

    const handleMouseEnter = (bgClass) => {
        const parBlok = document.querySelector('.block-one');
        parBlok.classList.add(bgClass);
        const bgClasses = ["bg01", "bg02", "bg03", "bg04", "bg05", "bg06", "bg07", "bg08", "bg09"];
        bgClasses.forEach((cls) => {
          if (cls !== bgClass) {
            parBlok.classList.remove(cls);
          }
        });
      };

  return (
    <>
    <section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Industries</span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Harnessing cutting-edge technologies to create innovative and
              highly scalable digital solutions that set new industry standards,
              all aimed at driving transformative and exceptional customer
              experiences. By capitalizing on the advancements in
              next-generation technologies, we are dedicated to crafting
              solutions that not only meet but exceed the evolving needs of our
              customers, ensuring a journey of unparalleled digital
              transformation and satisfaction.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>



<section>
  <link rel="preload" as="image" href="/assets/images/img1.jpg" />
  <link rel="preload" as="image" href="/assets/images/img2.jpg" />
  <link rel="preload" as="image" href="/assets/images/img3.jpeg" />
  <link rel="preload" as="image" href="/assets/images/img4.png" />
  <link rel="preload" as="image" href="/assets/images/Industries 1933-min.jpg" />
  <link rel="preload" as="image" href="/assets/images/img6.jpg" />
  <link rel="preload" as="image" href="/assets/images/img7.jpg" />
  <link rel="preload" as="image" href="/assets/images/img8.jpg" />
  <link rel="preload" as="image" href="/assets/images/img9.png" />
  <div className="block-one bg01">
    <div className="block-one-flex">
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg01")} >
        <div className="block-in-bg" />
        <div className="block-in-title">Healthcare &amp; Life Sciences</div>
        <div className="block-in-ul">
          <div className="block-in-li">Telemedicine apps</div>
          <div className="block-in-li">Hospital/patient apps</div>
          <div className="block-in-li">EHR/EMR</div>
          <div className="block-in-li">IoMT</div>
          <div className="block-in-li">Remote patient monitoring</div>
          <div className="block-in-li">Hospital inventory management</div>
        </div>
        <div className="block-in-bot">
          <a className="arrow-btn" href="healthcare-software-development.html">
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg02")} >
        <div className="block-in-bg" />
        <div className="block-in-title">Finance, banking and insurance</div>
        <div className="block-in-ul">
          <div className="block-in-li">Digital banking</div>
          <div className="block-in-li">Online payment software</div>
          <div className="block-in-li">Digital lending solutions</div>
          <div className="block-in-li">Trading &amp; investment platforms</div>
          <div className="block-in-li">AML/KYC</div>
          <div className="block-in-li">InsurTech</div>
        </div>
        <div className="block-in-bot">
          <a className="arrow-btn" href="finance-software-development.html">
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg03")}>
        <div className="block-in-bg" />
        <div className="block-in-title">eCommerce &amp; Retail</div>
        <div className="block-in-ul">
          <div className="block-in-li">Online shopping</div>
          <div className="block-in-li">Enterprise marketplaces</div>
          <div className="block-in-li">NFT marketplaces</div>
          <div className="block-in-li">Content management systems</div>
          <div className="block-in-li">Inventory management platforms</div>
          <div className="block-in-li">Trade platforms</div>
        </div>
        <div className="block-in-bot">
          <a className="arrow-btn" href="ecommerce-development-services.html">
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg04")}>
        <div className="block-in-bg" />
        <div className="block-in-title">Hi-Tech &amp; Innovations</div>
        <div className="block-in-ul">
          <div className="block-in-li">NFT</div>
          <div className="block-in-li">Blockchain</div>
          <div className="block-in-li">Metaverse</div>
          <div className="block-in-li">Machine learning</div>
          <div className="block-in-li">Digital twins</div>
          <div className="block-in-li">AR/VR/MR</div>
        </div>
        <div className="block-in-bot">
          <a className="arrow-btn">
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg05")}>
        <div className="block-in-bg" />
        <div className="block-in-title">eLearning &amp; Education</div>
        <div className="block-in-ul">
          <div className="block-in-li">Learning management systems</div>
          <div className="block-in-li">eLearning platforms</div>
          <div className="block-in-li">Mobile eLearning platforms</div>
          <div className="block-in-li">Distance learning platforms</div>
          <div className="block-in-li">Learning experience platforms</div>
          <div className="block-in-li">VR training apps</div>
        </div>
        <div className="block-in-bot">
          <a className="arrow-btn" href="elearning-software-development.html">
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg06")}>
        <div className="block-in-bg" />
        <div className="block-in-title">Automotive industry</div>
        <div className="block-in-ul">
          <div className="block-in-li">Dealership management systems</div>
          <div className="block-in-li">Connected vehicles</div>
          <div className="block-in-li">Remote vehicle control</div>
          <div className="block-in-li">Vehicle data management</div>
          <div className="block-in-li">Digital twins</div>
        </div>
        <div className="block-in-bot">
          <a className="arrow-btn">
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg07")}>
        <div className="block-in-bg" />
        <div className="block-in-title">Media &amp; Entertainment</div>
        <div className="block-in-ul">
          <div className="block-in-li">Digital asset management platforms</div>
          <div className="block-in-li">Social media apps</div>
          <div className="block-in-li">Video on Demand</div>
          <div className="block-in-li">Video streaming</div>
          <div className="block-in-li">Live streaming</div>
        </div>
        <div className="block-in-bot">
          <a
            className="arrow-btn"
            href="entertainment-and-media-solutions.html"
          >
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg08")}>
        <div className="block-in-bg" />
        <div className="block-in-title">Logistics &amp; Transportation</div>
        <div className="block-in-ul">
          <div className="block-in-li">Fleet management systems</div>
          <div className="block-in-li">Warehouse management</div>
          <div className="block-in-li">Vehicle maintenance</div>
          <div className="block-in-li">Remote vehicle monitoring</div>
          <div className="block-in-li">Transport platforms</div>
        </div>
        <div className="block-in-bot">
          <a
            className="arrow-btn"
            href="logistics-transportation-software-development.html"
          >
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="block-in" onMouseEnter={() => handleMouseEnter("bg09")}>
        <div className="block-in-bg"></div>
        <div className="block-in-title">Enterprise &amp; Manufacturing</div>
        <div className="block-in-ul">
          <div className="block-in-li">Content management systems</div>
          <div className="block-in-li">Enterprise resource planning</div>
          <div className="block-in-li">Predictive maintenance</div>
          <div className="block-in-li">Process automation software</div>
          <div className="block-in-li">Digital twins</div>
          <div className="block-in-li">IIoT</div>
        </div>
        <div className="block-in-bot">
          <a href="enterprise-software-development.html" className="arrow-btn">
            <svg
              className="arrow-btn__svg"
              width={110}
              height={18}
              viewBox="0 0 110 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L17 8.99999L9 17" stroke="#ffffff" strokeWidth={2} />
              <path
                d="M0 9.00018L17 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M99 1L107 8.99999L99 17"
                stroke="#ffffff"
                strokeWidth={2}
              />
              <path
                d="M90 9.00018L107 9.00018"
                stroke="#ffffff"
                strokeWidth={2}
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  
<div class="block-one-2">
  <div>
        <div>
          <div>
      
              <div class="swiper-slide">
                  <div class="block-in block-in-bg11">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">Healthcare & Life Sciences</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Telemedicine apps</div>
                          <div class="block-in-li">Hospital/patient apps</div>
                          <div class="block-in-li">EHR/EMR</div>
                          <div class="block-in-li">IoMT</div>
                          <div class="block-in-li">Remote patient monitoring</div>
                          <div class="block-in-li">Hospital inventory management</div>
                      </div>
                      <div class="block-in-bot" href="healthcare-software-development.html">
                          <a class="arrow-btn">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
                      </div>
                  </div>
              </div>    
                  
              <div class="swiper-slide">
                  <div class="block-in block-in-bg22">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">Finance, banking and insurance</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Digital banking</div>
                          <div class="block-in-li">Online payment software</div>
                          <div class="block-in-li">Digital lending solutions</div>
                          <div class="block-in-li">Trading & investment platforms</div>
                          <div class="block-in-li">AML/KYC</div>
                          <div class="block-in-li">InsurTech</div>
                      </div>
                      <div class="block-in-bot">
                          <a class="arrow-btn" href="finance-software-development.html">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
                      </div>
                  </div>
              </div>

              <div class="swiper-slide">
                  <div class="block-in block-in-bg33">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">eCommerce & Retail</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Online shopping</div>
                          <div class="block-in-li">Enterprise marketplaces</div>
                          <div class="block-in-li">NFT marketplaces</div>
                          <div class="block-in-li">Content management systems</div>
                          <div class="block-in-li">Inventory management platforms</div>
                          <div class="block-in-li">Trade platforms</div>
                      </div>
                      <div class="block-in-bot">
          
                            <a class="arrow-btn" href="ecommerce-development-services.html">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
                      </div>
                  </div>
              </div>

              <div class="swiper-slide">
                  <div class="block-in block-in-bg44">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">Hi-Tech & Innovations</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">NFT</div>
                          <div class="block-in-li">Blockchain</div>
                          <div class="block-in-li">Metaverse</div>
                          <div class="block-in-li">Machine learning</div>
                          <div class="block-in-li">Digital twins</div>
                          <div class="block-in-li">AR/VR/MR</div>
                      </div>
                      <div class="block-in-bot">
          
                            <a class="arrow-btn" href="blockchain.html">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
          
                      </div>
                  </div>
              </div>


              <div class="swiper-slide">
                  <div class="block-in block-in-bg55">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">eLearning & Education</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Learning management systems</div>
                          <div class="block-in-li">eLearning platforms</div>
                          <div class="block-in-li">Mobile eLearning platforms</div>
                          <div class="block-in-li">Distance learning platforms</div>
                          <div class="block-in-li">Learning experience platforms</div>
                          <div class="block-in-li">VR training apps</div>
                      </div>
                      <div class="block-in-bot">
          
                            <a class="arrow-btn" href="elearning-software-development.html">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
          
                      </div>
                  </div>
              </div>


              <div class="swiper-slide">
                  <div class="block-in block-in-bg66">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">Automotive industry</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Dealership management systems</div>
                          <div class="block-in-li">Connected vehicles</div>
                          <div class="block-in-li">Remote vehicle control</div>
                          <div class="block-in-li">Vehicle data management</div>
                          <div class="block-in-li">Digital twins</div>
                      </div>
                      <div class="block-in-bot">
          
                            <a class="arrow-btn">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
          
                      </div>
                  </div>
              </div>


              <div class="swiper-slide">
                  <div class="block-in block-in-bg77">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">Media & Entertainment</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Digital asset management platforms</div>
                          <div class="block-in-li">Social media apps</div>
                          <div class="block-in-li">Video on Demand</div>
                          <div class="block-in-li">Video streaming</div>
                          <div class="block-in-li">Live streaming</div>
                      </div>
                      <div class="block-in-bot">
          
                          <a class="arrow-btn" href="entertainment-and-media-solutions.html">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
          
                      </div>
                  </div>
              </div>


              <div class="swiper-slide">
                  <div class="block-in block-in-bg88">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">Logistics & Transportation</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Fleet management systems</div>
                          <div class="block-in-li">Warehouse management</div>
                          <div class="block-in-li">Vehicle maintenance</div>
                          <div class="block-in-li">Remote vehicle monitoring</div>
                          <div class="block-in-li">Transport platforms</div>
                      </div>
                      <div class="block-in-bot">
          
                          <a class="arrow-btn" href="logistics-transportation-software-development.html">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
                      </div>
                  </div>
              </div>



              <div class="swiper-slide">
                  <div class="block-in block-in-bg99">
                      <div class="block-in-bg"></div>
                      <div class="block-in-title">Enterprise & Manufacturing</div>
                      <div class="block-in-ul">
                          <div class="block-in-li">Content management systems</div>
                          <div class="block-in-li">Enterprise resource planning</div>
                          <div class="block-in-li">Predictive maintenance</div>
                          <div class="block-in-li">Process automation software</div>
                          <div class="block-in-li">Digital twins</div>
                          <div class="block-in-li">IIoT</div>
                      </div>
                      <div class="block-in-bot">
          
                           <a href="enterprise-software-development.html" class="arrow-btn">
                            <svg class="arrow-btn__svg"
                                 width="110"
                                 height="18"
                                 viewBox="0 0 110 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 1L17 8.99999L9 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M0 9.00018L17 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                  
                              <path d="M99 1L107 8.99999L99 17"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                              <path d="M90 9.00018L107 9.00018"
                                    stroke="#ffffff"
                                    stroke-width="2" />
                            </svg>
                          </a>
          
                      </div>
                  </div>
              </div>
              
          </div>
      </div>
      
  </div>
</div>


</section>


    </>
  )
}

export default Industries