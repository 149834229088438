import React, { useState } from 'react';

const EnterpriseSoftwareDevelopment = () => {

    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
        {
            title: "ENTERPRISE RESOURCE PLANNING SYSTEMS",
            imageSrc: "/assets/images/Picture-2.png",
            content:
              "Tailored ERP software development offers immense value to businesses by enhancing inter-departmental communication and refining operational workflows. Through our adeptness in crafting personalized ERP solutions, we empower enterprises to streamline operations, optimize resource utilization, and drive informed decision-making via instantaneous data analytics. Our team collaborates intimately with clients, grasping their distinct prerequisites to engineer bespoke ERP resolutions that precisely align with their needs.",
          },
          {
            title: "CUSTOMER RELATIONSHIP MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Picture-2-1.png",
            content:
              "Brain Star Technologies is a company that specializes in crafting customized enterprise CRM software designed to suit the distinct requirements of companies. Our skilled team of developers utilizes the latest technology and advanced development approaches to provide CRM systems that can expand as needed and uphold high levels of security. These systems are aimed at boosting interactions with customers, streamlining sales and marketing efforts, and ultimately raising overall customer contentment. Our collaboration with clients is close-knit, as we strive to build user-intuitive CRM solutions that seamlessly integrate with their current setups. We oversee all phases of development, starting from conceptualization, going through execution, and continuing with support.",
          },
          {
            title: "BUSINESS PROCESS MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Picture-2-2.png",
            content:
              "BPM (Business Process Management) software presents an ideal opportunity for enterprises aiming to enhance effectiveness, optimize earnings, and secure a competitive advantage. By utilizing user-friendly tools that both automate and oversee processes, organizations can refine their activities and lower expenses. Our developed BPM software empowers businesses to enhance their output and receive up-to-the-minute insights into their operational performance. Choosing to adopt this solution stands as a prudent decision, given that the advantages will swiftly manifest.",
          },
          {
            title: "ENTERPRISE MASTER DATA MANAGEMENT SYSTEM",
            imageSrc: "/assets/images/Picture-2-3.png",
            content:
              "Brain Star Technologies  has extensive experience creating advanced solutions for enterprise master data management (MDM), which give organizations a unified and comprehensive view of their data. We use the latest technologies like enterprise data warehouses to achieve this goal. Our skill in managing enterprise database systems also lets organizations have full oversight and transparency into their master data resources. This results in improved return on investment (ROI) and more efficient workflows for our clients.",
          },
          {
            title: "ENTERPRISE DOCUMENT MANAGEMENT SYSTEMS",
            imageSrc: "/assets/images/Picture-2-4.png",
            content:
              "As businesses become more intricate, handling documents and information is progressively more challenging. Brain Star Technologies is tackling this issue by creating enterprise document management systems. These systems assist businesses in swiftly and conveniently storing, arranging, and retrieving their documents. The EDMS offers robust features for managing document workflows, ensuring document security, implementing automation, and enhancing collaboration. This enables enterprises to simplify operations and enhance effectiveness.",
          },
          {
            title: "ENTERPRISE ASSET MANAGEMENT SOFTWARE (EAM)",
            imageSrc: "/assets/images/Picture-2-5.png",
            content:
              "We specialize in assisting businesses with making informed investments in suitable solutions. One essential tool for companies aiming to oversee and control their tangible resources while promptly spotting areas for enhancement is inventory management software. Through harnessing the robust abilities of Enterprise Asset Management (EAM), enterprises can extract valuable understandings about their possessions. This empowers them to take more astute actions, resulting in enhanced performance, operational effectiveness, and a significant uplift for their businesses.",
          },
          {
            title: "CONTENT MANAGEMENT SYSTEM (CMS)",
            imageSrc: "/assets/images/Picture-2-6.png",
            content:
              "We specialize in cutting-edge CMS and ECM software, empowering enterprises to centralize content management. Our systems enable seamless content creation, modification, and storage, all within a unified platform. This facilitates comprehensive monitoring and assessment of content performance, providing a strategic edge in comprehending customer actions and inclinations. Through Brain Star Technologies, organizations attain an excellent avenue for overseeing their web presence, guaranteeing targeted communication with precision timing.",
          },
          {
            title: "HR AND TALENT MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Picture-2-7.png",
            content:
              "We specialize in crafting advanced HRM solutions that empower enterprises to effectively oversee their human resources and streamline their talent procurement procedures. Through harnessing our state-of-the-art technologies, organizations can automate monotonous HR chores like salary calculations, attendance monitoring, performance evaluation, and staff induction, thus conserving precious time and resources. Furthermore, we develop platforms capable of assessing employee productivity and recognizing patterns, leading to improved operational effectiveness and optimized financial gains.",
          },
          {
            title: "ENTERPRISE LEARNING MANAGEMENT SYSTEMS",
            imageSrc: "/assets/images/Picture-2-8.png",
            content:
              "Utilizing a corporate Learning Management System (LMS) offers a potent resource and an indispensable instrument for your enterprise. This platform enables companies to optimize their training and growth workflows, ensuring that employees are consistently equipped with current knowledge and abilities. Through an extended enterprise LMS, organizations can monitor employee advancements, gauge the efficiency of their training initiatives, and establish customized learning trajectories to enhance overall productivity.",
          },
          {
            title: "SUPPLY CHAIN MANAGEMENT",
            imageSrc: "/assets/images/Picture-2-9.png",
            content:
              "The professionals at Brain Star Technologies comprehend the key factors for achieving prosperity in the modern, interconnected business landscape. We develop supply chain management (SCM) solutions that aid in monitoring and overseeing the complete supply chain journey, from procuring raw materials to distributing them to clients. By integrating our SCM systems, businesses aiming to optimize their processes can enjoy significant advantages, including up-to-the-minute information, automated workflows, and enhanced transparency across the entirety of the supply chain.",
          },
          {
            title: "ORDER MANAGEMENT SYSTEMS",
            imageSrc: "/assets/images/Picture-2-10.png",
            content:
              "Brain Star Technologies specializes in creating user-friendly order management systems that include all the vital functionalities required for businesses to enhance operational effectiveness, lower expenses, and enhance customer support through the automation of order handling and monitoring. These solutions offer up-to-the-minute visibility into orders and stock, empowering companies to make more informed choices and optimize earnings. Moreover, they aid enterprises in overseeing intricate supply networks, guaranteeing prompt and precise order fulfillment.",
          },
          {
            title: "PROCUREMENT MANAGEMENT SOFTWARE",
            imageSrc: "/assets/images/Picture-2-11.png",
            content:
              "The utilization of eProcurement software is becoming increasingly popular among businesses, as it offers seamless tracking of orders, real-time inventory monitoring, and easy supplier performance analysis. Such systems automate repetitive tasks, including invoice processing and contract management, thereby enabling significant resource savings for your company. We develop solutions that help companies streamline their procurement processes, leading to improved efficiency and cost-effectiveness.",
          },
          {
            title: "BILLING AND ACCOUNTING SOFTWARE",
            imageSrc: "/assets/images/Picture-2-12.png",
            content:
              "Utilizing accounting and budgeting software is a proven method to efficiently simplify the task of tracking revenue and costs, automate the collection of payments, and generate timely financial statements that support improved decision-making for companies. By leveraging sophisticated financial planning software designed for enterprises, businesses can refine their accounting procedures, improve cash flow, and provide extra benefits to clients within the field of corporate accounting. This ultimately leads to time and resource savings for the business.",
          },
          {
            title: "BUSINESS INTELLIGENCE SOFTWARE (BI)",
            imageSrc: "/assets/images/Picture-2-14.png",
            content:
              "By utilizing the valuable insights derived from data-driven analysis through Business Intelligence (BI) software, businesses can recognize patterns, streamline operations, and discover avenues for expansion. These tools empower companies to enhance decision-making precision and operational effectiveness, leading to time, cost, and resource savings. By presenting a holistic view of a company's performance, BI software enables enterprises to strategically adapt for optimal outcomes, maintain competitiveness, and boost their financial gains.",
           },
 ];
      

  return (
<>
<section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Enterprise software development</span>
            </h1>
            <p
              className="wow fadeInUp aos-init"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Brain Star Technologies stands as a provider of tailored software
              solutions for businesses, focusing on crafting custom applications
              for the web and mobile platforms. Our expertise encompasses UI/UX
              design, software testing, and ensuring top-notch quality. The
              Agile development approach is our compass, ensuring the timely and
              cost-effective delivery of premium solutions. Our dedication lies
              in establishing enduring relationships with clients, backed by a
              proven history of achievements across diverse sectors.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>



<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>

</>  )
}

export default EnterpriseSoftwareDevelopment