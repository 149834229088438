import React, { useState, useEffect } from 'react';
import { useLocation ,Link} from 'react-router-dom';
import { Element,scroller } from 'react-scroll';

export const Header = () => {

  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Close the menu when the location changes
  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  // Define a function to handle scrolling to the section
  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  useEffect(() => {
    // Get the section ID from the URL hash
    const sectionIdFromHash = location.hash.substring(1);

    // Scroll to the section if the hash is present in the URL
    if (sectionIdFromHash) {
      scrollToSection(sectionIdFromHash);
    }
  }, [location]);
 
  return (
    <>


<Element name="top" >
</Element>
 
<div className="announcement-bar">
  <div className="announcement-text">
    <Link to="/#seoform">Free SEO Check</Link>
  </div>
  <div className="social-icons">
    <a
      className="btn1 m-1 "
      href="https://www.facebook.com/brainstartech"
    >
      <i className="fa fa-facebook-f" />
    </a>
    <a className="btn1 m-1 " href="https://twitter.com/BrainStarTech">
      <i className="fa fa-twitter" />
    </a>
    <a
      className="btn1 m-1 "
      href="https://www.instagram.com/brainstartechnologies"
    >
      <i className="fa fa-instagram" />
    </a>
    <a
      className="btn1 m-1 "
      href="https://www.linkedin.com/company/brain-star-technologies"
    >
      <i className="fa fa-linkedin" />
    </a>
    <a className="btn1 m-1 " href="https://wa.me/+917986324471">
      <i className="fa fa-whatsapp" />
    </a>
  </div>
</div>

    <nav className={`cd-nav-container  ${isNavOpen ? 'show' : ''}`} id="cd-nav">
  <header>
    <Link to="#0" onClick={toggleNav} className="close-toggle " >
      Close
    </Link>
  </header>


  <ul className="cd-nav">
  <li>
  <Link className="" to="/">
      Home
    </Link>
  </li>
  <li className="dropdown">
  <a
  role="button"
  data-toggle="collapse"
  href="#collapseExample1"
  aria-expanded="false"
  aria-controls="collapseExample1"
  className=" collapsed"
>
  Hire Us <i className="fa fa-caret-down" aria-hidden="true" />
</a>

    <div
  className="collapse"
  id="collapseExample1"
  aria-expanded="false"
  style={{ height: 0 }}
>
  <div className="well mobile-submenu">
    <div className="menus-grid services-menu">
      <span className="icon-webdesign menu-icons" />
      <ul>
        <li>
          <Link to="/outsourcing-web-app-development"
           
            className="hvr-forward"
          >
            <img src="/assets/images/web.png" height="20px" />
            Website Developer
          </Link>
        </li>
        <li>
          <Link 
             to="/web-design-outsourcing"   
              className="hvr-forward">
            <img src="/assets/images/ui-ux.png" height="20px" />
            UI/UX Designer
          </Link>
        </li>
        <li>
          <Link to="/mobile-app-development#hire"  className="hvr-forward">
            <img src="/assets/images/mobile.svg" height="20px" />
            Mobile App Developer
          </Link>
        </li>
        <li>
          <Link to="emerging-technologies#hire"  className="hvr-forward">
            <img src="/assets/images/emerging.png" height="20px" />
            Emerging Technologies
          </Link>
        </li>
        <li>
          <Link to="/digital-marketing#hire"  className="hvr-forward">
            <img src="/assets/images/digital.png" height="20px" />
            Digital Marketing
          </Link>
        </li>
        <li>
          <Link to="/internet-of-things#hire"  className="hvr-forward">
            <img src="/assets/images/IOT1.png" height="20px" />
            Internet of Things
          </Link>
        </li>
        <li>
          <Link
            to="/cross-platform-app-development#hire" 
            className="hvr-forward"
          >
            <img src="/assets/images/cross-app.png" height="20px" />
            Cross-Platform App Developer
          </Link>
        </li>
        <li>
          <Link to="/managed-services#hire"  className="hvr-forward">
            <img src="/assets/images/managed-services.png" height="20px" />
            Managed Services
          </Link>
        </li>
        <li>
          <Link to="/blockchain#hire"  className="hvr-forward">
            <img src="/assets/images/blockchain.png" height="20px" />
            Blockchain Developer
          </Link>
        </li>
        <li>
          <Link to="/nft#hire"  className="hvr-forward">
            <img src="/assets/images/nft.png" height="20px" />
            NFT Developer
          </Link>
        </li>
      </ul>
    </div>
  </div>
</div>


  </li>
  <li>
  <Link to="/about-us" className="">
      About Us
    </Link>
  </li>
  <li className="dropdown">
  <a
  role="button"
  data-toggle="collapse"
  href="#collapseExample2"
  aria-expanded="false"
  aria-controls="collapseExample2"
  className=" collapsed"
>
  Services <i className="fa fa-caret-down" aria-hidden="true" />
</a>

<div
  className="collapse"
  id="collapseExample2"
  aria-expanded="true"
>
  <div className="well mobile-submenu">

        <div className="menus-grid services-menu">
          <span className="icon-webdesign menu-icons" />
          <ul>
            <li className="menu-first">
              <Link
                to="/outsourcing-web-app-development"
                
                className="hvr-forward"
              >
                <img src="/assets/images/web.png" height="20px" />
                &nbsp;Website Development
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/web-design-outsourcing" className="hvr-forward">
                <img src="/assets/images/ui-ux.png" height="20px" />
                &nbsp;UI/UX Design
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/mobile-app-development" className="hvr-forward">
                <img src="/assets/images/mobile.svg" height="20px" />
                &nbsp;Mobile App Development
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/emerging-technologies" className="hvr-forward">
                <img src="/assets/images/emerging.png" height="20px" />
                &nbsp;Emerging Technologies
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/digital-marketing" className="hvr-forward">
                <img src="/assets/images/digital.png" height="20px" />
                &nbsp;Digital Marketing
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/internet-of-things" className="hvr-forward">
                <img src="/assets/images/IOT1.png" height="20px" />
                &nbsp;Internet of Things
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/cross-platform-app-development"
                className="hvr-forward"
              >
                <img src="/assets/images/cross-app.png" height="20px" />
                &nbsp;Cross-Platform App Development
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/managed-services" className="hvr-forward">
                <img src="/assets/images/managed-services.png" height="20px" />
                &nbsp;Managed Services
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/blockchain" className="hvr-forward">
                <img src="/assets/images/blockchain.png" height="20px" />
                &nbsp;Blockchain
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/nft" className="hvr-forward">
                <img src="/assets/images/nft.png" height="20px" />
                &nbsp;NFT
              </Link>
            </li>
            <div className="ocean">
              <div className="wave nav_wave" />
              <div className="wave nav_wave" />
            </div>
          </ul>
        </div>
      </div>
    </div>
  </li>
  <li>
  <Link to="/technologies" className="">
      Technologies
    </Link>
  </li>
  <li>
  <Link to="/portfolio" className="">
      Portfolio
    </Link>
  </li>
  <li className="dropdown">

    <a
  role="button"
  data-toggle="collapse"
  href="#collapseExample3"
  aria-expanded="false"
  aria-controls="collapseExample3"
  className=" collapsed"
>
Industries <i className="fa fa-caret-down" aria-hidden="true" />
</a>


    <div
  className="collapse"
  id="collapseExample3"
  aria-expanded="true"
>
  <div className="well mobile-submenu">
        <div className="menus-grid services-menu">
          <span className="icon-webdesign menu-icons" />
          <ul>
            <li className="menu-first">
              <Link
                to="/healthcare-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Healthcare &amp; Life Sciences
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/telecom-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Telecommunications
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/enterprise-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Enterprise
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/finance-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Finance
              </Link>
            </li>
            <li className="menu-first">
            <Link className="hvr-forward">
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Automotive
              </Link>
            </li>
            <li className="menu-first">
            <Link to="/government-it-solutions" className="hvr-forward">
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Government
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/banking-software-development-services"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Banking
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/entertainment-and-media-solutions"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Media &amp; Entertainment
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/logistics-transportation-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Logistics &amp; Transportation
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/insurance-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Insurance
              </Link>
            </li>
            <li className="menu-first">
            <Link className="hvr-forward">
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Construction &amp; Real Estate
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/marketing-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Marketing &amp; Advertising
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/ecommerce-development-services"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;eCommerce &amp; Retail
              </Link>
            </li>
            <li className="menu-first">
            <Link className="hvr-forward">
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Energy &amp; Utilities
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/manufacturing-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Manufacturing
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/elearning-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp;Education
              </Link>
            </li>
            <li className="menu-first">
              <Link
                to="/agriculture-software-development"
                className="hvr-forward"
              >
                <i className="fa fa-chevron-circle-right" aria-hidden="true" />
                &nbsp; Agriculture{" "}
              </Link>
            </li>
            <div className="ocean">
              <div className="wave nav_wave" />
              <div className="wave nav_wave" />
            </div>
          </ul>
        </div>
      </div>
    </div>
  </li>

  
  <li>
  <Link to="/blogs" className="">
     Blogs
    </Link>
  </li>

  <li>
  <Link to="/contact-us" className="">
      Contact Us
    </Link>
  </li>


</ul>
</nav>

<nav className="container-fluid">
  <div className="brand-logo">
  <Link to="/" className="">
      <img
        src="/assets/images/brain-star-logo-removebg.png"
        height="90px"
        width="235px"
      />
    </Link>
  </div>
  <div className="navbar-right">
    <ul className="nav-list">
      <li>
      <Link className="" to="/">
          Home
        </Link>
      </li>
      <li className="dropdown">
        <Link
          className=" dropdown-toggle disabled"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Hire Us <i className="fa fa-caret-down" aria-hidden="true" />
        </Link>
        <div className="dropdown-menu mega-menu">
          <div className="row">
            <div className="menus-grid services-menu">
              <span className="icon-webdesign menu-icons" />
              <ul>
                <li className="menu-first">
                  <Link
                    to="/outsourcing-web-app-development#hire" 

                  
                    className="hvr-forward"
                  >
                    <img src="/assets/images/web.png" height="20px" />
                    &nbsp;Hire Website Developer
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/web-design-outsourcing#hire" 

                 
                    
                    className="hvr-forward"
                  >
                    <img src="/assets/images/ui-ux.png" height="20px" />
                    &nbsp;Hire UI/UX Designer
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/mobile-app-development#hire" 
                    className="hvr-forward"
                  >
                    <img src="/assets/images/mobile.svg" height="20px" />
                    &nbsp;Hire Mobile App Developer
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/emerging-technologies#hire" 
                    className="hvr-forward"
                  >
                    <img src="/assets/images/emerging.png" height="20px" />
                    &nbsp;Hire Emerging Technologies Specialists
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/digital-marketing#hire"  className="hvr-forward">
                    <img src="/assets/images/digital.png" height="20px" />
                    &nbsp;Hire Digital Marketing Experts
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/internet-of-things#hire" 
                    className="hvr-forward"
                  >
                    <img src="/assets/images/IOT1.png" height="20px" />
                    &nbsp;Hire Internet of Things Specialists
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/cross-platform-app-development#hire" 
                    className="hvr-forward"
                  >
                    <img src="/assets/images/cross-app.png" height="20px" />
                    &nbsp;Hire Cross-Platform App Developer
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/managed-services#hire"  className="hvr-forward">
                    <img src="/assets/images/managed-services.png" height="20px" />
                    &nbsp;Hire Managed Services Specialists
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/blockchain#hire"  className="hvr-forward">
                    <img src="/assets/images/blockchain.png" height="20px" />
                    &nbsp;Hire Blockchain Developer
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/nft#hire"  className="hvr-forward">
                    <img src="/assets/images/nft.png" height="20px" />
                    &nbsp;Hire NFT Developer
                  </Link>
                </li>
                <div className="ocean">
                  <div className="wave nav_wave" />
                  <div className="wave nav_wave" />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li>
      <Link to="/about-us" className="">
          About Us
        </Link>
      </li>
      <li className="dropdown">
        <Link
          to="/services"
          className=" dropdown-toggle disabled"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Services <i className="fa fa-caret-down" aria-hidden="true" />
        </Link>
        <div className="dropdown-menu mega-menu">
          <div className="row">
            <div className="menus-grid services-menu">
              <span className="icon-webdesign menu-icons" />
              <ul>
                <li className="menu-first">
                  <Link
                    to="/outsourcing-web-app-development"
                    className="hvr-forward"
                  >
                    <img src="/assets/images/web.png" height="20px" />
                    &nbsp;Website Development
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/web-design-outsourcing" className="hvr-forward">
                    <img src="/assets/images/ui-ux.png" height="20px" />
                    &nbsp;UI/UX Design
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/mobile-app-development" className="hvr-forward">
                    <img src="/assets/images/mobile.svg" height="20px" />
                    &nbsp;Mobile App Development
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/emerging-technologies" className="hvr-forward">
                    <img src="/assets/images/emerging.png" height="20px" />
                    &nbsp;Emerging Technologies
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/digital-marketing" className="hvr-forward">
                    <img src="/assets/images/digital.png" height="20px" />
                    &nbsp;Digital Marketing
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/internet-of-things" className="hvr-forward">
                    <img src="/assets/images/IOT1.png" height="20px" />
                    &nbsp;Internet of Things
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/cross-platform-app-development"
                    className="hvr-forward"
                  >
                    <img src="/assets/images/cross-app.png" height="20px" />
                    &nbsp;Cross-Platform App Development
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/managed-services" className="hvr-forward">
                    <img src="/assets/images/managed-services.png" height="20px" />
                    &nbsp;Managed Services
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/blockchain" className="hvr-forward">
                    <img src="/assets/images/blockchain.png" height="20px" />
                    &nbsp;Blockchain
                  </Link>
                </li>
                <li className="menu-first">
                <Link to="/nft" className="hvr-forward">
                    <img src="/assets/images/nft.png" height="20px" />
                    &nbsp;NFT
                  </Link>
                </li>
                <div className="ocean">
                  <div className="wave nav_wave" />
                  <div className="wave nav_wave" />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li>
      <Link to="/technologies" className="">
          Technologies
        </Link>
      </li>
      <li>
      <Link to="/portfolio" className="">
          Portfolio
        </Link>
      </li>
      <li className="dropdown">
        <Link
          to="/industries"
          className=" dropdown-toggle disabled"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Industries <i className="fa fa-caret-down" aria-hidden="true" />
        </Link>
        <div className="dropdown-menu mega-menu">
          <div className="row">
            <div className="menus-grid services-menu">
              <span className="icon-webdesign menu-icons" />
              <ul>
                <li className="menu-first">
                  <Link
                    to="/healthcare-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Healthcare &amp; Life Sciences
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/telecom-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Telecommunications
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/enterprise-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Enterprise
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/finance-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Finance
                  </Link>
                </li>
                <li className="menu-first">
                <Link className="hvr-forward">
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Automotive
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/government-it-solutions"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Government
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/banking-software-development-services"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Banking
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/entertainment-and-media-solutions"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Media &amp; Entertainment
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/logistics-transportation-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Logistics &amp; Transportation
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/insurance-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Insurance
                  </Link>
                </li>
                <li className="menu-first">
                <Link className="hvr-forward">
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Construction &amp; Real Estate
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/marketing-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Marketing &amp; Advertising
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/ecommerce-development-services"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;eCommerce &amp; Retail
                  </Link>
                </li>
                <li className="menu-first">
                <Link className="hvr-forward">
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Energy &amp; Utilities
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/manufacturing-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Manufacturing
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/elearning-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp;Education
                  </Link>
                </li>
                <li className="menu-first">
                  <Link
                    to="/agriculture-software-development"
                    className="hvr-forward"
                  >
                    <i
                      className="fa fa-chevron-circle-right"
                      aria-hidden="true"
                    />
                    &nbsp; Agriculture{" "}
                  </Link>
                </li>
                <div className="ocean">
                  <div className="wave nav_wave" />
                  <div className="wave nav_wave" />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </li>

      <li>
  <Link to="/blogs" className="">
     Blogs
    </Link>
  </li>

      <li>
      <Link to="/contact-us" className="">
          Contact Us
        </Link>
      </li>

      
    </ul>
  </div>
  {/* <ul class="social-icon">
<li>
                          
                        
                        <Link class="" to="/https://www.facebook.com/brainstartech"><i class="fa fa-facebook-f"></i></Link>
                    
                         
                        <Link class="" to="/https://twitter.com/BrainStarTech"><i class="fa fa-twitter"></i></Link>
                    
                         
                        <Link class="" to="/https://www.instagram.com/brainstartechnologies"><i class="fa fa-instagram"></i></Link>
                    
                        
                        <Link to="/https://www.linkedin.com/company/brain-star-technologies/"><i><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg></i></Link>
                    
                        <Link to="/https://wa.me/+917986324471"><i><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg></i></Link>
  
                        
                      </li>
            </ul> */}


  <div className="menu-icon " onClick={toggleNav} >☰</div>


</nav>

<Link class="scroll-btn" to="#top">
        <div class="arrow-up"></div>
      </Link>


</>
  )
}
