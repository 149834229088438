import React, { useState } from 'react';

const EcommerceDevelopmentServices = () => {

  
    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
      
        {
            title: "Online shopping software",
            imageSrc: "/assets/images/slide1.jpg",
            content:
              "Brain Star Technologies creates and deploys personalized web and mobile online stores with advanced features like real-time order tracking, product filters, automation, and notifications. Our designs prioritize user-friendliness for easy navigation and quick adoption.",
          },
          {
            title: "Enterprise marketplaces",
            imageSrc: "/assets/images/slide2.jpg",
            content:
              "We create tailored B2C, B2B, and P2P marketplaces linking sellers and buyers. Our marketplaces boast strong features for admins, vendors, and customers, streamlining operations. Diverse monetization options like subscriptions, commissions, and freemium are available.",
          },
          {
            title: "NFT marketplaces",
            imageSrc: "/assets/images/slide3.jpg",
            content:
              "We create advanced NFT marketplaces with essential features for users to create, trade, and profit from non-fungible tokens. Our services encompass secure and transparent platform development, seamless UI/UX design, integration of NFTs into current eCommerce and gaming setups, and ongoing support and maintenance.",
          },
          {
            title: "CMS",
            imageSrc: "/assets/images/slide4.jpg",
            content:
              "We create tailored content management systems that optimize eCommerce operations, enhance user engagement, and automate sales and support tasks. Additionally, we adapt and configure platform-based CMSs to align with your business requirements.",
          },
          {
            title: "Inventory management systems",
            imageSrc: "/assets/images/slide5.jpg",
            content:
              "Brain Star Technologies develops complete warehouse data management systems that allow retailers, suppliers, and distributors to effectively monitor, allocate, and track their inventory. Our solutions automate data entry and provide tools for demand forecasting, optimizing inventory management. This results in reduced product shortages, faster service delivery, and higher customer satisfaction.",
          },
          {
            title: "Auction and bidding apps",
            imageSrc: "/assets/images/slide6.jpg",
            content:
              "We specialize in developing online auction systems for B2B, B2C, and C2C interactions. Our platforms offer smooth experiences for sellers and buyers, enriched with features like instant messaging, video calls, and versatile payment methods. Our expertise covers various auction formats, including both traditional and reverse auctions.",
          },
          {
            title: "Trade portals",
            imageSrc: "/assets/images/slide7.jpg",
            content:
              "Brain Star Technologies creates specialized trading portals that facilitate seamless and secure online interactions between global retailers, suppliers, manufacturers, and forwarders. These platforms enable users to place orders, negotiate, finalize deals, and conduct asset transactions with ease.",
          },
          {
            title: "Bartering apps",
            imageSrc: "/assets/images/slide8.jpg",
            content:
              "We create efficient web and mobile platforms for B2C and C2C trading. Our apps enable seamless exchanges of goods and services between individuals and businesses, while offering great performance, user-friendly interfaces, and diverse monetization options for revenue generation.",
          },
          {
            title: "POS systems",
            imageSrc: "/assets/images/slide9.jpg",
            content:
              "We assist POS system manufacturers by providing embedded software services. This includes cloud-based and on-premises POS software delivery, system upgrades, mPOS app development, and self-service terminal software design to enhance client flexibility and speed of service.",
          },
          {
            title: "PIM systems",
            imageSrc: "/assets/images/slide10.jpg",
            content:
              "Brain Star Technologies specializes in crafting personalized Product Information Management (PIM) systems. These systems enable centralized control over extensive product data such as marketing details, sales information, images, manufacturer specifics, etc. Our PIM solutions facilitate seamless data transfer to various platforms like online stores, marketplaces, point-of-sale outlets, and printed catalogs.",
          },
          
        ];

  return (
<>
<section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span> eCommerce development services </span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >
Brain Star Technologies offers eCommerce software development services to assist B2C, B2B, and B2G companies engaged in retail operations. With strong IT proficiency, we help enhance the shopping experience, streamline internal processes, devise business growth tactics, and effectively utilize digital data.

            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>


<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>

        
</>
  )
}

export default EcommerceDevelopmentServices