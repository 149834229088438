import React, { useState } from 'react';

const LogisticsTransportationSoftwareDevelopment = () => {

    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
      
        {
            title: "Warehouse management systems",
            imageSrc: "/assets/images/Warehouse.jpg",
            content:
              "Brain Star Technologies specializes in developing all-encompassing software for warehouse management. This technology optimizes the complete process of monitoring assets and handling logistics, starting from the initiation of purchase orders and concluding with streamlined deliveries. Our tailored solutions for warehouse management empower businesses to effectively oversee their inventory, vehicle fleet, shipping procedures, and workforce, all within a unified platform.",
          },
          {
            title: "Inventory management software",
            imageSrc: "/assets/images/inventory.jpg",
            content:
              "Effective goods storage plays a vital role within the broader framework of logistics and transportation. Our company specializes in creating diverse inventory management system (IMS) software, which plays a critical role in overseeing various tasks related to stock management. These tasks encompass receiving, storage, packaging, and the shipment of a wide array of physical goods.",
          },
          {
            title: "Transportation scheduling software",
            imageSrc: "/assets/images/Transportation.jpg",
            content:
              "We offer advanced software solutions for logistics that elevate our clients' businesses and simplify fleet operations. Our range of services includes warehouse monitoring applications, telematics based on GPS technology, route optimization tools, software for managing dispatch operations, and much more. No matter what you require, we have the perfect solution to meet your needs.",
          },
          {
            title: "Logistics management software",
            imageSrc: "/assets/images/Logistics.jpg",
            content:
              "Logistics management involves intricate procedures encompassing the gathering of merchandise, their safekeeping, efficient transportation, and vigilant monitoring. Through our logistics management solutions, clients gain the capability to trace the movement of products, all the way from storage facilities to the final destination at the customer's location.",
          },
          {
            title: "Transport monitoring software",
            imageSrc: "/assets/images/Transport.jpg",
            content:
              "Effective monitoring of goods' transportation plays a vital role in logistics, enabling businesses to strategically arrange and carry out the movement of physical products while also enhancing overall supply chain efficiency. Brain Star Technologies specializes in software solutions designed to enhance transparency and provide real-time insights into routine transportation activities.",
          },
          {
            title: "Truck fleet management software",
            imageSrc: "/assets/images/fleetmanage.jpg",
            content:
              "Brain Star Technologies offers robust solutions for managing truck fleets, granting clients valuable visibility into the utilization and operation of their transportation assets. Our software empowers businesses to efficiently monitor, track, and oversee their truck fleets like never before.",
          },
          {
            title: "Workflow management software",
            imageSrc: "/assets/images/workflow-software.jpg",
            content:
              "We develop comprehensive workflow solutions that immediately enhance corporate procedures and optimize activities. Our expertise lies in crafting streamlined workflow systems that encompass vital functionalities such as task validation, cross-functional teamwork, and the automation of business processes.",
          },
          {
            title: "Order processing",
            imageSrc: "/assets/images/Order_processing.jpg",
            content:
              "Brain Star Technologies offers order processing software to a wide range of industries, encompassing eCommerce and retail sectors. Our solutions equip enterprises with a robust tool to enhance the efficiency of their order processing, resulting in cost savings.",
          },
          {
            title: "Reporting and BI",
            imageSrc: "/assets/images/Reporting_BI.jpg",
            content:
              "Due to the crucial role of data in assessing the profitability of business activities, leveraging business intelligence can greatly aid in organizing its oversight. We have the capability to create a comprehensive range of solutions that support enterprises in gathering and transforming unorganized data into valuable insights.",
          },
          
        ];

  return (
<>

<section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Logistics &amp; Transportation software development</span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Brain Star Technologies utilizes advanced technologies and
              extensive development expertise to offer strong software solutions
              for logistics and transportation needs. Within this sector,
              companies encounter numerous challenges, and we are fully equipped
              to create a range of applications that aid in overseeing supply
              chains and operations, tracking delivery procedures, and
              minimizing expenses.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>



<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>

        
</>
  )
}

export default LogisticsTransportationSoftwareDevelopment