import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';



const WebsiteDevelopment = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);

  return (
<>

<>
  <section className="section home_banner website-developmentBanner">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-7 banner_text carrer_banner_text">
              <h1
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                <span>Stay Ahead of the Competition</span>
                <br /> Partner with a Trusted Web Development Company
              </h1>
              <h3
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Unlock the potential of your business with an impactful and
                professional website. Our experienced developers are equipped
                with problem-solving skills and a strong commitment to
                delivering top-notch web app development.
              </h3>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/images/website-development-banner-img.png"
                alt="Website Development Banner Image"
                className="img-responsive wow zoomIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "zoomIn"
                }}
              />
            </div>
          </div>
        </div>
        <div className="ocean web_app">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section app_development_section services-padding">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            We go beyond websites,
            <br />
            <span>We propel your business forward.</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
            An effective website is crucial for your marketing strategy, serving
            as the face of your app development company and the center of your
            online activities. It can either boost or hinder your business
            success, so choosing the right development partner is vital. At
            Brain Star Technologies, we excel in web and mobile app development,
            driving higher business conversions. We deliver captivating designs,
            responsive themes, and user-focused websites, backed by thorough
            research to meet modern business demands. Partner with us to propel
            your business growth with precision and commitment.
          </p>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section mt-0 w-100">
          <div className="col-md-6 common_tittle">
            <h2
              className="heading-mt wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.4s"
            >
              {" "}
              Web Development Services At <span>Brain Star Technologies</span>
            </h2>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/php-icon.svg" alt="php" />
                </span>
                <h3>PHP Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.7s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/laravel.png" alt="laravel" />
                </span>
                <h3>Laravel Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/node.png" alt="node" />
                </span>
                <h3>NodeJS Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.9s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/cake-php.png" alt="cakephp1" />
                </span>
                <h3>Cake PHP Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/django.png" alt="django" />
                </span>
                <h3>Django Web Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.1s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/angular.png" alt="angular" />
                </span>
                <h3>Angular Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.2s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/wordpress.png" alt="wordpress" />
                </span>
                <h3>WordPress Development</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section app_platform_section w-100 pt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Wondering Which <br />
              <span>Platform To Choose For</span> Your Website?
            </h2>
          </div>
          <div className="definition_column_wrapper w-100">
            <div className="col-md-7 definition_column_right">
              <h3 className="text-uppercase wow fadeInUp" data-aos-delay="0.4s">
                PHP Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                PHP, the powerhouse of web scripting languages, reigns supreme
                with its unparalleled functionalities. Tailored for skilled web
                app development, PHP has emerged as a dominant force in the web
                development arena. With its wide database support, PHP is the
                ultimate choice for those seeking simplicity, rapid development,
                and cost-effectiveness. Let's explore some of the remarkable
                advantages of PHP Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Unleashes Open Source Potential
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Extensibility at Its Finest
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Platform Freedom Guaranteed
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Seamless Integration with All Servers
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1s"
                >
                  Access to Robust Support
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Fortified with Top-notch Security
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/PHP-logo.png"
                alt="PHP Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5 definition_column_wrapper">
              <img
                src="/assets/images/Laravel-logo.png"
                alt="Laravel Development"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right definition_column_wrapper">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Laravel Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Leveraging the Laravel framework for custom software development
                has gained immense popularity. This framework simplifies tasks
                like authentication, sessions, caching, and routing, freeing
                developers from complex coding. With a clean and functional web
                development framework, web development companies can create
                powerful apps using an expressive and straightforward syntax.
                Let's explore the significant advantages of Laravel Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Seamlessly Integrated Mail Services
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Efficient Error and Exception Handling
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Automated Testing Simplified
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Flexible URL Routing Configuration
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Robust Security and Vulnerability Fixtures
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Multi-Lingual App Development Capabilities
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Node.js Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Node.js is an agile, adaptable, and versatile method to run
                code. It accelerates the creation of real-time applications. The
                Node Package Manager equips developers with various tools and
                modules to enhance their efficiency. It proves ideal for
                microservices, a favored approach for web app development.
                Explore the primary advantages of Node.js Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Seamless Scalability
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Optimal Performance
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Fullstack JavaScript Integration
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Extensive Support
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Highly Customizable
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Leveraging Caching Benefits
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/Node.js-logo.png"
                alt="Node.js Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5">
              <img
                src="/assets/images/cake-php-logo.png"
                alt="CakePHP Development"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                CakePHP Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                CakePHP, a powerful and user-friendly platform, empowers web and
                mobile app development companies to build feature-rich and
                specialized websites with ease. The developers efficiently
                create robust and budget-friendly websites using CakePHP's
                open-source capabilities, offering flexibility in web
                development. Let's explore the notable advantages of CakePHP
                Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Unleashing the Power of Open Source
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Seamless Implementation of CRUD Functionality
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  No Hassle of Pre-configuration
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Embracing the Model View Controller (MVC) Pattern
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Friendly License and Easy Extension with Components and
                  Plug-ins
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Leveraging Object Relational Mapping (ORM)
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Django Web Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Django, an efficient open-source framework, empowers developers
                to cut down web application development costs while catering to
                diverse business needs. With seamless integration of Python, it
                accelerates custom web app development and supports the MVC
                programming paradigm. Django's versatility is showcased by its
                compatibility with major operating systems and databases,
                providing an easy-to-extend and scalable solution. Not to
                mention, it boasts robust security features, ensuring the safety
                of your web applications. Let's explore the key advantages of
                Django Web Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Harnessing the power of Python
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Accelerating custom app development
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Embracing the MVC programming paradigm
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Versatility across operating systems and databases
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Easy extension and scalability
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Strengthened security features
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/django-logo.png"
                alt="Django Web Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-5">
              <img
                src="/assets/images/angular-js2.png"
                alt="Angular Development"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Angular Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Leveraging the Angular Framework empowers the creation of
                interactive, dynamic, and efficient single-page applications
                (SPAs). Its remarkable features encompass templating, two-way
                binding, modularization, RESTful API handling, dependency
                injection, and AJAX handling. With Angular, web development
                companies utilize HTML as the template language and easily
                extend its syntax to convey application components. Embracing
                Angular development brings an array of benefits, including:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Endorsed by Google
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Utilizes TypeScript Language
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Declarative UI
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Consistent Code Structure
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Simplified Unit Testing
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Simplified MVC Pattern
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix" />
          <div className="definition_column_wrapper w-100 mt-0">
            <div className="col-md-7 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                WordPress Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                WordPress Development stands out as a leading website-building
                solution worldwide. This free platform offers a wide range of
                customizable add-ons and plugins to cater to specific needs.
                It's a top choice for both web and mobile app development
                companies. Here are some key advantages of WordPress
                Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Highly Versatile for Evolving Needs
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Intuitive and User-Friendly
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Enhanced Functionality with Plugins
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Diverse Theme Options
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Improved Website Ranking
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  Integrated Support and Blogging Features
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                src="/assets/images/wordpress-logo.png"
                alt="WordPress Development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section mobile_process w-100 bg-light-blue">
          <div className="container">
            <div className="col-md-12 common_tittle text-center">
              <h2
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Our Web <span>Development Process</span>{" "}
              </h2>
            </div>
            <div className="clearfix" />
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/gather-information.png"
                    alt="Information Gathering"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Information Gathering</h3>
                <p>
                  Begin by collecting comprehensive details about the company,
                  its objectives, target audience, and business requirements.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Planning</h3>
                <p>
                  Develop a well-structured blueprint based on the gathered
                  information, outlining the site's architecture, technology
                  choices, user interface, and navigation system.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/business-consulting.png"
                    alt="Planning"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/build-and-lead.png"
                    alt="Design"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Design</h3>
                <p>
                  In the design phase, we craft visually stunning websites that
                  reflect your brand identity and captivate your target
                  audience. Our focus is on delivering a captivating user
                  experience by incorporating your logo, colors, and elements
                  tailored to your audience's preferences.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Development</h3>
                <p>
                  Transform design prototypes into a fully functional website
                  using valid HTML/CSS code that adheres to web standards and
                  ensures seamless accessibility.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/blend-in-design-and-development.png"
                    alt="Development"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.9s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/testing.png"
                    alt="Testing"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Testing</h3>
                <p>
                  Conduct rigorous testing to verify the website's
                  functionality, including forms and scripts, and ensure
                  compatibility across different browsers for optimal
                  performance.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Maintenance</h3>
                <p>
                  The development process doesn't end with the website going
                  live. Regular updates, fresh content, and plugin installations
                  are essential to keep the site engaging and relevant to
                  visitors.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/support-and-maintenance.png"
                    alt="Maintenance"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="clearfix" />
      </div>
    </div>
  </section>



  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 contact_section_left">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Hire Web Developers
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
           Collaborate with our skilled team of web developers to create cutting-edge websites that expand and evolve.


          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>




</>


</>  

)
}

export default WebsiteDevelopment