import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const BlogDetails = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
  
    useEffect(() => {
      // Fetch the blog post data using the slug
      fetch(`https://admin.brainstartechnologies.com/api/blogs/${slug}`)
        .then(response => response.json())
        .then(data => {
          setBlog(data); // Set the retrieved blog post data
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }, [slug]); // Fetch data whenever the slug changes
  
    if (!blog) {
      return <div>Loading...</div>;
    }
  
    return (

        <>

<Helmet>
<title> {blog.blogTitle} </title> 
<meta name="description" content="{blog.metaDes}"/>
<meta name="keywords" content="{blog.metaKey}"/>

      </Helmet>
        <div className="breadcrumb-area">
  <div className="container">
    <div className="title">
      <h1>Blog Details</h1>
    </div>
  </div>
</div>



      <div className="blog-details">
  <div className="container">
    <div className="row">
      <div className="col-lg-8">
        <main id="primary" className="site-main">
          <article
            id="post-307"
            className="post-307 post type-post status-publish format-standard has-post-thumbnail hentry category-web-design tag-business tag-consulting tag-corporate"
          >
            <div
              className="blog-thumb aos-init aos-animate"
              data-aos="fade-down"
              style={{ backgroundImage: `url(https://admin.brainstartechnologies.com/${blog.blogImg})`}}
              />
            <div className="blog-content">
              <div className="entry-header">
                <h2 className="entry-title">
                {blog.blogTitle}
                </h2>
              </div>
              <div className="post-info">
                <div className="author-img">
                  <a href="/">
                    <img
                      alt="author"
                      src="/assets/images/sumit-sir.jpg"
                      className="avatar avatar-55 photo media-object img-circle"
                      height={55}
                      width={55}
                      loading="lazy"
                      decoding="async"
                    />{" "}
                  </a>
                </div>
                <div className="post-meta">
                  <div className="author-name">
                    <h5>Sumit Kumar</h5>
                  </div>
                  <div className="entry-meta">
                  
                    
                    <div className="meta-date">
                      <i className="fa fa-calendar" />
                      <span>July 26, 2023</span>
                    </div>
                  </div>
                </div>
              </div>

         

              <div className="entry-content">
               
             
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />

              </div>
            </div>
          </article>
        
         
        </main>
      </div>
      <div className="col-lg-4 sticky-top">
        <aside id="secondary" className="widget-area">
          <section id="block-1" className="widget widget_block">
            <div className="block-group is-layout-flow">
              <div className="block-group__inner-container">
                <h2 className="block-heading">Recent Posts</h2>
                <ul className="wp-block-latest-posts__list wp-block-latest-posts">
                  <li className="hvr-bounce-in">
                    <a
                      className="wp-block-latest-posts__post-title"
                      href="blog-page-2.html"
                    >
                      Easy Innovation Using Best Software Company
                    </a>
                  </li>
                  <li className="hvr-bounce-in">
                    <a
                      className="wp-block-latest-posts__post-title"
                      href="blog-page-3.html"
                    >
                      The Power of Artificial Intelligence in Transforming
                      Modern Business
                    </a>
                  </li>
                  <li className="hvr-bounce-in">
                    <a
                      className="wp-block-latest-posts__post-title"
                      href="blog-page-4.html"
                    >
                      The Growing Significance of Cybersecurity in Modern
                      Enterprises
                    </a>
                  </li>
                  <li className="hvr-bounce-in">
                    <a
                      className="wp-block-latest-posts__post-title"
                      href="blog-page-1.html"
                    >
                      The Evolving Landscape of Cloud Computing: Navigating the
                      Future
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </aside>
        {/* #secondary */}
      </div>
    </div>
  </div>
</div>



</>
    
    );
  };

export default BlogDetails