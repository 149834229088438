import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Technologies = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);

      
  return (
   
    <>
  <section className="section home_banner menu_bgBanner">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-7 banner_text carrer_banner_text">
              <h1
                className="wow fadeInUp"
                data-aos="fade-right"
                data-aos-delay={300}
              >
                Technologies
              </h1>
              <p
                className="wow fadeInUp"
                data-aos="fade-down"
                data-aos-delay={300}
              >
                Unlock Business Technologies with Brain Star Technologies!
                Maximize Productivity, Dominate the Digital Marketplace.
                Innovative Solutions for Diverse Domains. Join Us and Explore!
              </p>
            </div>
            <div
              className="col-md-5 text-center wow fadeInRight"
              data-aos="fade-left"
              data-aos-delay={300}
            >
              <img
                src="/assets/images/technologies.png"
                alt="Technologies Banner Image"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
        <div className="ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section technologies_section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 common_tittle frontend-tech">
          <h2 className="heading-mt wow fadeInUp" data-aos="fade-up">
            FrontEnd <span>Technologies</span>
          </h2>
        </div>
        <div className="col-md-12">
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/js.png" alt="javascript-icon" />
                </span>
                <h3>JavaScript</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/js.png" alt="typescript-icon" />
                </span>
                <h3>TypeScript</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/html.svg" alt="html-icon" />
                </span>
                <h3>HTML5</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/css.svg" alt="css-icon" />
                </span>
                <h3>CSS3</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/AngularJS-Shield.png" alt="angular-icon" />
                </span>
                <h3>Angular</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/reactjs.png" alt="reactjs-icon" />
                </span>
                <h3>React JS</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/vuejs.png" alt="vuejs-icon" />
                </span>
                <h3>Vue.js</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section technologies_section">
    <div className="container-fluid">
      <div className="row backend_technologies">
        <div className="col-md-12 col-sm-12 col-xs-12 common_tittle backend-tech">
          <h2 className="heading-mt wow fadeInUp" data-aos="fade-up">
            Backend <span>Technologies</span>
          </h2>
        </div>
        <div className="col-md-12">
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/ruby.svg" alt="ruby-icon" />
                </span>
                <h3>Ruby</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/nodejs.svg" alt="nodejs-icon" />
                </span>
                <h3>Node.js</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/elixir.png" alt="elixir-icon" />
                </span>
                <h3>Elixir</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/rails.png" alt="ruby-on-rails-icon" />
                </span>
                <h3>Ruby on Rails</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/phoenix.png" alt="phoenix-icon" />
                </span>
                <h3>Phoenix</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/go.png" alt="go-icon" />
                </span>
                <h3>Go</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/php-framework.png"
                    alt="php-framework-icon"
                  />
                </span>
                <h3>PHP frameworks</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/Laravel-technology.png" alt="laravel-icon" />
                </span>
                <h3>Laravel</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/codeigniter.png" alt="codeigniter-icon" />
                </span>
                <h3>Codeigniter</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/Yii.png" alt="yii-icon" />
                </span>
                <h3>YII 2</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/cake-php.png" alt="cake-php-icon" />
                </span>
                <h3>Cake-php</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/Python.png" alt="python-icon" />
                </span>
                <h3>Python</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/django.png" alt="django-icon" />
                </span>
                <h3>Django</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/flask.png" alt="flask-icon" />
                </span>
                <h3>Flask</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section technologies_section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 common_tittle frontend-tech">
          <h2 className="heading-mt wow fadeInUp" data-aos="fade-up">
            Mobile <span>App</span>
          </h2>
        </div>
        <div className="col-md-12">
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/android-app.png" alt="android-app-icon" />
                </span>
                <h3>Android</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/Java.png" alt="java-icon" />
                </span>
                <h3>Java </h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/Kotlin.png" alt="kotlin-icon" />
                </span>
                <h3>Kotlin </h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/ios.png" alt="ios-icon" />
                </span>
                <h3>iOS</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/swift_hire.png" alt="swift-icon" />
                </span>
                <h3>Swift</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 text-center">
            <div className="all_services_wrapper wow zoomIn" data-aos="zoom-in">
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/Objective C.png" alt="objective-c-icon" />
                </span>
                <h3>Objective C</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>


  )
}

export default Technologies