
  import React, { useState } from 'react';

    const BankingSoftwareDevelopmentServices = () => {
    
      
        const [activeTab, setActiveTab] = useState(0);
    
        const tabMouseOverHandler = (index) => {
          // Set the active tab when a tab is hovered over
          setActiveTab(index);
        };
        const tabData = [
          {
              title: "Internet banking solutions",
              imageSrc: "/assets/images/Frame-4716.png",
              content: "Brain Star Technologies facilitates customers in harnessing the complete benefits of online financial transactions. This includes the ability to buy products, settle payments, and move funds between accounts through the Internet.",
          },
          {
              title: "Mobile banking solutions",
              imageSrc: "/assets/images/Frame-4716-1.png",
              content: "Through the development of our mobile banking app, our customers can effortlessly take control of their accounts and conduct transactions, all without the necessity of physically visiting a bank branch.",
          },
          {
              title: "ATM management solutions",
              imageSrc: "/assets/images/Frame-4716-2.png",
              content: "Our proficient developers craft creative ATM solutions for overseeing cash movement, examining transactions, deterring potential fraud, and upholding round-the-clock accessibility for self-service machines.",
          },
          {
              title: "EMV software solutions",
              imageSrc: "/assets/images/Frame-4716-3.png",
              content: "We utilize the most effective EMV techniques to provide secure and compatible payment solutions across peer-to-peer and distant settings using smart chip-equipped plastic cards.",
          },
          {
              title: "POS software solutions",
              imageSrc: "/assets/images/Frame-4716-4.png",
              content: "Brain Star Technologies develops Point of Sale (POS) software for the banking sector, guaranteeing the safety of monetary transactions while also offering meaningful analyses of financial systems, empowering informed choices based on data.",
          },
          {
              title: "Investment management solutions",
              imageSrc: "/assets/images/Frame-4716-5.png",
              content: "We provide robust solutions designed to enhance the profitability of investments. Our offerings encompass effective instruments for overseeing and fine-tuning investment portfolios, ensuring adherence to auditing standards, and closely monitoring activities involving stocks, futures, options, and bonds.",
          },
      ];
    
    
      return (
    <>
    <section
      className="section home_banner contact_banner"
      style={{ marginBottom: "3rem" }}
    >
      <div className="header_banner w-100">
        <div className="banner_overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
                <h1
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <span> Banking software development services
 </span>
                </h1>
                <p
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-down"
                  data-aos-delay={300}
                >
    
    Brain Star Technologies supports financial organizations in adopting the digital transformation trend while ensuring the security and effectiveness of their financial transactions. We empower businesses to leverage advanced technologies and integrate continuous improvements into their services. Our skilled developers consistently provide solutions that stand out in operational efficiency and user engagement.

</p>
              </div>
            </div>
          </div>
          <div className="ocean contact_ocean">
            <div className="wave" />
            <div className="wave" />
          </div>
        </div>
      </div>
    </section>
    
    
    <div className="services_container">
          <div className="services_lt">
            <ul id="scroll_list" className="scroll_list">
              {tabData.map((tab, index) => (
                <li
                  key={index}
                  className={`tab-link ${activeTab === index ? 'active' : ''}`}
                  onMouseOver={() => tabMouseOverHandler(index)}
                >
                  <span data-tab={index + 1}>
                    <span className="scroll_list_num">{index + 1}.</span>
                    {tab.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="services_rt">
            <div className="sevices_rt_inner">
              {tabData.map((tab, index) => (
                <div
                  key={index}
                  id={index + 1}
                  className={`tab-content ${activeTab === index ? 'current' : ''}`}
                >
                  <figure>
                    <img src={tab.imageSrc} alt="img" />
                  </figure>
                  <div className="serv_card_head">
                    <h1>{tab.title}</h1>
                  </div>
                  <p className="card_para">{tab.content}</p>
                </div>
              ))}
            </div>
            </div> </div>
    
            
    </>

  )
}

export default BankingSoftwareDevelopmentServices