import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';

const ManagedServices = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);


  return (
 
    <>
  <section className="section home_banner crossPlatform">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-9 banner_text carrer_banner_text">
              <h1
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                <span>Elevate Your Business</span>
                <br /> Comprehensive Managed Solutions for Success!
              </h1>
              <h3
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Experience Enhanced Efficiency with Our Managed Services
              </h3>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/images/managed-services-banner.png"
                alt="Managed Services Banner Image"
                className="img-responsive wow zoomIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "zoomIn"
                }}
              />
            </div>
          </div>
        </div>
        <div className="ocean contact_ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section app_development_section services-padding">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            TRANSFORMATIVE<span> MANAGED SERVICES</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
            Experience unparalleled growth and efficiency with our Managed
            Services. We redefine the way businesses operate by providing expert
            management and optimization solutions. At Brain Star Technologies,
            we excel in offering seamless integration of technology and
            expertise, empowering your organization to focus on core objectives.
            From implementation to support, we deliver tailor-made solutions
            that drive your business forward into the realm of efficient and
            secure Managed Services.
          </p>
        </div>
        <div className="clearfix" />
        <div className="culture_section_column w-100 pb-100">
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/solutions.png"
                  alt="it solutions"
                  className="img-responsive"
                />
              </div>
              <h4>Comprehensive IT Solutions</h4>
              <p>
                Unlock the full potential of Managed Services with our holistic
                IT solutions. Streamline your operations and scale your business
                efficiently with our expert guidance.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.6s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/monitoring.svg"
                  alt="monitoring and support"
                  className="img-responsive"
                />
              </div>
              <h4>Proactive Monitoring and Support</h4>
              <p>
                Stay ahead of potential issues with our proactive monitoring and
                support services. Our Managed Services ensure smooth operations
                and minimal downtime for your business.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/cost-effective.svg"
                  alt="cost effective"
                  className="img-responsive"
                />
              </div>
              <h4>Cost-Effective Optimization</h4>
              <p>
                Optimize your business processes and reduce operational costs
                with our Managed Services. We tailor solutions that fit your
                requirements, ensuring maximum efficiency.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.8s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/data-security.svg"
                  alt="data security"
                  className="img-responsive"
                />
              </div>
              <h4>Enhanced Data Security</h4>
              <p>
                Security is our priority. We implement robust measures to
                protect your sensitive data, ensuring the highest level of data
                security in your Managed Services environment.
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            Advantages of<span> Managed Services</span>
          </h2>
        </div>
        <div className="culture_section_column w-100">
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.5s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/solutions.png"
                  alt="it solutions"
                  className="img-responsive"
                />
              </div>
              <h4>Efficient IT Solutions</h4>
              <p>
                Benefit from efficient IT solutions tailored to meet your
                specific business needs, ensuring smooth operations and
                optimized performance.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/maintenance.svg"
                  alt="maintenance"
                  className="img-responsive"
                />
              </div>
              <h4>Proactive Maintenance</h4>
              <p>
                Stay ahead with proactive maintenance and timely updates,
                minimizing downtime and enhancing the reliability of your IT
                infrastructure.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.7s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/robust-data.svg"
                  alt="robust data"
                  className="img-responsive"
                />
              </div>
              <h4>Robust Data Security</h4>
              <p>
                Ensure robust data security measures with managed services,
                safeguarding your critical information and protecting your
                business from potential threats.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/expert.svg"
                  alt="expert support"
                  className="img-responsive"
                />
              </div>
              <h4>24/7 Expert Support</h4>
              <p>
                Access round-the-clock expert support to address any IT issues,
                ensuring a seamless and reliable IT environment for your
                business operations.
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section w-100 mt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              {" "}
              Services <span>We Offer</span>
            </h2>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.5s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/quality.svg"
                    alt="quality assurance"
                    className="img-responsive"
                  />
                </span>
                <h3>Quality Assurance</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.6s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/resources.svg"
                    alt="hire resources"
                    className="img-responsive"
                  />
                </span>
                <h3>Hire Resources</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.7s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/maintenance.svg"
                    alt="maintenance and support"
                    className="img-responsive"
                  />
                </span>
                <h3> Maintenance &amp; Support</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section app_platform_section w-100 mt-0 bg-light-blue">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Expert Managed Services
              <br />
              <span>Ensure Smooth Operations with Our</span> Quality Assurance,
              Resource Hiring, Maintenance, and Support
            </h2>
          </div>
          <div className="container">
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/quality-assurance.svg"
                    alt="Quality Assurance"
                    className="img-responsive"
                  />
                  &gt;
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Quality Assurance</h3>
                <p>
                  Ensure impeccable quality and reliability of your products and
                  services with our expert quality assurance solutions.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Hire Resources</h3>
                <p>
                  Expand your capabilities and enhance your team with our
                  top-notch resources tailored to your specific requirements.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/hire.png"
                    alt="Hire Resources"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/maintenance.jpeg"
                    alt="Maintenance and Support"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Maintenance and Support</h3>
                <p>
                  Ensure continuous smooth functioning and user satisfaction
                  with our reliable maintenance and support services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  </section>

  
  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-9 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Hire for Managed Services
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
          Elevate operational efficiency with our comprehensive managed services tailored to your business needs.


          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>




</>


  )
}

export default ManagedServices