import React, { useRef, useEffect } from 'react';

const About = () => {
    const videoRef = useRef(null);

    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    useEffect(() => {
      const teamWrapper = document.querySelector(".team-wrapper");
      const carousel = document.querySelector(".carousel");
      const arrowBtns = document.querySelectorAll(".team-wrapper i");
      const firstCardWidth = carousel.querySelector(".card").offsetWidth;
      const carouselChildrens = [...carousel.children];
  
      let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);
  
      carouselChildrens.slice(-cardPerView).reverse().forEach(card => {
        carousel.insertAdjacentHTML("afterBegin", card.outerHTML);
      });
  
      carouselChildrens.slice(0, cardPerView).forEach(card => {
        carousel.insertAdjacentHTML("beforeend", card.outerHTML);
      });
  
      let isDragging = false,
        startX,
        startScrollLeft,
        timeoutId;
  
      arrowBtns.forEach(btn => {
        btn.addEventListener("click", () => {
          carousel.scrollLeft += btn.id === "left" ? -firstCardWidth : firstCardWidth;
        });
      });
  
      const dragStart = (e) => {
        isDragging = true;
        carousel.classList.add("dragging");
        startX = e.pageX;
        startScrollLeft = carousel.scrollLeft;
      };
  
      const dragging = (e) => {
        if (!isDragging) return;
        carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
      };
  
      const dragStop = () => {
        isDragging = false;
        carousel.classList.remove("dragging");
      };
  
      const autoPlay = () => {
        if (window.innerWidth < 800) return;
        timeoutId = setTimeout(() => (carousel.scrollLeft += firstCardWidth), 2500);
      };
      autoPlay();
  
      const infiniteScroll = () => {
        if (carousel.scrollLeft === 0) {
          carousel.classList.add("no-transition");
          carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
          carousel.classList.remove("no-transition");
        } else if (
          Math.ceil(carousel.scrollLeft) ===
          carousel.scrollWidth - carousel.offsetWidth
        ) {
          carousel.classList.add("no-transition");
          carousel.scrollLeft = carousel.offsetWidth;
          carousel.classList.remove("no-transition");
        }
        clearTimeout(timeoutId);
        if (!teamWrapper.matches(":hover")) autoPlay();
      };
      carousel.addEventListener("mousedown", dragStart);
      carousel.addEventListener("mousemove", dragging);
      document.addEventListener("mouseup", dragStop);
      carousel.addEventListener("scroll", infiniteScroll);
      carousel.addEventListener("mouseenter", () => clearTimeout(timeoutId));
      carousel.addEventListener("mouseleave", autoPlay);
    }, []);

    
  return (
    <>
    <section className="section">
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
      <video id="background-video" autoPlay loop muted playsInline onClick={playVideo}>
          <source src="/assets/images/about-us.webm" type="video/mp4" />
        </video>
        <div className="image-overlay" />
        <div className="row banner-content">
          <div className="col-md-6 banner_text carrer_banner_text hvr-grow-shadow">
            <h1 className="wow fadeInUp aos-init" data-aos="fade-right">
              <span>Your Vision, Our Mission.</span>
              <br /> Let’s Grow Mutually!
            </h1>
            <p className="wow fadeInUp aos-init" data-aos="fade-right">
              Brain Star Technologies: Your Co-Innovation Partner for Success!
              Seamlessly overcome challenges with our dedicated team.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="section we-help who-we-are-section">
  <div className="container-fluid">
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="row">
      <div
        className="col-md-6 service-box-two aos-init aos-animate"
        data-aos="zoom-in"
        data-wow-delay="0.2s"
        style={{
          animation: "pulse 2s infinite",
          marginTop: 70,
          backgroundImage: "url( /assets/images/bg-1-1.png )"
        }}
      >
        {/*<a class="popup-youtube " href="#">*/}
        <img
          src="/assets/images/girl.png"
          alt="Who we are"
          className="img-responsive"
        />
        {/* <img src="/assets/images/play.png" alt="Play Button" class="img-responsive play-btn"> */}
        {/* </a>*/}
      </div>
      <div className="col-md-6 common_tittle" style={{ paddingLeft: 40 }}>
        <h2
          data-aos="fade-up"
          data-aos-delay="0.3s"
          className="aos-init aos-animate"
        >
          Introducing #BrainStars: <br />
          <span> Unleashing Infinite Possibilities!</span>
        </h2>
        <p
          data-aos="fade-up"
          data-aos-delay="0.4s"
          className="aos-init aos-animate"
        >
          In a world where challenges may seem boundless, we envision limitless
          potential. Meet our exceptional team of creative thinkers, visionary
          designers, and ingenious developers – united to craft digital
          solutions tailored for your growth.
        </p>
        <ul>
          <li
            data-aos="fade-up"
            data-aos-delay="0.5s"
            className="aos-init aos-animate"
          >
            🚀 &nbsp;Expertise across diverse technologies
          </li>
          <li data-aos="fade-up" data-aos-delay="0.6s" className="aos-init">
            🔧 &nbsp;Agile, end-to-end process for seamless execution
          </li>
          <li data-aos="fade-up" data-aos-delay="0.7s" className="aos-init">
            💡 &nbsp;Start-up mindset with seasoned execution
          </li>
          <li data-aos="fade-up" data-aos-delay="0.8s" className="aos-init">
            🔄 &nbsp;Continuous delivery for ongoing improvements
          </li>
          <li data-aos="fade-up" data-aos-delay="0.9s" className="aos-init">
            🤝 &nbsp;A co-creative journey with you
          </li>
          <li data-aos="fade-up" data-aos-delay="1s" className="aos-init">
            💥 &nbsp;Empowering game-changing innovation
          </li>
          <li data-aos="fade-up" data-aos-delay="1.1s" className="aos-init">
            🎨 &nbsp;Flawless UX &amp; UI for exceptional product design &amp;
            development
          </li>
        </ul>
      </div>
    </div>
    <div className="cube" />
  </div>
</section>

<section className="founder-section">
  <div className="founder-container container-fluid">
    <div className="founder-column">
      <div className="founder-widget-wrap">
        <div className="founder-element">
          <div className="founder-widget-container">
            <div className="founder-section-title-wrapper">
              <div className="container">
                <div className="founder-section-title-content">
                  <h2 className="founder-section-title">Our Founders</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="founder-element">
          <div className="founder-widget-container">
            <div className="service-wrapper_two">
              <div className="container">
                <div className="row">
                  <div
                    id="service-box"
                    className="col-xl-4 col-lg-4 col-md-6 col-12"
                  >
                    <div
                      className="service-box-two moving-border"
                      style={{ backgroundImage: "url( /assets/images/bg-1-1.png )" }}
                    >
                      <div className="service-two-content">
                        <div className="cardFounder">
                          <div className="image__container">
                            <img src="/assets/images/sumit-sir.jpg" alt="" />
                          </div>
                          <div className="founder_content">
                            <div className="details">
                              <h2>
                                Sumit Kumar <br />
                                <span>CEO</span>{" "}
                              </h2>
                              <div className="data" />
                              <div className="actions__button">
                                <p>
                                Mr. Sumit Kumar is the Chief Executive Officer of the company. He holds a master degree in Computer Applications and comes with deep knowledge of software development solutions that includes Enterprise and SaaS Applications, Website Design and Development, Product Engineering, Content Management System, Customer Relationship Management, Travel Portal and E-commerce solutions. He started the organization back in 2016 and has been amusing the industry since then. His unmatched strengths are understanding clients’ needs and improving business ROI as a whole. For 5 years as a CEO, his performance has been exceptional. His management style is extremely accessible and he is a very hands-on administrator. He is fearless in his business approach and his instincts are impeccable. Mr. Kumar has turned the little company into an industry powerhouse over the years and he managed to do it while still making friends in the industry.


                                </p>
                                <button>
                                  <a href="https://www.linkedin.com/in/sumitkumar2610/">
                                    {" "}
                                    Contact
                                  </a>{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="service-box"
                    className="col-xl-4 col-lg-4 col-md-6 col-12"
                  >
                    <div
                      className="service-box-two moving-border"
                      style={{ backgroundImage: "url( /assets/images/bg-1-1.png )" }}
                    >
                      <div className="service-two-content">
                        <div className="cardFounder">
                          <div className="image__container">
                            <img src="/assets/images/mohit.jpg" alt="" />
                          </div>
                          <div className="founder_content">
                            <div className="details">
                              <h2>
                                MOHIT KUMAR <br />
                                <span>CEO</span>{" "}
                              </h2>
                              <div className="data" />
                              <div className="actions__button">
                                <p>
                                Mr. Mohit Kumar, the CFO, holds an LLB degree, excels in cash flow tracking, financial planning, and analysis. He is also responsible for the planning, implementation, managing, and running of all the finance activities of a company, including business planning, budgeting, forecasting, and negotiations. Also, he does internal and external financial reporting, stewardship of a company’s assets, and ownership of cash management. He started the organization back in 2016 with Mr. Sumit Kumar and has been an asset to the organization since then. He is a motivated person with a high degree of professionalism, confidentiality, and integrity. He’s worked really hard over the years and progressed steadily, revealing his passion and enthusiasm for his position at the organization. These 5 years as a CFO, his performance has been commendable. A dedicated professional, he willingly takes on tasks and invests substantial hours.


                                </p>
                                <button>
                                  <a href="https://www.linkedin.com/in/mohit-thakur-b32a5b51/">
                                    {" "}
                                    Contact
                                  </a>{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="service-box"
                    className="col-xl-4 col-lg-4 col-md-6 col-12"
                  >
                    <div
                      className="service-box-two  moving-border"
                      style={{ backgroundImage: "url( /assets/images/bg-1-1.png )" }}
                    >
                      <div className="service-two-content">
                        <div className="cardFounder">
                          <div className="image__container">
                            <img src="/assets/images/kelley.jpeg" alt="" />
                          </div>
                          <div className="founder_content">
                            <div className="details">
                              <h2>
                                KELLEY PHELAN <br />
                                <span>CEO</span>{" "}
                              </h2>
                              <div className="data" />
                              <div className="actions__button">
                                <p>
                                Meet Kelley Phelan, a trailblazer in the tech realm and the dynamic partner at the helm. Hailing from Fashion Careers College in San Diego, CA, Kelley boasts a remarkable scholastic journey – an honor student with perfect attendance, setting the tone for her unwavering dedication. With a rich history, Kelley's expertise shines as a proven leader in software product development. She has steered high-performance teams, orchestrating triumphs in both start-ups and Fortune 500 giants. Her prowess lies in sculpting visions, streamlining processes, nurturing talents, and harnessing technology's power to craft software products that stand out. A maven at blending creativity and strategy, Kelley not only crafts cutting-edge software but also shapes unparalleled customer experiences. She crafts these experiences while staying in perfect harmony with overarching business objectives.


                                </p>
                                <button>
                                  {" "}
                                  <a href="https://www.linkedin.com/in/kelley-phelan-268083234/">
                                    Contact
                                  </a>{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section className="teams-section">
  <div className="teams-section-1">
    <h1>Our Team Members</h1>
    <p>
      Collaborating dreamers, turning ideas into reality. Together, we weave
      innovation through unity, creating a tapestry of success.
    </p>
  </div>
  <div className="team-wrapper">
    <i id="left" className="fa fa-angle-left" />
    <ul className="carousel">
      <li className="card">
        <div className="img">
          <img src="/assets/images/rajat.jpg" alt="img" draggable="false" />
        </div>
        <h2>Rajat</h2>
        <span>Business Development</span>
      </li>
      <li className="card">
        <div className="img">
          <img src="/assets/images/anshul.jpg" alt="img" draggable="false" />
        </div>
        <h2>Anshul</h2>
        <span>Business Development</span>
      </li>
      <li className="card">
        <div className="img">
          <img src="/assets/images/arvind.jpg" alt="img" draggable="false" />
        </div>
        <h2>Arvind</h2>
        <span>Web Designer & Developer</span>
      </li>
      <li className="card">
        <div className="img">
          <img src="/assets/images/ck.jpg" alt="img" draggable="false" />
        </div>
        <h2>Chandrakanta</h2>
        <span>Software Developer</span>
      </li>
      <li className="card">
        <div className="img">
          <img src="/assets/images/komal.jpg" alt="img" draggable="false" />
        </div>
        <h2>Komal</h2>
        <span>Project Manager</span>
      </li>
      <li className="card">
        <div className="img">
          <img src="/assets/images/anjali.jpg" alt="img" draggable="false" />
        </div>
        <h2>Anjali</h2>
        <span>Business Development</span>
      </li>
    </ul>
    <i id="right" className="fa fa-angle-right" />
  </div>
</section>



<section className="get_started_section">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12 common_tittle text-center get-started-section">
        <h2
          className="wow fadeInUp aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          Ready to Bring Your Vision to Life? <span>Let's Get Started!</span>
        </h2>
        <a
          href="contact-us.html"
          className="btn btn-lets-talk gradient-btn hvr-bounce-in aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration={1000}
          data-aos-delay={300}
        >
          Let's Talk
        </a>
      </div>
    </div>
  </div>
</section>

</>
  )
}

export default About