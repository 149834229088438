import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';


const CrossPlatformAppDevelopment = () => {

    
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-out-back',
      once: true,
    });
  }, []);

  return (

<>
  <section className="section home_banner crossPlatform">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-7 banner_text carrer_banner_text">
              <h1
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                <span>Conquer Global Markets</span>
                <br /> Infinite App Solutions, Embrace Possibilities!
              </h1>
              <h3
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Reach More Customers with Our Versatile Mobile Apps
              </h3>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/images/cross-platform-app-development-banner-img.png"
                alt="Cross Platform App Development Banner Image"
                className="img-responsive wow zoomIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "zoomIn"
                }}
              />
            </div>
          </div>
        </div>
        <div className="ocean contact_ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section app_development_section services-padding">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            Cross-Platform Apps:
            <br />
            <span> Your Business Solution</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
            Empower Your Business with Cross-Platform Apps Cost-effective.
            Efficient. Expanded Reach. Unlock new opportunities with
            cross-platform mobile development. Reach diverse audiences, optimize
            costs, and elevate performance without compromising quality. Our
            expert team excels in React Native and Flutter App Development,
            providing unbeatable features and seamless user experiences. Elevate
            your business today!
          </p>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section w-100 mt-0">
          <div className="col-md-6 common_tittle">
            <h2
              className="heading-mt wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.4s"
            >
              {" "}
              Cross-Platform App Development Services
              <br />
              <span>At Brain Star Technologies</span>
            </h2>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/React.png" alt="Icon" />
                </span>
                <h3>React Native App Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.7s"
            >
              <div className="all_services_inner">
                <span>
                  <img src="/assets/images/flutter1.png" alt="flutter" />
                </span>
                <h3>Flutter App Development</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section app_platform_section w-100 pt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Wondering Which
              <span>
                {" "}
                Platform
                <br />{" "}
              </span>
              To Choose?
            </h2>
          </div>
          <div className="definition_column_wrapper w-100">
            <div className="col-md-6 definition_column_right">
              <h3 className="text-uppercase wow fadeInUp" data-aos-delay="0.4s">
                React Native App Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                One Codebase, Dual Platforms: Our React Native framework
                eliminates the need for separate iOS and Android apps. With a
                single codebase, our expert developers create stunning apps
                without compromising on UX/UI. Enjoy speed, efficiency, and
                consistency across both platforms. Key benefits of React Native
                App Development:
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Reusable Code &amp; Pre-Built Components
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Simplified UI
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Enhanced efficiency
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Reduced debugging Hassles
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Stable App with Modular architecture
                </li>
              </ul>
            </div>
            <div className="col-md-6 text-center">
              <img
                src="/assets/images/react-native-app-development.jpg"
                alt="react-native-app-development"
                className="img-responsive mx-auto wow fadeInRight"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInRight"
                }}
              />
            </div>
          </div>
          <div className="definition_column_wrapper w-100">
            <div className="col-md-6 text-center">
              <img
                src="/assets/images/flutter-guide.png"
                alt="react-native-app-development"
                className="img-responsive mx-auto wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft"
                }}
              />
            </div>
            <div className="col-md-6 definition_column_right">
              <h3
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Flutter App Development
              </h3>
              <p
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Accelerate your cross-platform app development with Flutter,
                Google's top-notch mobile app SDK. Enjoy rapid coding, faster
                time-to-market, and customizable, animated UI. Simplify
                platform-specific logic with Flutter's advanced rendering
                engine. Upgrade your app development with Flutter today!
              </p>
              <ul className="w-100">
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  Faster Code Development
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  Quicker Time-to-Market
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  Customizable, Animated UI
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  Easy Platform-Specific Logic Implementation
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.0s"
                >
                  Proprietary Rendering Engine
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section mobile_process w-100 bg-light-blue">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Our Cross-Platform App<span> Development Process</span>{" "}
            </h2>
          </div>
          <div className="clearfix" />
          <div className="container">
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/consultation2.png"
                    alt="Consultation"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Consultation</h3>
                <p>
                  Our expert developers understand your requirements and
                  recommend the best framework for your Cross-platform app
                  development.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Interface Design</h3>
                <p>
                  We present a wireframe with all the features you need to bring
                  your app idea to life.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/flow-design.png"
                    alt="Interface Wireframe"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/designing.png"
                    alt="UI/UX Design"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase"> UI/UX Design</h3>
                <p>
                  Our team creates a visually appealing and user-friendly design
                  that aligns with your brand identity.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase"> Development</h3>
                <p>
                  We architect and develop your app or website, ensuring optimal
                  performance and functionality.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/design-and-development.png"
                    alt="Development"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.9s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/testing.png"
                    alt="Testing"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase"> Testing</h3>
                <p>
                  Thorough testing is conducted to ensure compatibility and a
                  seamless user experience.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Deployment</h3>
                <p>
                  After your approval, we release the app or website and provide
                  ongoing support for updates.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/deployment.png"
                    alt="Deployment"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  
  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 ">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      > 
        <div className="col-md-7 col-md-10 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
          Hire Cross-Platform App Developer

          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
     Create versatile cross-platform apps with the guidance of our skilled developers.


          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>






</>

    )
}

export default CrossPlatformAppDevelopment