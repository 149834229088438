import React, { useState } from 'react';

const EntertainmentAndMediaSolutions = () => {

  
    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
      
        {
            title: "SOCIAL MEDIA APP DEVELOPMENT",
            imageSrc: "/assets/images/Picture-1-3.png",
            content:
              "We assist entrepreneurs globally in elevating their online visibility and offer client-focused services for enhancing social media growth, reshaping how their audience engages with content.",
          },
          {
            title: "DIGITAL ART SOFTWARE DEVELOPMENT",
            imageSrc: "/assets/images/Picture-2-3-1.png",
            content:
              "At Brain Star Technologies, we specialize in crafting cutting-edge software equipped with robust drawing tools that offer the flexibility to produce captivating visuals and graphics. Our focus lies in developing enduring IT solutions tailored for the media and entertainment industry, garnering adoration from countless users.",
          },
          {
            title: "MUSIC & VIDEO STREAMING SERVICES",
            imageSrc: "/assets/images/Picture-3-3.png",
            content:
              "Our specialists monitor current patterns and offer exceptional substitutes for existing streaming services. Through our expertise in developing entertainment apps, your users can effortlessly personalize their music and video collections to align with their preferences.",
          },
          {
            title: "CONTENT MONETIZATION PLATFORMS",
            imageSrc: "/assets/images/Picture-4-3.png",
            content:
              "In order to guarantee the sustained profitability and ongoing growth of businesses, we develop exceptional content monetization systems. These platforms enable companies to optimize their earnings while ensuring that users have a pleasant and satisfying customer experience.",
          },
          {
            title: "DIGITAL MEDIA PLATFORMS",
            imageSrc: "/assets/images/Picture-5-2.png",
            content:
              "Brain Star Technologies provides top-notch solutions for social media networks, e-commerce platforms, content management systems, and various digital media interfaces. These solutions assist businesses in achieving their goals effectively.",
          },
          {
            title: "PROCESS AND WORKFLOW AUTOMATION",
            imageSrc: "/assets/images/Picture-6-2.png",
            content:
              "Brain Star Technologies offers a range of services including process mapping, system integration, software development, and continuous support designed to assist businesses in automating tasks and enhancing processes. This empowers businesses to optimize their operations and attain their objectives with increased efficiency.",
          },
          {
            title: "REPORTING AND ANALYTICAL DASHBOARDS",
            imageSrc: "/assets/images/Picture-7-3.png",
            content:
              "Media professionals derive significant advantages from utilizing our reporting and analytical dashboards, as these tools offer valuable information about audience interaction, the effectiveness of content, and crucial metrics that contribute to the prosperity of their organizations.",
          },
          {
            title: "CLOUD MIGRATION AND SYSTEM MODERNIZATION",
            imageSrc: "/assets/images/Picture-8-3.png",
            content:
              "We offer optimal cloud migration approaches that lower expenses related to hardware, boost flexibility, and elevate data protection. Our solutions are at the forefront, aiding businesses in refining workflows and elevating client satisfaction.",
          },
        ];

  return (
<>
<section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Media &amp; entertainment software development</span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              At Brain Star Technologies, our mission is to assist media and
              entertainment enterprises in enhancing their operational
              processes, staying up-to-date with technological advancements, and
              delivering inventive resolutions to their clientele. Rely on us to
              furnish you with the necessary resources for triumph in this
              cutthroat sector. Whether your aim is process refinement,
              efficiency enhancement, or business elevation, we are at your
              service.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>


<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>

        
</>
  )
}

export default EntertainmentAndMediaSolutions