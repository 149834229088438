import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const ContactUs = () => {
    useEffect(() => {
      AOS.init({
        duration: 1000,
        easing: 'ease-out-back',
        once: true,
      });
    }, []);
  
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactno: '',
        projectbudget: '',
        message: '',
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          const response = await fetch('http://localhost:4444/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
      
          if (response.ok) {
            console.log('Email sent successfully');
          } else {
            console.error('Error sending email');
          }
        } catch (error) {
          console.error('Error sending email', error);
        }
      };
    
  return (

<>
  <section className="section home_banner contact_banner">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-9 banner_text carrer_banner_text hvr-grow-shadow">
              <h1
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                Great Things Unfold <br />
                <span>When You Communicate!</span>
              </h1>
              <p
                className="wow fadeInUp"
                data-aos="fade-down"
                data-aos-delay={300}
              >
                Reach out to us via phone or send us an email to discuss your
                projects.
              </p>
            </div>
            <div
              className="col-md-5 text-center wow fadeInRight"
              data-aos="fade-left"
              data-aos-delay={300}
            >
              <img
                src="/assets/images/contact_us.png"
                alt="Technologies Banner Image"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
        <div className="ocean contact_ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section
    className="section contact_section"
    id="contact"
    style={{ background: "#fff" }}
  >
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center ">
          <h1
            className="text-uppercase"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            Let's talk about what we can build together
          </h1>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay={300}>
            Whatever may be your requirement - be it a simple website design, a
            complex data driven web application development, an ecommerce
            website, a native or cross platform mobile app development or a full
            fledged digital marketing campaign - we have a solution for you.
          </p>
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-5 contact_section_right "
          id="Request_Quote_form"
        >
          <div className="contact_right_wrapper ">
            <div className="col-md-12 common_tittle text-center ">
              <h2
                className="text-uppercase wow fadeInDown"
                data-aos="fade-down"
                data-aos-delay={300}
              >
                <span>Let’s Connect</span>
              </h2>
            </div>
           
            <form
  className="w-100"
  id="contactform"
  action="https://mail.brainstartechnologies.com/submit.php"
  method="post"
  noValidate="novalidate"
>
  <div className="row">
    <div className="col-md-6 col-sm-6 col-xs-12">
      <div className="form-group">
        <input
          type="text"
          id="name"
          name="name"
          className="form-control"
          placeholder="Name"
          required=""
        />
      </div>
    </div>
    <div className="col-md-6 col-sm-6 col-xs-12">
      <div className="form-group">
        <input
          type="text"
          id="email"
          name="email"
          className="form-control"
          placeholder="Email Address"
          required=""
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className=" col-md-6 col-sm-6 col-xs-12 ">
      <div className="form-group">
        <input
          type="text"
          id="contactno"
          name="phone"
          className="form-control"
          placeholder="Mobile"  min="10" max="11"
        />
      </div>
    </div>
    <div className=" col-md-6 col-sm-6 col-xs-12 ">
      <div className="form-group">
        <input
          type="number"
          name="projectbudget"
          className="form-control"
          placeholder="Project Budget"
        />
      </div>
    </div>
  </div>
  <div className="clearfix" />
  <div className="row">
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div className="form-group">
        <textarea
          className="form-control"
          name="message"
          placeholder="Project Summary"
          defaultValue={""}
        />
      </div>
    </div>
    <div className="clearfix" />
    <div className="col-md-12 col-sm-12 col-xs-12" />
    <div className="clearfix" />
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div className="form-group intership-form">
        <div className="loading" style={{ display: "none" }} />
        <button
          type="submit"
          name="submit_query"
          className="btn btn-talk gradient-btn hvr-grow"
          id="contactformSubmit"
        >
          Let's Talk
        </button>
      </div>
    </div>
  </div>
  <div className="clearfix" />
</form>


          </div>
        </div>
        <div className="col-md-6 col-md-offset-1 contact_section_left">
          <div
            className="common_tittle"
            data-aos="fade-up"
            data-aos-duration={300}
          >
            <h2 className="text-uppercase wow fadeInUp" data-aos="fade-up">
              We'd love to talk with you
            </h2>
            <ul className="contact-info">
              <li className="wow fadeInUp" data-aos="fade-up">
                {" "}
                Request a Quote
                <br />
                <a
                  href="mailto:info@braintechnologies.com"
                  className="wow fadeInUp hvr-backward"
                >
                  info@braintechnologies.com
                </a>
              </li>
              <li className="wow fadeInUp" data-aos="fade-up">
                Careers
                <br />
                <a
                  href="mailto:hr@brainstartechnologies.com"
                  className="wow fadeInUp hvr-backward"
                >
                  hr@brainstartechnologies.com
                </a>
              </li>
              <li className="wow fadeInUp" data-aos="fade-up">
                Phone
                <br />
                <a
                  href="tel:+917986324471"
                  className="wow fadeInUp hvr-backward"
                >
                  +917986324471
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section facts_section ofc-branches-section">
    <div className="countries_section w-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="countries_wrapper w-100" data-aos="zoom-out">
              <div className="countries_wrapper_image countryIndia" />
              <div className="country_desc w-100">
                <h3 className="text-uppercase">India</h3>
                <div
                  className="flag_image"
                  data-aos="flip-right"
                  data-aos-delay={300}
                >
                  <img src="/assets/images/flag-india.jpg" alt="india-flag" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="countries_wrapper w-100" data-aos="zoom-out">
              <div className="countries_wrapper_image countryCanada" />
              <div className="country_desc w-100">
                <h3 className="text-uppercase">Canada</h3>
                <div
                  className="flag_image"
                  data-aos="flip-right"
                  data-aos-delay={300}
                >
                  <img src="/assets/images/flag-canada.jpg" alt="canada-flag" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="countries_wrapper w-100" data-aos="zoom-out">
              <div className="countries_wrapper_image countryAustralia" />
              <div className="country_desc w-100">
                <h3 className="text-uppercase">Australia</h3>
                <div
                  className="flag_image"
                  data-aos="flip-right"
                  data-aos-delay={300}
                >
                  <img src="/assets/images/flag-australia1.png" alt="australia-flag" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="countries_wrapper w-100" data-aos="zoom-out">
              <div className="countries_wrapper_image countryUSA" />
              <div className="country_desc w-100">
                <h3 className="text-uppercase">U.S.A.</h3>
                <div
                  className="flag_image"
                  data-aos="flip-right"
                  data-aos-delay={300}
                >
                  <img src="/assets/images/flag-usa1.png" alt="flag-usa" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
    )
}

export default ContactUs