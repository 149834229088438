import React, { useEffect, useState } from 'react';
import { useLocation ,Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Blogs = () => {

    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        // Fetch data from the Node.js backend
        fetch('https://admin.brainstartechnologies.com/api/blogs')
          .then(response => response.json())
          .then(data => {
            // Format the date strings before setting them in state
            const formattedBlogs = data.map(blog => ({
              ...blog,
              formattedDate: new Date(blog.date).toLocaleString(),
            }));
            setBlogs(formattedBlogs); // Set the data in state
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }, []);
      
  return (
    <>

<Helmet>
<title> Blogs | Brain Star Technologies </title> 
        {/* You can add more head elements as needed */}
      </Helmet>

    <div className="breadcrumb-area">
  <div className="container">
    <div className="title">
      <h1>Blogs</h1>
    </div>
  </div>


  <div class="ocean"><div class="wave"></div><div class="wave"></div></div>

</div>



   

<section className="blog-cards-wrapper"  >
{blogs.map((blog, index) => (
  <div className="card-grid-space" key={blog._id} >
 <div className="num">{index + 1}</div> 
     <Link
      className="blog-card"
      to={`/blogs/${blog.slug}`}

      style={{ backgroundImage: `url(https://admin.brainstartechnologies.com/${blog.blogImg})`}}
    >
      <div>
        <h1> {blog.blogTitle} </h1>
        <p>
        {blog.metaDes}
        </p>
        <div className="date"> {blog.createdAt.slice(0, 10)}</div>
        <div className="tags">
          {/* <div className="tag">Cloud Computing</div> */}
        </div>
      </div>
    </Link>
  </div>
  
))}

</section>




    </>
  )
}

export default Blogs