import React, { useState } from 'react';

const TelecomSoftwareDevelopment = () => {

    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
        {
          title: "TELECOM APPLICATION DEVELOPMENT",
          imageSrc: "/assets/images/Frame-49126-15.png",
          content:
            "Our experienced developers work diligently to enhance business flexibility by providing customized solutions that cater to unique requirements. With their expert guidance, our clients consistently elevate their digital efficiency to new heights.",
        },
        {
          title: "TELECOM ENTERPRISE SOFTWARE DEVELOPMENT",
          imageSrc: "/assets/images/Frame-49126-1-1.png",
          content:
            "Brain Star Technologies is prepared to transform businesses into data-driven networks complete with cutting-edge solutions. We leverage the most recent technological advancements and industry-specific expertise to upgrade outdated software and replace legacy systems.",
        },
        {
          title: "UNIFIED COMMUNICATION SOFTWARE",
          imageSrc: "/assets/images/Frame-49126-2-1.png",
          content:
            "Our dedicated experts integrate various communication tools such as voice calls, instant messaging, video conferencing, content sharing, and more, creating a versatile and effective system.",
        },
        {
          title: "ROBOTIC PROCESS AUTOMATION",
          imageSrc: "/assets/images/Frame-49126-3-1.png",
          content:
            "In line with the movement towards complete automation, we are developing solutions that mimic human actions during interactions on digital devices. By mechanizing tasks that occur in a repetitive manner, telecommunications companies can release their available assets to concentrate on tasks of greater significance.",
        },
        {
          title: "DIGITAL MEDIA STREAMING",
          imageSrc: "/assets/images/Frame-49126-4-1.png",
          content:
            "Recognizing the undeniable significance of video content, Brain Star Technologies introduces advanced media streaming capabilities. This enables the seamless delivery of high-quality audio and video content, showcasing exceptional picture clarity and detail.",
        },
        {
          title: "THIRD-PARTY SOFTWARE INTEGRATION",
          imageSrc: "/assets/images/Frame-49126-5-1.png",
          content:
            "We view the development of telecom software as a unified process, rather than piecing together separate modules. Our solutions are designed for effortless scalability and seamless integration with external platforms, ensuring a smooth alignment with your business expansion.",
        },
        {
          title: "ENTERPRISE DATABASE MANAGEMENT",
          imageSrc: "/assets/images/Frame-49126-6-1.png",
          content:
            "Brain Star Technologies specializes in managing both relational databases (such as MySQL, PostgreSQL, MSSQL) and non-relational databases (including MongoDB, DynamoDB, CosmosDB, Cassandra). These databases are utilized to collect and centralize data from various communication sources.",
        },
        {
          title: "TELECOM INFRASTRUCTURE MANAGEMENT",
          imageSrc: "/assets/images/Frame-49126-7-1.png",
          content:
            "Our experienced DevOps engineers establish and configure telecommunications infrastructure software, guaranteeing the reliability of data transmission across various mediums such as landlines, cables, and 3/4/5G wireless networks.",
        },
        {
          title: "TELECOM SECURITY SERVICES",
          imageSrc: "/assets/images/Frame-49126-8-1.png",
          content:
            "By employing the protocols of L2 VPN and L3 VPN, our developers guarantee a secure and consistent connection for distinct entities over diverse communication channels.",
        },
      ];
      
  return (
   <>
   <section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Telecommunica­tions software development</span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Robust communication and consistent data sharing are more crucial
              than ever for contemporary businesses. As a result, corporations
              are increasingly prioritizing telecommunications services. Brain
              Star Technologies offers the opportunity for startups, small to
              medium-sized enterprises (SMEs), and large corporations to fully
              utilize top-notch telecom software development. Our aim is to
              bridge the divide between business and technology by providing a
              tailored solution that seamlessly aligns with your requirements.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>

<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>
   </>
  )
}

export default TelecomSoftwareDevelopment