import React, { useState } from 'react';

    const AgricultureSoftwareDevelopment = () => {
    
      
        const [activeTab, setActiveTab] = useState(0);
    
        const tabMouseOverHandler = (index) => {
          // Set the active tab when a tab is hovered over
          setActiveTab(index);
        };
    
        const tabData = [
          
            {
                title: "LAND MANAGEMENT SOFTWARE",
                imageSrc: "/assets/images/agr1s.jpg",
                content:
                  "Brain Star Technologies offers advanced solutions for managing medical practices, equipping healthcare institutions with digital tools to effectively address clinical, administrative, and financial hurdles. We design versatile healthcare software that simplifies the day-to-day tasks of care providers, encompassing activities such as handling data, organizing schedules, managing billing, and processing claims.",
              },
              {
                title: "AGRICULTURE SUPPLY CHAIN MANAGEMENT SOFTWARE",
                imageSrc: "/assets/images/agr2s.jpg",
                content:
                  "We develop personalized software for hospital management, aiming to streamline administrative processes, automate medical data handling, and enhance the quality of healthcare delivery. Our hospital information systems play a crucial role in ensuring the efficient operation of medical institutions, promoting smooth communication across various departments, and minimizing the impact of human errors. Additionally, our team of healthcare software developers creates specialized solutions tailored to different medical fields, including systems for managing laboratory information.",
              },
              {
                title: "AGRICULTURE DRONE SOFTWARE",
                imageSrc: "/assets/images/agr3s.jpg",
                content:
                  "Brain Star Technologies harnesses the capabilities of telecommunication technologies to design advanced telemedicine applications that offer comprehensive mHealth features for providing medical care remotely. Our developed telehealth applications have the potential to substantially lower healthcare expenses, enhance patient involvement, boost treatment results, and widen the reach of medical services. We customize our telehealth apps to suit distinct medical practices and specializations.",
              },
              {
                title: "FARM MANAGEMENT SOFTWARE",
                imageSrc: "/assets/images/agr4s.jpg",
                content:
                  "Our experts are skilled in creating and deploying mobile applications for healthcare that cater to specific needs as they arise. Brain Star Technologies offers healthcare mobile app development solutions, drawing on their wide-ranging experience, up-to-date industry insights, and cutting-edge technologies such as machine learning, augmented reality, real-time communication, and others.",
              },
              {
                title: "AGRICULTURE MAPPING SOFTWARE",
                imageSrc: "/assets/images/agr5s.jpg",
                content:
                  "We design comprehensive telemedicine software that facilitates touchless medical care, enabling patients to engage with doctors via video calls or real-time messaging. Our telemedicine software development services prioritize swift and convenient patient access to healthcare, all while minimizing expenses through an exceptionally user-friendly interface.",
              },
              {
                title: "AGRICULTURE ERP SOFTWARE",
                imageSrc: "/assets/images/agr6s.jpg",
                content:
                  "Brain Star Technologies specializes in creating personalized Remote Patient Monitoring (RPM) solutions that enable healthcare providers to closely track patients' essential signs and overall well-being using mHealth technology and intelligent medical devices. Our cutting-edge Internet of Medical Things (IoMT) solutions facilitate the precise collection of real-time health information, ensuring its secure storage and thorough analysis. To guarantee the effectiveness of our RPM software, we enhance it with extensive functional features: analysis of health-related data, notifications regarding vital parameter fluctuations, AI-powered conversational bots, automated documentation, a messaging platform, and a range of additional capabilities.",
              },
              {
                title: "LIVESTOCK MANAGEMENT SOFTWARE",
                imageSrc: "/assets/images/agr7s.jpg",
                content:
                  "Our group of skilled IT professionals develops sophisticated software to operate a variety of medical tools, such as wearables, implanted devices, and imaging machines. Brain Star Technologies specializes in offering services for the development of Software as a Medical Device (SaMD), crafting diverse healthcare software solutions for tasks like disease management, diagnostics, medication tracking, analyzing medical images, and more.",
              },
              {
                title: "PRECISION AGRICULTURE SOFTWARE",
                imageSrc: "/assets/images/agr8s.jpg",
                content:
                  "Our team of IT experts possesses comprehensive expertise in crafting 3D medical image analysis systems tailored for healthcare institutions, research facilities, and device makers. We are well-versed in handling a variety of image formats and protocols such as DICOM, MINC, NIfTI, and ECAT7, among others. When it comes to imaging modalities, we specialize in designing solutions for MRI, CT, PET, SPECT, X-ray, and ultrasound scans.",
              },
              
            ];
    
      return (
    <>
    <section
      className="section home_banner contact_banner"
      style={{ marginBottom: "3rem" }}
    >
      <div className="header_banner w-100">
        <div className="banner_overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
                <h1
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <span> Agriculture software development </span>
                </h1>
                <p
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-down"
                  data-aos-delay={300}
                >
    
    Brain Star Technologies stands out as a pioneering company in the field of agriculture software. Our focus is on driving the digital revolution in agriculture, providing cutting-edge software that empowers both agribusinesses and industry experts. Our mission is to enhance productivity and promote sustainability within the agricultural sector. By harnessing the power of artificial intelligence (AI), machine learning (ML), and predictive analytics, we develop customized software solutions to address the specific requirements of today's farmers.    
                </p>
              </div>
            </div>
          </div>
          <div className="ocean contact_ocean">
            <div className="wave" />
            <div className="wave" />
          </div>
        </div>
      </div>
    </section>
    
    
    <div className="services_container">
          <div className="services_lt">
            <ul id="scroll_list" className="scroll_list">
              {tabData.map((tab, index) => (
                <li
                  key={index}
                  className={`tab-link ${activeTab === index ? 'active' : ''}`}
                  onMouseOver={() => tabMouseOverHandler(index)}
                >
                  <span data-tab={index + 1}>
                    <span className="scroll_list_num">{index + 1}.</span>
                    {tab.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="services_rt">
            <div className="sevices_rt_inner">
              {tabData.map((tab, index) => (
                <div
                  key={index}
                  id={index + 1}
                  className={`tab-content ${activeTab === index ? 'current' : ''}`}
                >
                  <figure>
                    <img src={tab.imageSrc} alt="img" />
                  </figure>
                  <div className="serv_card_head">
                    <h1>{tab.title}</h1>
                  </div>
                  <p className="card_para">{tab.content}</p>
                </div>
              ))}
            </div>
            </div> </div>
    
            
    </>
      )
    }
    

export default AgricultureSoftwareDevelopment