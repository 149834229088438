

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Footer } from './components/Footer';
import { Header } from './components/Header';

import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import WebsiteDevelopment from './pages/WebsiteDevelopment';
import MobileAppDevelopment from './pages/MobileAppDevelopment';
import DigitalMarketing from './pages/DigitalMarketing';
import CrossPlatformAppDevelopment from './pages/CrossPlatformAppDevelopment';
import UIUXDesign from './pages/UIUXDesign';
import EmergingTechnologies from './pages/EmergingTechnologies';
import InternetofThings from './pages/InternetofThings';
import ManagedServices from './pages/ManagedServices';
import Technologies from './pages/Technologies';
import Portfolio from './pages/Portfolio';
import Blockchain from './pages/Blockchain';
import Nft from './pages/Nft';
import HealthcareSoftwareDevelopment from './pages/HealthcareSoftwareDevelopment';
import TelecomSoftwareDevelopment from './pages/TelecomSoftwareDevelopment';
import EnterpriseSoftwareDevelopment from './pages/EnterpriseSoftwareDevelopment'
import EntertainmentAndMediaSolutions from './pages/EntertainmentAndMediaSolutions';
import FinanceSoftwareDevelopment from './pages/FinanceSoftwareDevelopment';
import GovernmentItSolutions from './pages/GovernmentItSolutions';
import LogisticsTransportationSoftwareDevelopment from './pages/LogisticsTransportationSoftwareDevelopment';
import InsuranceSoftwareDevelopment from './pages/InsuranceSoftwareDevelopment';
import MarketingSoftwareDevelopment from './pages/MarketingSoftwareDevelopment';
import EcommerceDevelopmentServices from './pages/EcommerceDevelopmentServices';
import ManufacturingSoftwareDevelopment from './pages/ManufacturingSoftwareDevelopment';
import ElearningSoftwareDevelopment from './pages/ElearningSoftwareDevelopment';
import AgricultureSoftwareDevelopment from './pages/AgricultureSoftwareDevelopment';
import BankingSoftwareDevelopmentServices from './pages/BankingSoftwareDevelopmentServices';
import Blogs from './pages/Blogs';
import BlogDetails from './pages/BlogDetails';
import ContactUs from './pages/ContactUs';
import Industries from './pages/Industries';
import Career from './pages/Career';

function App() {
  return (
<>
<Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/outsourcing-web-app-development" element={<WebsiteDevelopment />} />
        <Route path="/mobile-app-development" element={<MobileAppDevelopment />} />
        <Route path="/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/cross-platform-app-development" element={<CrossPlatformAppDevelopment />} />
        <Route path="/web-design-outsourcing" element={<UIUXDesign />} />
        <Route path="/emerging-technologies" element={<EmergingTechnologies />} />
        <Route path="/internet-of-things" element={<InternetofThings />} />
        <Route path="/managed-services" element={<ManagedServices />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blockchain" element={<Blockchain />} />
        <Route path="/nft" element={<Nft />} />
      <Route path="/healthcare-software-development" element={<HealthcareSoftwareDevelopment />} />
      <Route path="/telecom-software-development" element={<TelecomSoftwareDevelopment />} />
      <Route path="/enterprise-software-development" element={<EnterpriseSoftwareDevelopment />} />
      <Route path="/entertainment-and-media-solutions" element={<EntertainmentAndMediaSolutions />} />
      <Route path="/finance-software-development" element={<FinanceSoftwareDevelopment />} />
      <Route path="/government-it-solutions" element={<GovernmentItSolutions />} />
      <Route path="/logistics-transportation-software-development" element={<LogisticsTransportationSoftwareDevelopment />} />
      <Route path="/insurance-software-development" element={<InsuranceSoftwareDevelopment />} />
      <Route path="/marketing-software-development" element={<MarketingSoftwareDevelopment />} />
      <Route path="/ecommerce-development-services" element={<EcommerceDevelopmentServices />} />
      <Route path="/manufacturing-software-development" element={<ManufacturingSoftwareDevelopment />} />
      <Route path="/elearning-software-development" element={<ElearningSoftwareDevelopment />} />
      <Route path="/agriculture-software-development" element={<AgricultureSoftwareDevelopment />} />
      <Route path="/banking-software-development-services" element={<BankingSoftwareDevelopmentServices />} />
      <Route path="/industries" element={<Industries />} />
      
      <Route path="/career" element={<Career />} />

 <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      
         <Route path="/contact-us" element={<ContactUs />} />

        
      </Routes>
      <Footer />
</>     
  );
}

export default App;

