 import React, { useState } from 'react';

    const MarketingSoftwareDevelopment = () => {
    
      
        const [activeTab, setActiveTab] = useState(0);
    
        const tabMouseOverHandler = (index) => {
          // Set the active tab when a tab is hovered over
          setActiveTab(index);
        };
    
        const tabData = [
          
            {
                title: "MARKETING AUTOMATION SOFTWARE",
                imageSrc: "/assets/images/Picture-1.png",
                content:
                  "Performing marketing tasks such as email marketing, social media posting, and optimizing ad campaigns manually can consume a significant amount of time. However, the use of marketing automation tools can simplify these activities, enhancing processes like lead generation, direct mail, and online advertising. This ultimately results in increased efficiency in marketing efforts, higher return on investment (ROI), and greater levels of customer satisfaction.",
              },
              {
                title: "MARKETING ANALYTIC SOFTWARE",
                imageSrc: "/assets/images/Picture-21.png",
                content:
                  "We create versatile AI-driven marketing analytics software that monitors and forecasts marketing campaign effectiveness using various measurements like ROAS, ROMI, CPL, and more. Our tools automate reporting, predictive analysis, optimization, customer categorization, attribution modeling, and data visualization.",
              },
              {
                title: "REAL-TIME BIDDING SOFTWARE (RTB)",
                imageSrc: "/assets/images/Picture-3.png",
                content:
                  "Real-time bidding (RTB) has revolutionized how ads are distributed and revenue is optimized in the ad tech industry. Our RTB platforms enhance digital marketing with efficient automation, ensuring precise ad targeting and improved revenue generation compared to traditional ineffective online advertising methods.",
              },
              {
                title: "EMAIL MARKETING SOFTWARE",
                imageSrc: "/assets/images/Picture-4.png",
                content:
                  "Email marketing offers impressive ROI and serves as a potent method for personalized client communication. Our experts create automated email marketing software, streamlining processes and enhancing engagement and conversion rates. Our tailored tools free up time spent on mundane tasks, enabling you to prioritize strategy and innovation.",
              },
              {
                title: "SOFTWARE FOR SOCIAL MEDIA MARKETING",
                imageSrc: "/assets/images/Picture-5.png",
                content:
                  "Brain Star Technologies creates software that enhances social media processes, facilitates monitoring, and streamlines content publishing. Our tools help businesses manage channels, track engagement, and stay on schedule and budget.",
              },
              {
                title: "SALES AUTOMATION",
                imageSrc: "/assets/images/Picture-6.png",
                content:
                  "Sales automation is essential for enhancing customer experiences. It streamlines tasks like lead assessment, analytics, forecasting, and pipeline management. Brain Star Technologies offers digital solutions to manage a higher volume of leads while maintaining a personal touch.",
              },
              {
                title: "MARKETING CRM SOFTWARE",
                imageSrc: "/assets/images/Picture-7.png",
                content:
                  "We specialize in crafting and optimizing CRM systems that help businesses build lasting customer relationships, drive new leads, and streamline campaign management. Our team enhances CRM software by incorporating tools for automating ad campaigns, tracking email interactions, and performing A/B tests on landing pages.",
              },
              {
                title: "WORKFLOW AUTOMATION SOFTWARE",
                imageSrc: "/assets/images/Picture-8.png",
                content:
                  "Workflow automation software enhances enterprise efficiency by simplifying complex processes through customized solutions designed by our experts. This saves time, boosts effectiveness, and enhances accountability in your business operations.",
              },
              {
                title: "REVENUE MANAGEMENT SOFTWARE",
                imageSrc: "/assets/images/Picture-9.png",
                content:
                  "Brain Star Technologies offers advanced revenue management software designed to synchronize sales and customer payments, minimize revenue leakage, allocate revenue across divisions, and enhance the overall performance of products and services. Our solutions specialize in billing, invoicing, and payment optimization, all customized to suit your business needs.",
              },
              {
                title: "CUSTOMER LOYALTY SOFTWARE",
                imageSrc: "/assets/images/Picture-10.png",
                content:
                  "Customer loyalty programs can be optimized using specialized software. Our solutions utilize ML algorithms to anticipate customer actions and effectively oversee loyalty initiatives, distributing rewards such as points, coupons, and samples.",
              },
              {
                title: "ADVERTISING AGENCY SOFTWARE",
                imageSrc: "/assets/images/Picture-11.png",
                content:
                  "We've created an all-in-one software for ad agencies, covering project management, team collaboration, client relations, media planning, data analysis, budget tracking, and reporting. Our tailored solutions allow agencies to concentrate on delivering top-tier creative campaigns while we handle their operational demands.",
              },
              {
                title: "CUSTOMER EXPERIENCE MANAGEMENT (CXM) SOFTWARE",
                imageSrc: "/assets/images/Picture-19.png",
                content:
                  "Our tailored CXM solutions enhance customer-company interactions. Integrating CXM software with various tools allows us to consolidate data sources, offering a holistic customer view. This empowers marketers to grasp customer insights, forecast actions, provide personalized offers, and boost satisfaction.",
              },
              {
                title: "ONLINE REPUTATION MANAGEMENT SOFTWARE",
                imageSrc: "/assets/images/Picture-20.png",
                content:
                  "We develop tools to monitor, collect, and distribute customer feedback, providing valuable insights for improving your online reputation. Our software utilizes advanced SEMs and integrates with third-party platforms to assist businesses in attracting and retaining customers by swiftly responding to reviews.",
              },
              
            ];
    
      return (
    <>
    <section
      className="section home_banner contact_banner"
      style={{ marginBottom: "3rem" }}
    >
      <div className="header_banner w-100">
        <div className="banner_overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
                <h1
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <span> Marketing software development </span>
                </h1>
                <p
                  className="wow fadeInUp aos-init aos-animate"
                  data-aos="fade-down"
                  data-aos-delay={300}
                >
               At Brain Star Technologies, we leverage our skilled developer team and innovation focus to assist marketing firms in realizing their concepts and maintaining a competitive edge. Proficient in the marketing field, we provide creative and efficient remedies that foster business expansion, enhance efficacy, and engage intended customer bases. Whether introducing digital products or revamping websites, Brain Star Technologies guarantees exceptional outcomes through its vast expertise and track record.


                </p>
              </div>
            </div>
          </div>
          <div className="ocean contact_ocean">
            <div className="wave" />
            <div className="wave" />
          </div>
        </div>
      </div>
    </section>
    
    
    <div className="services_container">
          <div className="services_lt">
            <ul id="scroll_list" className="scroll_list">
              {tabData.map((tab, index) => (
                <li
                  key={index}
                  className={`tab-link ${activeTab === index ? 'active' : ''}`}
                  onMouseOver={() => tabMouseOverHandler(index)}
                >
                  <span data-tab={index + 1}>
                    <span className="scroll_list_num">{index + 1}.</span>
                    {tab.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="services_rt">
            <div className="sevices_rt_inner">
              {tabData.map((tab, index) => (
                <div
                  key={index}
                  id={index + 1}
                  className={`tab-content ${activeTab === index ? 'current' : ''}`}
                >
                  <figure>
                    <img src={tab.imageSrc} alt="img" />
                  </figure>
                  <div className="serv_card_head">
                    <h1>{tab.title}</h1>
                  </div>
                  <p className="card_para">{tab.content}</p>
                </div>
              ))}
            </div>
            </div> </div>
    
            
    </>
      )
    }

export default MarketingSoftwareDevelopment