import React, { useState } from 'react';

const HealthcareSoftwareDevelopment = () => {
  // State to track the currently active tab
  const [activeTab, setActiveTab] = useState(0);

  const tabClickHandler = (index) => {
    // Set the active tab when a tab is clicked
    setActiveTab(index);
  };

  // Define the tab data
  const tabData = [
    {
      title: "MEDICAL PRACTICE MANAGEMENT SOFTWARE",
      imageSrc: "/assets/images/1-1.png",
      content: "Brain Star Technologies offers advanced solutions for managing medical practices, equipping healthcare institutions with digital tools to effectively address clinical, administrative, and financial hurdles. We design versatile healthcare software that simplifies the day-to-day tasks of care providers, encompassing activities such as handling data, organizing schedules, managing billing, and processing claims.",
    },
    {
      title: "HOSPITAL INFORMATION SYSTEMS",
      imageSrc: "/assets/images/2-1.png",
      content: "We develop personalized software for hospital management, aiming to streamline administrative processes, automate medical data handling, and enhance the quality of healthcare delivery. Our hospital information systems play a crucial role in ensuring the efficient operation of medical institutions, promoting smooth communication across various departments, and minimizing the impact of human errors. Additionally, our team of healthcare software developers creates specialized solutions tailored to different medical fields, including systems for managing laboratory information.",
    },
    {
      title: "TELEMEDICINE & TELEHEALTH APPS",
      imageSrc: "/assets/images/3-1.png",
      content: "Brain Star Technologies harnesses the capabilities of telecommunication technologies to design advanced telemedicine applications that offer comprehensive mHealth features for providing medical care remotely. Our developed telehealth applications have the potential to substantially lower healthcare expenses, enhance patient involvement, boost treatment results, and widen the reach of medical services. We customize our telehealth apps to suit distinct medical practices and specializations.",
    },
    {
      title: "PATIENT/HOSPITAL MOBILE APPS",
      imageSrc: "/assets/images/4-1.png",
      content: "Our experts are skilled in creating and deploying mobile applications for healthcare that cater to specific needs as they arise. Brain Star Technologies offers healthcare mobile app development solutions, drawing on their wide-ranging experience, up-to-date industry insights, and cutting-edge technologies such as machine learning, augmented reality, real-time communication, and others.",
    },
    {
      title: "EHR/EMR SOFTWARE",
      imageSrc: "/assets/images/5-1.png",
      content: "We design comprehensive telemedicine software that facilitates touchless medical care, enabling patients to engage with doctors via video calls or real-time messaging. Our telemedicine software development services prioritize swift and convenient patient access to healthcare, all while minimizing expenses through an exceptionally user-friendly interface.",
    },
    {
      title: "REMOTE PATIENT MONITORING",
      imageSrc: "/assets/images/6-1.png",
      content: "Brain Star Technologies specializes in creating personalized Remote Patient Monitoring (RPM) solutions that enable healthcare providers to closely track patients' essential signs and overall well-being using mHealth technology and intelligent medical devices. Our cutting-edge Internet of Medical Things (IoMT) solutions facilitate the precise collection of real-time health information, ensuring its secure storage and thorough analysis. To guarantee the effectiveness of our RPM software, we enhance it with extensive functional features: analysis of health-related data, notifications regarding vital parameter fluctuations, AI-powered conversational bots, automated documentation, a messaging platform, and a range of additional capabilities.",
    },
    {
      title: "MEDICAL DEVICE SOFTWARE",
      imageSrc: "/assets/images/7-1.png",
      content: "Our group of skilled IT professionals develops sophisticated software to operate a variety of medical tools, such as wearables, implanted devices, and imaging machines. Brain Star Technologies specializes in offering services for the development of Software as a Medical Device (SaMD), crafting diverse healthcare software solutions for tasks like disease management, diagnostics, medication tracking, analyzing medical images, and more.",
    },
    {
      title: "MEDICAL IMAGE ANALYSIS",
      imageSrc: "/assets/images/8.png",
      content: "Our team of IT experts possesses comprehensive expertise in crafting 3D medical image analysis systems tailored for healthcare institutions, research facilities, and device makers. We are well-versed in handling a variety of image formats and protocols such as DICOM, MINC, NIfTI, and ECAT7, among others. When it comes to imaging modalities, we specialize in designing solutions for MRI, CT, PET, SPECT, X-ray, and ultrasound scans.",
    },
    {
      title: "PATIENT ENGAGEMENT SOFTWARE",
      imageSrc: "/assets/images/9.png",
      content: "Brain Star Technologies creates web and mobile healthcare applications with a focus on patients, aiming to cultivate strong and favorable connections between healthcare providers and patients. Our approach involves incorporating gamification, Remote Patient Monitoring (RPM), and telemedicine functionalities to encourage caregivers to actively involve patients in their treatment journey. This encourages patients to monitor vital signs, enhance their understanding through education, and keep track of their physical activities.",
    },
    {
      title: "MEDICAL CRM",
      imageSrc: "/assets/images/10.png",
      content: "We specialize in aiding healthcare providers in creating, adopting, merging, and maintaining healthcare CRM solutions. These systems enable medical institutions to enhance interactions with prospective patients, assess patient satisfaction, track the effectiveness of medical personnel, simplify approaches to gaining and retaining patients, and enhance internal processes.",
    },
    {
      title: "CHRONIC DISEASE MANAGEMENT SOFTWARE",
      imageSrc: "/assets/images/11.png",
      content: "Brain Star Technologies offers personalized Clinical Data Management Systems (CDMS) that equip doctors and individuals with digital tools to combat illnesses, prevent complications, reduce relapses, and lower hospital reentries. Our solutions come with distinct features tailored for specific chronic conditions such as diabetes, cancer, and heart diseases, among others. We facilitate smooth information exchange between patients and healthcare providers, enabling instant communication and essential system integrations.",
    },
    {
      title: "HEALTHCARE DATA ANALYTICS SOFTWARE",
      imageSrc: "/assets/images/12.png",
      content: "Our team of data analysts supports healthcare institutions in transforming vast amounts of data into practical knowledge, enhancing the decision-making procedure and elevating the standard of patient services. We provide inventive resolutions for examining health information originating from patients, treatment metrics, medical expenditures, income patterns, and additional aspects of medical management.",
    },
    {
      title: "HEALTH INFORMATION EXCHANGE",
      imageSrc: "/assets/images/13.png",
      content: "We specialize in creating personalized Health Information Exchange (HIE) systems for the smooth and secure sharing of sensitive health data. Our team of software engineers designs tailored solutions that enable the confidential exchange of Protected Health Information (PHI) across various healthcare entities, pharmaceutical firms, and insurers. Our developed HIE solutions are designed to adhere to the highest standards of security, data transfer, and content compatibility.",
    },
    // Add more tab data objects as needed
  ];

  return (
    <>
   <section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Healthcare software development</span>
            </h1>
            <p
              className="wow fadeInUp aos-init aos-animate"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Brain Star Technologies specializes in providing tailored medical
              software development solutions to a wide range of clients in the
              healthcare sector. Our expertise extends to healthcare
              institutions, medical educational institutions, pharmaceutical
              firms, makers of medical devices, as well as emerging digital
              health ventures across the US and Europe. Our healthcare software
              development enterprise is adept at creating diverse medical
              applications suitable for deployment in hospitals, clinics,
              medical practices, assisted living centers, nursing homes,
              pharmacies, and laboratory settings.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>

      <div className="services_container">
        <div className="services_lt">
          <ul id="scroll_list" className="scroll_list">
            {tabData.map((tab, index) => (
              <li
                key={index}
                className={`tab-link ${activeTab === index ? 'active' : ''}`}
                onMouseOver={() => tabClickHandler(index)}
              >
                <span data-tab={index + 1}>
                  <span className="scroll_list_num">{index + 1}.</span>
                  {tab.title}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="services_rt">
          <div className="sevices_rt_inner">
            {tabData.map((tab, index) => (
              <div
                key={index}
                className={`tab-content ${activeTab === index ? 'current' : ''}`}
              >
                <figure>
                  <img src={tab.imageSrc} alt="img" />
                </figure>
                <div className="serv_card_head">
                  <h1>{tab.title}</h1>
                </div>
                <p className="card_para">{tab.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Additional sections and content */}
    </>
  );
};

export default HealthcareSoftwareDevelopment;
