import React, { useState } from 'react';

const FinanceSoftwareDevelopment = () => {

    const [activeTab, setActiveTab] = useState(0);

    const tabMouseOverHandler = (index) => {
      // Set the active tab when a tab is hovered over
      setActiveTab(index);
    };

    const tabData = [
        {
            title: "Billing, invoicing, payment",
            imageSrc: "/assets/images/Frame7.jpg",
            content:
              "Brain Star Technologies specializes in creating and integrating advanced payment software for both B2C and B2B sectors. Our expertise lies in developing solutions that can effortlessly handle a high volume of financial transactions. We craft innovative billing and payment systems that cater to multiple currencies and FinTech frameworks, guaranteeing strong security, transparency, and the ability to handle large numbers of requests.",
          },
          {
            title: "DIGITAL MARKETING",
            imageSrc: "/assets/images/Frame8.jpg",
            content:
              "We utilize cutting-edge digital banking technology to deliver an exceptional user journey, enhance customer interaction, and optimize internal sales processes for a distinct competitive edge. Our banking software facilitates seamless, round-the-clock communication and efficient management of bank accounts, ensuring an integrated experience across all channels.",
          },
          {
            title: "PERSONAL FINANCE",
            imageSrc: "/assets/images/Frame9.jpg",
            content:
              "Our company builds mobile personal budgeting apps to help individuals manage and improve their financial experience. Within the personal finance management app, users access all their assets (fiat, stock, and crypto) thanks to integration with APIs that enable connection with bank accounts, electronic payments, currency exchange, etc.",
          },
          {
            title: "DIGITAL LENDING",
            imageSrc: "/assets/images/Frame10.jpg",
            content:
              "Brain Star Technologies develops intelligent FinTech solutions that digitalize lending procedures, aiming to deliver substantial returns on investment while also enhancing risk management. These platforms are integrated with credit reporting systems and various databases, facilitating online credit assessment, borrower authentication, and effective loan administration processes.",
          },
          {
            title: "TRADING AND INVESTMENT",
            imageSrc: "/assets/images/Frame11.jpg",
            content:
              "We provide exceptional software applications designed for the management of both stock and cryptocurrency assets. Our platforms enable users to effectively oversee their investments, offering features such as visualized statistics, automated buying and selling processes, and real-time market analysis. Our software is enhanced with advanced artificial intelligence algorithms, which continuously assess market fluctuations, integrate with relevant metadata, and generate predictive insights. By using our applications, individuals can optimize their profit potential while minimizing the risk of significant losses.",
          },
          {
            title: "FINANCIAL ANALYTICS",
            imageSrc: "/assets/images/Frame12.jpg",
            content:
              "We help our customers from the financial sector use data analysis and ML technologies to streamline business operations and digitalize time-consuming processes. We utilize a wide range of technological tools to ensure a high level of financial analytics.",
          },
          {
            title: "InsurTech",
            imageSrc: "/assets/images/Frame13.jpg",
            content:
              "Brain Star Technologies offers insurance firms pay-as-you-go insurance options that promote higher customer acquisition and retention levels. This strategy steers the company towards a more technology-driven path, fuels the progress of corporate digital makeover, enhances sales, and expedites customer support services.",
          },
          {
            title: "RegTech",
            imageSrc: "/assets/images/Frame14.jpg",
            content:
              "Brain Star Technologies necessitate the handling of substantial volumes of delicate information. The Banking, Financial Services, and Insurance (BFSI) sector adhere to rigorous government oversight. Our role involves aiding enterprises in satisfying regulations and meeting stipulations by offering Regulatory Technology (RegTech) remedies. This assists in streamlining and expediting the implementation of regulatory compliance procedures in a smoother and more effective manner.",
          },
          {
            title: "ERP FINANCIAL SYSTEMS",
            imageSrc: "/assets/images/Frame-4716-9-1.png",
            content:
              "We specialize in developing high-quality financial technology ERP solutions tailored for enterprises. Our systems enable businesses in various industries to efficiently manage financial outcomes by offering capabilities to strategize, forecast, allocate funds, and present financial insights. Through our emphasis on data-driven methodologies, our clients gain access to effective resources for assessing and enhancing business operations.",
          },
          {
            title: "CMS FINANCIAL SOFTWARE",
            imageSrc: "/assets/images/Frame-4716-8-1.png",
            content:
              "We utilize the capabilities of Content Management Systems (CMS) to craft engaging content on company websites while also tracking how well they perform in terms of being search engine-friendly and generating conversions. With our assistance, the financial technology industry employs user-friendly software that effectively tackles the specific challenges faced by their desired audience. This, in turn, converts casual website visitors into potential customers.",
          },
        ];



  return (
    <>

<section
  className="section home_banner contact_banner"
  style={{ marginBottom: "3rem" }}
>
  <div className="header_banner w-100">
    <div className="banner_overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner_text carrer_banner_text hvr-grow-shadow">
            <h1
              className="wow fadeInUp aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span>Financial software development</span>
            </h1>
            <p
              className="wow fadeInUp aos-init"
              data-aos="fade-down"
              data-aos-delay={300}
            >
              Brain Star Technologies excels as a top provider of tailored
              financial software development services for diverse businesses in
              the finance sector. With a team of skilled engineers and financial
              experts, the company enables clients to optimize operations
              through cutting-edge technology, fostering success in today's
              dynamic digital landscape.
            </p>
          </div>
        </div>
      </div>
      <div className="ocean contact_ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </div>
</section>




<div className="services_container">
      <div className="services_lt">
        <ul id="scroll_list" className="scroll_list">
          {tabData.map((tab, index) => (
            <li
              key={index}
              className={`tab-link ${activeTab === index ? 'active' : ''}`}
              onMouseOver={() => tabMouseOverHandler(index)}
            >
              <span data-tab={index + 1}>
                <span className="scroll_list_num">{index + 1}.</span>
                {tab.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="services_rt">
        <div className="sevices_rt_inner">
          {tabData.map((tab, index) => (
            <div
              key={index}
              id={index + 1}
              className={`tab-content ${activeTab === index ? 'current' : ''}`}
            >
              <figure>
                <img src={tab.imageSrc} alt="img" />
              </figure>
              <div className="serv_card_head">
                <h1>{tab.title}</h1>
              </div>
              <p className="card_para">{tab.content}</p>
            </div>
          ))}
        </div>
        </div> </div>
    </>
  )
}

export default FinanceSoftwareDevelopment