import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation ,Link} from 'react-router-dom';

export const Footer = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          easing: 'ease-out-back', // Easing function
          once: true, // Whether the animation should only happen once
        });
      }, []);
      
  return (
    <>


<section className="section contact_section" id="contact wave" style={{}}>
    <div className="air air1" />
    <div className="air air2" />
    <div className="air air3" />
    <div className="air air4" />
    <div className="container-fluid">
      <div className="row" style={{ zIndex: 1001 }}>
        <div className="col-md-6 contact_section_left">
          <div className="testimonials-section">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="testimonials-inner wow flipInX">
                  <div className="testi-img">
                    <img
                      src="/assets/images/mike.jpg"
                      alt="Mike"
                      className="img-responsive"
                    />
                  </div>
                  <h3 className="name-position">Mike</h3>
                  <p>
                    Thanks Brain Star Technologies for great support on our
                    project. They not only showed their commitment and hard work
                    in our project, but also helped us take care of some points
                    that got missed by us and did not belong to their scope.
                  </p>
                </div>
                <div className="testimonials-inner wow flipInX">
                  <div className="testi-img">
                    <img
                      src="/assets/images/casi.jpg"
                      alt="Casi"
                      className="img-responsive"
                    />
                  </div>
                  <h3 className="name-position">Casi</h3>
                  <p>
                    Brain Star Technologies has a solid technology team and on
                    time delivery. Very happy to be associated with them Great
                    work!!! The team was solid, efficient and knowledgeable.
                    They did an amazing job on my very challenging app. I will
                    be using them again. Thank you for doing such a great job!
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 mt-30">
                <div className="testimonials-inner wow flipInX">
                  <div className="testi-img">
                    <img
                      src="/assets/images/suzanne.jpg"
                      alt="Suzzane"
                      className="img-responsive"
                    />
                  </div>
                  <h3 className="name-position">Suzzane</h3>
                  <p>
                    Brain Star Technologies is driven by a passion to create and
                    deliver on new ideas and that is reflected in their work. It
                    is a team that strives for excellence and is open to
                    experimenting and creating new trends in the business. Our
                    work relationship has been productive and we have
                    collectively been able to achieve great results.
                  </p>
                </div>
                <div className="testimonials-inner wow flipInX">
                  <div className="testi-img">
                    <img
                      src="/assets/images/daisy.jpg"
                      alt="Daisy"
                      className="img-responsive"
                    />
                  </div>
                  <h3 className="name-position">Daisy</h3>
                  <p>
                    Brain Star Technologies Solutions has done a great job
                    taking our company’s idea and developing a website as per
                    our niche specifications. The team is technically strong and
                    creative. I will not hesitate to recommend them.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <a href="" className="btn btn-view gradient-btn hvr-forward">
                  View More
                  <img
                    src="/assets/images/arrow-right.png"
                    alt="Arrow Right"
                    className="img-responsive"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-5 col-md-offset-1 contact_section_right"
          id="Request_Quote_form"
        >
          <div className="contact_right_wrapper">
            <div className="col-md-12 common_tittle text-center">
              <h2 className="text-uppercase wow fadeInDown">
                Let's Talk <br />
                <span className="wow fadeInDown">About Project</span>
              </h2>
            </div>
            <form
  className="w-100"
  id="contactform"
  action="https://mail.brainstartechnologies.com/submit.php"
  method="post"
  noValidate="novalidate"
>
  <div className="row">
    <div className="col-md-6 col-sm-6 col-xs-12">
      <div className="form-group">
        <input
          type="text"
          id="name"
          name="name"
          className="form-control"
          placeholder="Name"
          required=""
        />
      </div>
    </div>
    <div className="col-md-6 col-sm-6 col-xs-12">
      <div className="form-group">
        <input
          type="text"
          id="email"
          name="email"
          className="form-control"
          placeholder="Email Address"
          required=""
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className=" col-md-6 col-sm-6 col-xs-12 ">
      <div className="form-group">
        <input
          type="text"
          id="contactno"
          name="phone"  min="10" max="11"
          className="form-control"
          placeholder="Mobile"
        />
      </div>
    </div>
    <div className=" col-md-6 col-sm-6 col-xs-12 ">
      <div className="form-group">
        <input
          type="number"
          name="projectbudget"
          className="form-control"
          placeholder="Project Budget"
        />
      </div>
    </div>
  </div>
  <div className="clearfix" />
  <div className="row">
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div className="form-group">
        <textarea
          className="form-control"
          name="message"
          placeholder="Project Summary"
          defaultValue={""}
        />
      </div>
    </div>
    <div className="clearfix" />
    <div className="col-md-12 col-sm-12 col-xs-12" />
    <div className="clearfix" />
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div className="form-group intership-form">
        <div className="loading" style={{ display: "none" }} />
        <button
          type="submit"
          name="submit_query"
          className="btn btn-talk gradient-btn hvr-grow"
          id="contactformSubmit"
        >
          Let's Talk
        </button>
      </div>
    </div>
  </div>
  <div className="clearfix" />
</form>


          </div>
        </div>
      </div>
    </div>
  </section>
  <section style={{ position: "relative", overflow: "hidden" }}>
    <div className="bottom-curve">
      <div className="ocean">
        <div className="wave" />
        <div className="wave" />
      </div>
    </div>
  </section>

<section className="section footer_section">
  <div id="stars" />
  <div id="stars2" />
  <div id="stars3" />
  <div className="footer_upper w-100">
  <div className="container-fluid" id="contact-footer">
    <div className="row">
      <div
        className="col-md-3 footer_section_left aos-init aos-animate"
        data-aos="zoom-in"
      >
        <p>
          Brain Star Technologies is a trusted technology leader in web,
          software, and mobile app development for various industries. We are
          here to give you something unique curated with all the expertise we
          have to help you establish a solid name in the market for being the
          top web development company.
        </p>
        <p>
          Our mission is to help you grow your business by overcoming
          technological challenges. From scratch to finish, we ensure you get
          constant support.
        </p>
        <div className="col-md-2">
          {/* Facebook */}
          <a
            className="btn1 m-1 hvr-bob"
            href="https://www.facebook.com/brainstartech"
          >
            <i className="fa fa-facebook-f" />
          </a>
          {/* Twitter */}
          <a
            className="btn1 m-1 hvr-bob"
            href="https://twitter.com/BrainStarTech"
          >
            <i className="fa fa-twitter" />
          </a>
          {/* Instagram */}
          <a
            className="btn1 m-1 hvr-bob"
            href="https://www.instagram.com/brainstartechnologies"
          >
            <i className="fa fa-instagram" />
          </a>
          {/* Linkedin */}
          <a
            className="btn1 m-1 hvr-bob"
            href="https://www.linkedin.com/company/brain-star-technologies"
          >
            <i className="fa fa-linkedin" />
          </a>
          {/* watsapp */}
          <a className="btn1 m-1 hvr-bob" href="https://wa.me/+917986324471">
            <i className="fa fa-whatsapp" />
          </a>
        </div>
      </div>
      <div
        className="col-md-8 col-md-offset-1 footer_quickLinks aos-init aos-animate"
        data-aos="zoom-in"
      >
        <div className="row">
          <div className="col-md-5">
            <ul className="services_provided w-100">
              <li>
                <Link to="/about-us#top" className="hvr-grow">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/services#top" className="hvr-grow">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/technologies#top" className="hvr-grow">
                  Technologies
                </Link>
              </li>
              <li>
                <Link to="/industries#top" className="hvr-grow">
                  Industries
                </Link>
              </li>
              <li>
                <Link to="/portfolio#top" className="hvr-grow">
                  Portfolio
                </Link>
              </li>
              <li>
                <Link to="/blogs#top" className="hvr-grow">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/career#top" className="hvr-grow">
                  Careers
                </Link>
              </li>
              <li>
                <Link to="/contact-us#top" className="hvr-grow">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-5">
            <ul
              className="contact_info_column aos-init aos-animate"
              data-aos="zoom-in"
            >
              <li>
                <span>
                  <img
                    src="/assets/images/footer-phone.png"
                    alt="contact-us-icon"
                    className="img-responsive"
                  />
                </span>
                <p>
                  <a href="tel:+917986324471">+917986324471</a>
                </p>
                <p>Mon-Fri 9 am to 6 pm</p>
              </li>
              <li>
                <span>
                  <img
                    src="/assets/images/footer-email.png"
                    alt="email-icon"
                    className="img-responsive"
                  />
                </span>
                <p>
                  <a href="mailto:info@brainstartechnologies.com">
                    info@brainstartechnologies.com
                  </a>
                </p>
                <p>Online Support</p>
              </li>
              <li>
                <div className="newsletter">
                  <h5 className="text-uppercase mb-4 font-weight-bold">
                    NEWSLETTER
                  </h5>
                  <p>
                    Subscribe for Newsletter.
                    <br />
                    We don't send spam so don't worry.
                  </p>
                  <div className="form col-md-7 col-lg-8">
                    <form
                      action="https://mail.brainstartechnologies.com/news.php"
                      method="post"
                      data-gtm-form-interact-id={0}
                    >
                      <input
                        type="text"
                        placeholder="Email..."
                        name="email"
                        data-gtm-form-interact-field-id={0}
                      />
                      <button type="submit" name="submit_news">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="clearfix" />
      <div className="company-locations">
        <div className="col-md-2">
          <ul className="company-loc">
            <li
              className="hvr-pulse-shrink aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span className="icon">
                <img
                  src="/assets/images/flag-india.jpg"
                  alt="footer-location-icon"
                  className="img-responsive"
                />
              </span>
              <p>
                <strong>INDIA</strong>
                <br />
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-2">
          <ul className="company-loc">
            <li
              className="hvr-pulse-shrink aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span className="icon">
                <img
                  src="/assets/images/flag-canada.jpg"
                  alt="footer-location-icon"
                  className="img-responsive"
                />
              </span>
              <p>
                <strong>CANADA</strong>
                <br />
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-2">
          <ul className="company-loc">
            <li
              className="hvr-pulse-shrink aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span className="icon">
                <img
                  src="/assets/images/flag-usa1.png"
                  alt="footer-location-icon"
                  className="img-responsive"
                />
              </span>
              <p>
                <strong>U.S.A.</strong>
                <br />
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-2">
          <ul className="company-loc">
            <li
              className="hvr-pulse-shrink aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span className="icon">
                <img
                  src="/assets/images/flag-australia1.png"
                  alt="footer-location-icon"
                  className="img-responsive"
                />
              </span>
              <p>
                <strong>AUSTRALIA</strong>
                <br />
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-2">
          <ul className="company-loc">
            <li
              className="hvr-pulse-shrink aos-init"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <span className="icon">
                <img
                  src="/assets/images/uk-flag.png"
                  alt="footer-location-icon"
                  className="img-responsive"
                />
              </span>
              <p>
                <strong>UK</strong>
                <br />
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


  <div className="footer_bottom w-100">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 copyright">
          <p>
            Brain Star Technologies © 2023
            <img
              src="/assets/images/dmca.png"
              alt="dmca-icon"
              className="img-responsive"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  )
}
