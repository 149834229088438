import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Service = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);

      
  return (
<>

<section className="section home_banner services_banner">

<div className="header_banner w-100">
  <div className="banner_overlay">
    <div className="container-fluid">
      <div className="row banner-content">
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            <span>Service</span> Spectrum
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            Empowering cognitive business through complex tech for optimized
            project performance. Delivering world-class IT solutions with a
            balanced and quality-focused approach.
          </p>
        </div>
        <div
          className="col-md-5 text-center services-banner-image wow fadeInRight aos-init"
          data-aos="fade-right"
          data-aos-delay="0.3s"
        >
          <img
            src="/assets/images/services_banner.png"
            alt="Our Services Banner Image"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
    <div className="ocean service_ocean">
      <div className="wave" />
      <div className="wave" />
    </div>
  </div>
</div>
</section>

<section className="section services_column_section services-inner">
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-6 wow fadeInLeft moving-border"
          data-aos="fade-left"
          data-aos-delay="0.3s"
        >
          <img
            src="/assets/images/web-development.png"
            alt=""
            className="img-responsive"
          />
        </div>
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            Elevate Your Business with
            <br />
            <span>Mobile Apps!</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.4s">
            Revolutionize your business growth with our exceptional mobile app
            development services. From dynamic B2E and B2B apps tailored for
            small businesses, enterprises, to ambitious startups, we deliver
            results that exceed expectations. Embrace success with our native
            and hybrid technologies, designed for iOS and Android smartphones.
          </p>
          <ul>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              <a href="#">iPhone App Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.6s"
            >
              <a href="#">iPad App Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.7s"
            >
              <a href="#">Swift App Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.8s"
            >
              <a href="#">Andriod App Development</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section services-inner servicesSection2">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            Experience the Power of <br />
            <span>Website Development Services!</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-wow-delay="0.4s">
            Elevate your industry standing with our top-notch custom software
            development services. Embrace next-generation solutions that cater
            to your business goals and drive technological superiority. Our
            diverse team of software developers employs the latest technologies
            to craft flawless and secure software, giving your business an edge
            over competitors.
          </p>
          <div className="row">
            <div className="col-md-6">
              <ul>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  <a href="#">PHP Development</a>
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.6s"
                >
                  <a href="#">Laravel Development</a>
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.7s"
                >
                  <a href="#">Nodejs Development</a>
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.8s"
                >
                  <a href="#">Cake PHP Development</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.9s"
                >
                  <a href="#">Django Web Developers</a>
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1s"
                >
                  <a href="#">Angular Development</a>
                </li>
                <li
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="1.1s"
                >
                  <a href="#">Wordpress Development</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 wow fadeInRight moving-border"
          data-aos="fade-right"
          data-aos-delay="0.3s"
        >
          <img
            src="/assets/images/services-website-development.png"
            alt="Website Development Services"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section services-inner">
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-6 wow fadeInLeft moving-border"
          data-aos="fade-left"
          data-aos-delay="0.3s"
        >
          <img
            src="/assets/images/services-ui-ux.png"
            alt=""
            className="img-responsive"
          />
        </div>
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2 className="text-uppercase wow fadeInUp" data-aos-delay="0.3s">
            Stand Out with
            <br />
            <span>UI/UX Design!</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.4s">
            Capture attention and engagement with our remarkable UI/UX designs.
            Our creative process ensures meaningful interactions that turn great
            ideas into captivating experiences. From concept to visual identity,
            we craft digital presence that maximizes user engagement.
          </p>
          <ul>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              <a href="#">Customized Web Design Services</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.6s"
            >
              <a href="#">Interface Design for Mobile App</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.7s"
            >
              <a href="#">Front End Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.8s"
            >
              <a href="#">Web Design Services</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.9s"
            >
              <a href="#">E-Commerce App Design</a>
            </li>
            <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="1s">
              <a href="#">iOS and Android App Designs</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section services-inner servicesSection2">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            Embrace the Future - <br />
            <span>Explore Emerging Technologies!</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.4s">
            Thrive in a competitive world with our updated and power-packed
            emerging technologies. Softuvo equips you to steal the limelight and
            conquer the business landscape with confidence.
          </p>
          <ul>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              <a href="#">Bots Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.6s"
            >
              <a href="#">Wearable Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.7s"
            >
              <a href="#">Hybrid Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.8s"
            >
              <a href="#">Ibeacon Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.9s"
            >
              <a href="#">IOT App Development</a>
            </li>
          </ul>
        </div>
        <div
          className="col-md-6 wow fadeInRight moving-border"
          data-aos="fade-right"
          data-aos-delay="0.3s"
        >
          <img
            src="/assets/images/services-emerging-technologies.png"
            alt=""
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section services-inner">
    <div className="container-fluid ">
      <div className="row">
        <div
          className="col-md-6 wow fadeInLeft moving-border"
          data-aos="fade-left"
          data-aos-delay="0.3s"
        >
          <img
            src="/assets/images/services-digital-marketing.png"
            alt=""
            className="img-responsive"
          />
        </div>
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2 className="text-uppercase wow fadeInUp" data-aos-delay="0.3s">
            Conquer the Digital World with <br />
            <span>Our Marketing Strategies!</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.4s">
            Stand out in the digital realm with our inventive digital marketing
            services. Softuvo Solutions is your one-stop-shop for all digital
            needs. Our talented marketing experts strategize online campaigns to
            boost your brand visibility and propel your business to new heights.
          </p>
          <ul>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              <a href="#">Search Engine Optimization</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.6s"
            >
              <a href="#">Social Media Optimization</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.7s"
            >
              <a href="#">Pay Per Click</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.8s"
            >
              <a href="#">Content Writing</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.9s"
            >
              <a href="#">App Store Optimization</a>
            </li>
            <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="1s">
              <a href="#">Online Reputation Management</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section services-inner  servicesSection2">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            Unleash the Potential of
            <br />
            <span>Internet of Things</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.4s">
            Seize the potential of the Internet of Things with Softuvo's expert
            guidance. Our professionals establish seamless connections to
            harness IoT's transformative power for your business.
          </p>
          <ul>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              <a href="#">Consumer Application</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.6s"
            >
              <a href="#">Infrastructure Management</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.7s"
            >
              <a href="#">AR-VR Apps</a>
            </li>
          </ul>
        </div>
        <div
          className="col-md-6 wow fadeInRight moving-border"
          data-aos="fade-right"
          data-aos-delay="0.3s"
        >
          <img
            src="/assets/images/services-internet-of-things.png"
            alt=""
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section services-inner">
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-6 wow fadeInLeft moving-border"
          data-aos="fade-left"
          data-wow-delay="0.3s"
        >
          <img
            src="/assets/images/services-cross-platform-app-development.png"
            alt=""
            className="img-responsive"
          />
        </div>
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-wow-delay="0.3s"
          >
            Reach More Users with
            <br />
            <span>Cross-Platform Apps!</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-wow-delay="0.4s">
            Minimize costs and maximize your reach with cross-platform app
            development. Our solutions enable your business to thrive on
            multiple mobile platforms, ensuring wide accessibility without
            compromising quality.
          </p>
          <ul>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-wow-delay="0.5s"
            >
              <a href="#">React Native App Development</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-wow-delay="0.6s"
            >
              <a href="#">Flutter App Development</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section manage-service-padding services-inner servicesSection2">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 common_tittle service_image">
          <div className="wave_service" />
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            Reliable Managed Services for <br />
            <span>Your Peace of Mind!</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.4s">
            Partner with Softuvo for a customer-centric approach that
            prioritizes your success. Our dedicated team delivers
            quality-assured services, providing constant maintenance and
            support, allowing your business to grow beyond expectations.
          </p>
          <ul>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.5s"
            >
              <a href="#">Quality Assurance</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.6s"
            >
              <a href="#">Hire Resources</a>
            </li>
            <li
              className="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.7s"
            >
              <a href="#">Maintenance &amp; Support</a>
            </li>
          </ul>
        </div>
        <div
          className="col-md-6 wow fadeInRight moving-border"
          data-aos="fade-right"
          data-aos-delay="0.4s"
        >
          <img
            src="/assets/images/services-managed-services.png"
            alt=""
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  </section>

</>
  )
}

export default Service