import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';


const InternetofThings = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);

  return (
    <>
  <section className="section home_banner crossPlatform">
    <div className="header_banner w-100">
      <div className="banner_overlay">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-md-7 banner_text carrer_banner_text">
              <h1
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                <span>Unleash the Power of</span>
                <br /> Internet of Things (IoT)
              </h1>
              <h3
                className="wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.5s"
              >
                Explore a Connected World with Our Innovative IoT Solutions
              </h3>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="/assets/images/ito-banner.png"
                alt="IOT Image"
                className="img-responsive wow zoomIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "zoomIn"
                }}
              />
            </div>
          </div>
        </div>
        <div className="ocean contact_ocean">
          <div className="wave" />
          <div className="wave" />
        </div>
      </div>
    </div>
  </section>
  <section className="section services_column_section app_development_section services-padding">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            {" "}
            EMPOWERING<span> YOUR BUSINESS WITH IOT</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
            Embrace the limitless potential of the Internet of Things (IoT) with
            our cutting-edge solutions. We go beyond the ordinary, transforming
            your business with smart and connected devices that optimize
            efficiency and elevate user experiences. At Brain Star Technologies,
            we specialize in IoT development, driving innovation and unlocking
            new opportunities for your business. From concept to implementation,
            we ensure seamless integration and precise execution to propel your
            business forward into the realm of IoT-driven success.
          </p>
        </div>
        <div className="clearfix" />
        <div className="culture_section_column w-100 pb-100">
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/iot.svg"
                  alt="iot"
                  className="img-responsive"
                />
              </div>
              <h4>Embracing IoT Technology</h4>
              <p>
                Stay ahead in the connected world with our expertise in Internet
                of Things. Unlock the potential of smart devices and seamless
                interactions for your business.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.6s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/smart-devices.svg"
                  alt="smart-devices"
                  className="img-responsive"
                />
              </div>
              <h4>Smart Devices Integration</h4>
              <p>
                Undervaluing the integration of smart devices can hinder your
                business growth. Our IoT solutions seamlessly connect devices,
                ensuring real-time data and insights at your fingertips.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/data-analytics.svg"
                  alt="data analytics"
                  className="img-responsive"
                />
              </div>
              <h4>Data Analytics and Insights</h4>
              <p>
                Make the most of IoT-generated data with our advanced analytics.
                Gain valuable insights into user behavior and business patterns
                to make data-driven decisions.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.8s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/security.svg"
                  alt="iot security"
                  className="img-responsive"
                />
              </div>
              <h4>Ensuring IoT Security</h4>
              <p>
                Security is a top priority in IoT deployments. We provide robust
                security measures to safeguard your data and maintain the
                integrity of your IoT ecosystem.
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            Benefits Of<span> Internet of Things (IoT)</span>
          </h2>
        </div>
        <div className="culture_section_column w-100">
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.5s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/connectivity.svg"
                  alt="Smart Connectivity"
                  className="img-responsive"
                />
              </div>
              <h4>Smart Connectivity</h4>
              <p>
                Leverage the power of IoT to establish smart and seamless
                connectivity between devices, systems, and users, enabling
                efficient data exchange and automation.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/insights.svg"
                  alt="data driven "
                  className="img-responsive"
                />
              </div>
              <h4>Data-driven Insights</h4>
              <p>
                IoT empowers businesses with valuable data-driven insights,
                enabling informed decision-making and improved operational
                efficiency for better outcomes.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.7s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/productivity.svg"
                  alt="Increased-productivity"
                  className="img-responsive"
                />
              </div>
              <h4>Enhanced Productivity</h4>
              <p>
                Embrace IoT solutions to boost productivity by streamlining
                processes, optimizing resource utilization, and automating
                routine tasks, leading to increased efficiency.
              </p>
              <p />
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/cost.svg"
                  alt="reduction"
                  className="img-responsive"
                />
              </div>
              <h4>Cost Optimization</h4>
              <p>
                IoT implementation can lead to cost optimization by reducing
                manual intervention, minimizing operational expenses, and
                improving overall resource management.
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section w-100 mt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              {" "}
              Services <span>We Offer</span>
            </h2>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.5s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/ecommerce-app-design.png"
                    alt="consumer application"
                    className="img-responsive"
                  />
                </span>
                <h3>Consumer Application</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.6s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/infrastructure.jpeg"
                    alt="Infrastructure Management"
                    className="img-responsive"
                  />
                </span>
                <h3>Infrastructure Management</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.7s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/ar.jpg"
                    alt="ar vr"
                    className="img-responsive"
                  />
                </span>
                <h3> AR-VR Apps</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section app_platform_section w-100 mt-0 bg-light-blue">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Experience the Future
              <br />
              <span>Unleashed with Our Innovative</span> IoT Solutions
            </h2>
          </div>
          <div className="container">
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/ecommerce-app-design.png"
                    alt="Consumer Application"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Consumer Application</h3>
                <p>
                  Empower your consumers with cutting-edge IoT applications that
                  enhance their daily experiences.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Infrastructure Management</h3>
                <p>
                  Efficiently manage and optimize your infrastructure with our
                  IoT solutions for seamless operations.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/infrastructure.jpeg"
                    alt="Infrastructure management"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/ar.jpg"
                    alt="ar vr"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">AR-VR Apps</h3>
                <p>
                  Step into the immersive world of Augmented and Virtual Reality
                  with our innovative IoT applications.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  </section>


  
  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 ">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Hire IoT Specialists
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
          Empower your IoT vision with our skilled development team creating cutting-edge connected experiences.


          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>





</>

  )
}

export default InternetofThings