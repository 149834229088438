import React from 'react'
import { Element } from 'react-scroll';


const Blockchain = () => {
  return (
    <>
    <section className="section home_banner crossPlatform">
      <div className="header_banner w-100">
        <div className="banner_overlay">
          <div className="container-fluid">
            <div className="row banner-content">
              <div className="col-md-12 banner_text carrer_banner_text">
                <h1
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  <span>Blockchain Development</span>
                  <br />
                </h1>
                <h3
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Brain Star Technologies empowers businesses with top-tier
                  blockchain solutions. Leveraging extensive expertise in
                  blockchain technology, we streamline, enhance security, and
                  expedite operations through automation. Our proficient team
                  guides businesses through the entire process of integrating
                  cutting-edge technologies to advance their operational
                  workflows.
                </h3>
              </div>
            </div>
          </div>
          <div className="ocean contact_ocean">
            <div className="wave" />
            <div className="wave" />
          </div>
        </div>
      </div>
    </section>
    <section className="section services_column_section app_development_section services-padding">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Transcending conventional paradigms,
              <br />
              <span>
                Pioneering your business into the future with Blockchain.
              </span>
            </h2>
            <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
              An impactful online presence is paramount in today's business
              landscape. As a beacon of innovation, your blockchain-powered
              enterprise demands a strategic approach to development and
              engagement. Your choice of a blockchain technology partner holds the
              key to unlocking business success in this transformative era. At
              BlockVista TechWorks, we stand as vanguards of blockchain
              application development, empowering heightened business outcomes.
              Our solutions encompass visionary designs, immutable security, and
              user-centric platforms, underpinned by meticulous research to align
              with contemporary business imperatives. Collaborate with us to
              propel your business expansion with unwavering focus and dedication
              to blockchain's potential.
            </p>
          </div>
          <div className="clearfix" />
          <div className="all_services_section app_services_section mt-0 w-100">
            <div className="col-md-6 common_tittle">
              <h2
                className="heading-mt wow fadeInLeft"
                data-aos="fade-left"
                data-aos-delay="0.4s"
              >
                {" "}
                Blockchain Development At <span>Brain Star Technologies</span>
              </h2>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.6s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/hyperledger-icon.svg" alt="hyperledger" />
                  </span>
                  <h3>Hyperledger</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.7s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/ethereum-eth-logo.png" alt="ethereum" />
                  </span>
                  <h3>Ethereum</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.8s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/bitcoin-btc-logo.png" alt="bitcoin" />
                  </span>
                  <h3>Bitcoin</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.9s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/dash-dash-logo.png" alt="dash" />
                  </span>
                  <h3>Dash</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.0s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/ripple.png" alt="ripple" />
                  </span>
                  <h3>Ripple</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.1s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/litecoin-ltc-logo.png" alt="litcoin" />
                  </span>
                  <h3>Litecoin</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/nem-xem-logo.png" alt="nem" />
                  </span>
                  <h3>NEM</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/eos-eos-logo.png" alt="eos" />
                  </span>
                  <h3>EOS</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/tether-usdt-logo.png" alt="tether" />
                  </span>
                  <h3>Tether</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/chain.svg" alt="chain" />
                  </span>
                  <h3>Chain</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/polygon-matic-logo.png" alt="matic" />
                  </span>
                  <h3>Matic/Polygon</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/corda.png" alt="corda" />
                  </span>
                  <h3>Corda</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/tron-trx-logo.png" alt="tron" />
                  </span>
                  <h3>Tron</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/stellar-xlm-logo.png" alt="stellar" />
                  </span>
                  <h3>Stellar</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
              <div
                className="all_services_wrapper wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="1.2s"
              >
                <div className="all_services_inner">
                  <span>
                    <img src="/assets/images/solana-sol-logo.png" alt="solana" />
                  </span>
                  <h3>Solana</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="all_services_section app_services_section app_platform_section w-100 pt-0">
            <div className="col-md-12 common_tittle text-center">
              <h2
                className="text-uppercase wow fadeInUp"
                data-aos="fade-up"
                data-aos-delay="0.4s"
              >
                Most Popular <br />
                <span>Blockchain Platform</span>
              </h2>
            </div>
            <div className="definition_column_wrapper w-100">
              <div className="col-md-7 definition_column_right">
                <h3 className="text-uppercase wow fadeInUp" data-aos-delay="0.4s">
                  Hyperledger
                </h3>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Commenced through the collaboration of the Linux Foundation and
                  further enriched by contributions from industry leaders such as
                  IBM, SAP Ariba, and Intel, Hyperledger presents a diverse
                  selection of blockchain frameworks and tools that offer a
                  multitude of avenues for implementation. Our team of proficient
                  blockchain developers possesses extensive expertise in
                  Hyperledger, encompassing its various frameworks and tools,
                  ensuring adeptness in harnessing its capabilities.
                </p>
              </div>
              <div className="col-md-5">
                <img
                  src="/assets/images/hyperledger-icon.png"
                  alt="hyperledger"
                  className="img-responsive mx-auto wow fadeInRight"
                  data-wow-delay="0.5s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeInRight"
                  }}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="definition_column_wrapper w-100 mt-0">
              <div className="col-md-5 definition_column_wrapper">
                <img
                  src="/assets/images/ethereum-eth-logo.png"
                  alt="eteherum"
                  className="img-responsive mx-auto wow fadeInLeft"
                  data-wow-delay="0.5s"
                  style={{
                    height: 300,
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeInLeft"
                  }}
                />
              </div>
              <div className="col-md-7 definition_column_right definition_column_wrapper">
                <h3
                  className="text-uppercase wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  Ethereum
                </h3>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Ranked as the second most widely embraced blockchain globally,
                  Ethereum boasts a multifaceted repertoire. Beyond merely housing
                  the Ether cryptocurrency, it serves as an expansive arena for
                  decentralized applications and ingenious smart contracts,
                  setting it distinctly apart from its counterpart, Bitcoin. This
                  distinctive attribute unfurls a vast spectrum of prospects,
                  inviting businesses to harness the Ethereum blockchain's prowess
                  for their strategic endeavors. The platform's inherent potential
                  for facilitating decentralized applications and executing
                  self-executing agreements presents a fertile ground for
                  innovation and transformative business applications, propelling
                  Ethereum into a pivotal role within the realms of modern
                  enterprise.
                </p>
              </div>
            </div>
            <div className="clearfix" />
            <div className="definition_column_wrapper w-100 mt-0">
              <div className="col-md-7 definition_column_right">
                <h3
                  className="text-uppercase wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  Bitcoin
                </h3>
                <p
                  className="wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.5s"
                >
                  Brain Star technologies' blockchain developers have a deep
                  familiarity with both Bitcoin, the world's most renowned
                  cryptocurrency, and Bitcoin Core, the underlying technology. Our
                  team of skilled software engineers is poised to create a
                  tailored solution utilizing Bitcoin's blockchain technology that
                  precisely aligns with your business requirements.
                </p>
              </div>
              <div className="col-md-5">
                <img
                  src="/assets/images/bitcoin-btc-logo.png"
                  alt="bitcoin"
                  className="img-responsive mx-auto wow fadeInRight"
                  data-wow-delay="0.5s"
                  style={{
                    height: 300,
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeInRight"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="all_services_section app_services_section mobile_process w-100 bg-light-blue">
            <div className="container">
              <div className="col-md-12 common_tittle text-center">
                <h2
                  className="text-uppercase wow fadeInUp"
                  data-aos="fade-up"
                  data-aos-delay="0.4s"
                >
                  Our Blockchain <span>Development Process</span>{" "}
                </h2>
              </div>
              <div className="clearfix" />
              <div
                className="definition_column_wrapper w-100 wow fadeInLeft"
                data-aos="fade-left"
                data-aos-delay="0.5s"
              >
                <div className="col-md-3">
                  <div className="process-image process-image-left">
                    <img
                      src="/assets/images/gather-information.png"
                      alt="Information Gathering"
                      className="img-responsive"
                    />
                  </div>
                </div>
                <div className="col-md-9 definition_column_right">
                  <h3 className="text-uppercase">Prepare</h3>
                  <p>
                    We initiate the project by outlining the essential
                    specifications, identifying user responsibilities, and making
                    initial technology selections.
                  </p>
                </div>
              </div>
              <div
                className="definition_column_wrapper w-100 wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.6s"
              >
                <div className="col-md-9 definition_column_right">
                  <h3 className="text-uppercase">Planning</h3>
                  <p>
                    We have created a prototype of the upcoming product that
                    showcases its anticipated appearance, user interactions, the
                    path users will take while using it, its functionalities, and
                    have outlined the strategy for its launch.
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="process-image process-image-right">
                    <img
                      src="/assets/images/build-and-lead.png"
                      alt="Planning"
                      className="img-responsive"
                    />
                  </div>
                </div>
              </div>
              <div
                className="definition_column_wrapper w-100 wow fadeInLeft"
                data-aos="fade-left"
                data-aos-delay="0.7s"
              >
                <div className="col-md-3">
                  <div className="process-image process-image-left">
                    <img
                      src="/assets/images/business-consulting.png"
                      alt="assess"
                      className="img-responsive"
                    />
                  </div>
                </div>
                <div className="col-md-9 definition_column_right">
                  <h3 className="text-uppercase">Assess</h3>
                  <p>
                    We analyze the specific requirements for the project's
                    development, calculate the anticipated expenses and time
                    frame, finalize the launch strategy, and allocate our experts
                    to the task.
                  </p>
                </div>
              </div>
              <div
                className="definition_column_wrapper w-100 wow fadeInRight"
                data-aos="fade-right"
                data-aos-delay="0.8s"
              >
                <div className="col-md-9 definition_column_right">
                  <h3 className="text-uppercase">Develop</h3>
                  <p>
                    Our team of experts in blockchain technology are actively
                    developing the project while also ensuring its quality through
                    rigorous testing. The entire workflow and measurable outcomes
                    can be monitored using a project management tool of your
                    choice.
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="process-image process-image-right">
                    <img
                      src="/assets/images/blend-in-design-and-development.png"
                      alt="Develop"
                      className="img-responsive"
                    />
                  </div>
                </div>
              </div>
              <div
                className="definition_column_wrapper w-100 wow fadeInLeft"
                data-aos="fade-left"
                data-aos-delay="0.9s"
              >
                <div className="col-md-3">
                  <div className="process-image process-image-left">
                    <img
                      src="/assets/images/support-and-maintenance.png"
                      alt="support"
                      className="img-responsive"
                    />
                  </div>
                </div>
                <div className="col-md-9 definition_column_right">
                  <h3 className="text-uppercase">Support</h3>
                  <p>
                    Once the project is effectively initiated, Brain Star
                    Technologies provides ongoing assistance and implements
                    continuous enhancements, encompassing both expansion and the
                    introduction of novel features and technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="clearfix" />
        </div>
      </div>
    </section>
  
   
   
  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Us</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 ">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Hire Blockchain Developers
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
          Empower your projects with our expert blockchain developers, harnessing the transformative capabilities of decentralized technology.


          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>




  </>
  
  )
}

export default Blockchain