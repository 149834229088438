import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Element } from 'react-scroll';



const UIUXDesign = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-out-back',
          once: true,
        });
      }, []);
    

  return (
  
    <>
  <section className="section services_column_section app_development_section services-padding">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            {" "}
            Embrace<span> User-Centricity</span>
          </h2>
          <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="0.5s">
            Is your business struggling to captivate users? It's time to embrace
            user-centricity. Upgrade your UX and leave outdated designs behind.
            Discover the secret to winning hearts effortlessly.
          </p>
        </div>
        <div className="clearfix" />
        <div className="culture_section_column w-100 pb-100">
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/research-icon.svg"
                  alt="user-research"
                  className="img-responsive"
                />
              </div>
              <h4>Fear Of User-Research</h4>
              <p>
                Stay ahead with cutting-edge tools and techniques for rapid
                prototyping. Gain valuable user insights and align your design
                ideas with customer preferences.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.6s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/ux-icon.svg"
                  alt="uxdesign"
                  className="img-responsive"
                />
              </div>
              <h4>Undervaluing The UX Design</h4>
              <p>
                Overlooking UX Design can result in lagging behind rivals with
                more intuitive and customer-centric apps and websites.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/bussiness-man.svg"
                  alt="Business-Complexitiey"
                  className="img-responsive"
                />
              </div>
              <h4>Worries Of Business Complexities</h4>
              <p>
                Unlock success with our seasoned experts and proven excellence.
                Welcome to a world of unparalleled experience.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.8s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/crm.svg"
                  alt="establish_customer"
                  className="img-responsive"
                />
              </div>
              <h4>Apprehension For Established Customers</h4>
              <p>
                For established organizations, understanding and meeting
                customer expectations is crucial. Thorough research and
                attentive listening will ensure a delightful experience for
                existing and new customers alike.
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="col-md-12 common_tittle text-center">
          <h2
            className="text-uppercase wow fadeInUp"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
            Benefits Of<span> UX And UI Services</span>
          </h2>
        </div>
        <div className="culture_section_column w-100">
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.5s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/cpa.svg"
                  alt="Customer Acquisition"
                  className="img-responsive"
                />
              </div>
              <h4>Customer Acquisition</h4>
              <p>
                Empower your business with compelling UX/UI design, ensuring a
                successful edge in customer acquisition. Unlock the potential of
                a seamless user experience to drive growth and success.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/retention.svg"
                  alt="retention"
                  className="img-responsive"
                />
              </div>
              <h4>Customer Retention</h4>
              <p>
                In the fast-paced world of technology, captivating and
                user-centric apps are essential for attracting and retaining
                customers. Customer loyalty is the key to sustained success in
                today's competitive market.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.7s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/productivity.svg"
                  alt="Increased-productivity"
                  className="img-responsive"
                />
              </div>
              <h4>Increased Productivity</h4>
              <p>
                Increased productivity is the result of better user experience.
                With the increased number of users and productivity, there is an
                upsurge in the financial impact also.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 text-center culture_column">
            <div
              className="user_centric ui_services wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="center_icon">
                <img
                  src="/assets/images/cost.svg"
                  alt="reduction"
                  className="img-responsive"
                />
              </div>
              <h4>Reduction In Development Costs</h4>
              <p>
                Experience exponential growth in productivity and revenue as
                user engagement soars.
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section w-100 mt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              {" "}
              Services <span>We Offer</span>
            </h2>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.5s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/website-design.svg"
                    alt="customized_web_design"
                    className="img-responsive"
                  />
                </span>
                <h3>Customised Web-Design Services</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.6s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/app-design.svg"
                    alt="User_Interface"
                    className="img-responsive"
                  />
                </span>
                <h3>Interface Design for Mobile App</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.7s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/front-end-development.svg"
                    alt="front_end _development"
                    className="img-responsive"
                  />
                </span>
                <h3> Front End Development</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.8s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/computer.svg"
                    alt="web_design"
                    className="img-responsive"
                  />
                </span>
                <h3>Web Design Services</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="0.9s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/online-shop.svg"
                    alt="ecommerce"
                    className="img-responsive"
                  />
                </span>
                <h3>E-Commerce App Design</h3>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-3 col-xs-6 all_services_column text-center">
            <div
              className="all_services_wrapper wow zoomIn"
              data-aos="zoom-in"
              data-aos-delay="1.0s"
            >
              <div className="all_services_inner">
                <span>
                  <img
                    src="/assets/images/responsive-design.svg"
                    alt="app-design"
                    className="img-responsive"
                  />
                </span>
                <h3>iOS and Android App Designs</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section app_services_section app_platform_section w-100 mt-0 bg-light-blue">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              See Your Business
              <br />
              <span>Bloom with Our Phenomenal</span> Designing Services
            </h2>
          </div>
          <div className="container">
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.5s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/customization.png"
                    alt="Customised Web-Design Services"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">
                  Customised Web-Design Services
                </h3>
                <p>
                  Tailored prototypes and designs that align perfectly with your
                  business needs for seamless integration.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.6s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">
                  Interface Design for Mobile App
                </h3>
                <p>
                  Engage users with striking UI/UX designs, ensuring an
                  exceptional experience on mobile devices.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/interface-design-for-mobile-app.png"
                    alt="Interface Design for Mobile App"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-left"
              data-aos-delay="0.7s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/code-creation.png"
                    alt="Front End Development"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">Front End Development</h3>
                <p>
                  Platforms compatibility guaranteed with high-performance code
                  for enhanced productivity.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="0.8s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase"> Web Design Services</h3>
                <p>
                  Stand out online with captivating web designs, expanding your
                  business's online presence.{" "}
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/build-and-lead.png"
                    alt="Design"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInLeft"
              data-aos="fade-right"
              data-aos-delay="0.9s"
            >
              <div className="col-md-3">
                <div className="process-image process-image-left">
                  <img
                    src="/assets/images/ecommerce-app-design.png"
                    alt="E-Commerce App Design"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">E-Commerce App Design</h3>
                <p>
                  Interactive and feature-rich e-commerce apps for a
                  user-friendly shopping experience and increased engagement.
                </p>
              </div>
            </div>
            <div
              className="definition_column_wrapper w-100 wow fadeInRight"
              data-aos="fade-right"
              data-aos-delay="1.0s"
            >
              <div className="col-md-9 definition_column_right">
                <h3 className="text-uppercase">iOS and Android App Designs</h3>
                <p>
                  Precision-crafted interfaces for seamless iOS and Android app
                  experiences, managed flawlessly by our interface designers.
                </p>
              </div>
              <div className="col-md-3">
                <div className="process-image process-image-right">
                  <img
                    src="/assets/images/development-process2.png"
                    alt="iOS and Android App Designs"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section mobile_process w-100 mt-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.4s"
            >
              Process Of <span>UX Designing</span>
            </h2>
            <div className="divider">
              <div className="ux_designing">
                <img
                  src="/assets/images/ux-designing1.png"
                  alt="ux-designing1"
                  className="img-responsive mx-auto wow zoomIn"
                  data-wow-delay="0.5s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "zoomIn"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="all_services_section mobile_process w-100 mt-0 pt-0 pb-0">
          <div className="col-md-12 common_tittle text-center">
            <h2
              className="text-uppercase wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="0.6s"
            >
              Process Of <span>UI Designing</span>{" "}
            </h2>
            <div className="ux_designing">
              <img
                src="/assets/images/ui-designing1.png"
                alt="ui-designing1"
                className="img-responsive mx-auto wow zoomIn"
                data-aos="zoom-in"
                data-aos-delay="0.7s"
              />
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  </section>





  <Element name="hire" 

className="section contact_section"

style={{ background: "#4A28D15d" }}
>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-5 contact_section_right " id="Request_Quote_form">
      <div className="contact_right_wrapper ">
        <div className="col-md-12 common_tittle text-center ">
          <h2
            className="text-uppercase wow fadeInDown aos-init aos-animate"
            data-aos="fade-down"
            data-aos-delay={300}
          >
            <span>Hire Uss</span>
          </h2>
        </div>
        <form
          className="w-100 aos-init aos-animate"
          id="contactform"
          action="https://mail.brainstartechnologies.com/hire.php"
          method="post"
          noValidate="novalidate"
          data-aos="fade-down"
          data-aos-delay={300}
        >
      
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <input
                  type="text"
                  id="contactno"
                  name="phone"
                  className="form-control"
                  placeholder="Mobile"
                  min="10" max="11"
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-6 col-xs-12 ">
              <div className="form-group">
                <select name="hire" className="form-control" id="hire_on">
                  <option value="part_time">Part time basis</option>
                  <option value="full_time">Full time basis</option>
                </select>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Project Summary"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12" />
            <div className="clearfix" />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-group intership-form">
                <div className="loading" style={{ display: "none" }} />
                <button
                  type="submit"
                  name="submit_query"
                  className="btn btn-talk gradient-btn hvr-bounce-in"
                  id="contactformSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    </div>
    <div className="col-md-6 col-md-offset-1 ">
      <div
        className="common_tittle aos-init"
        data-aos="fade-up"
        data-aos-duration={300}
      >
        <div className="col-md-7 banner_text carrer_banner_text">
          <h1
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.4s"
          >
Hire UI/UX Designer
          </h1>
          <p
            className="wow fadeInUp aos-init"
            data-aos="fade-up"
            data-aos-delay="0.5s"
          >
          We are capable of crafting user experiences that are both instinctive and captivating, compelling users to engage in the intended actions.


          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</Element>




</>


  )
}

export default UIUXDesign